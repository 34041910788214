import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  Upload: any;
};


/** An Account */
export type Account = Node & {
  __typename?: 'Account';
  accountable: Accountable;
  bankInformation?: Maybe<BankInformation>;
  birthDate?: Maybe<Scalars['ISO8601DateTime']>;
  civility?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  /** Account is Completed ? */
  completed?: Maybe<Scalars['Boolean']>;
  corporateForm?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identityDocuments?: Maybe<Array<CustomFile>>;
  importConditionAccepted?: Maybe<Scalars['Boolean']>;
  isProfessional?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nbItems?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  ribDocuments?: Maybe<Array<CustomFile>>;
  shippingAddress?: Maybe<Address>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  users: UserConnection;
  writerFixFees?: Maybe<Scalars['Float']>;
  writerPourcentageFees?: Maybe<Scalars['Float']>;
  writerType?: Maybe<Scalars['String']>;
};


/** An Account */
export type AccountUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Account. */
export type AccountConnection = {
  __typename?: 'AccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Account>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: 'AccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Account>;
};

export type AccountFilter = {
  OR?: Maybe<Array<AccountFilter>>;
  firstName?: Maybe<OperatorStringFilter>;
  lastName?: Maybe<OperatorStringFilter>;
  id?: Maybe<OperatorIdFilter>;
};

/** Objects which may belongs_to an account */
export type Accountable = Buyer | Expert | Vendor | Writer;

/** An Address */
export type Address = Node & {
  __typename?: 'Address';
  account: Account;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  phone1?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  zip?: Maybe<Scalars['String']>;
};

/** The connection type for Address. */
export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AddressEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Address>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Address>;
};

export type AddressFilter = {
  OR?: Maybe<Array<AddressFilter>>;
  type?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

/** An Artist */
export type Artist = Node & {
  __typename?: 'Artist';
  createdAt: Scalars['ISO8601DateTime'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isGroup?: Maybe<Scalars['Boolean']>;
  itemCategoryId: Scalars['ID'];
  lastName: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Artist. */
export type ArtistConnection = {
  __typename?: 'ArtistConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ArtistEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Artist>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ArtistEdge = {
  __typename?: 'ArtistEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Artist>;
};

export type ArtistFilter = {
  OR?: Maybe<Array<ArtistFilter>>;
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<OperatorStringFilter>;
  lastName?: Maybe<OperatorStringFilter>;
  tag?: Maybe<Scalars['String']>;
};

/** A BankInformation */
export type BankInformation = Node & {
  __typename?: 'BankInformation';
  account: Account;
  bic?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  fullName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A Book */
export type Book = Node & {
  __typename?: 'Book';
  assemblySpecification?: Maybe<Scalars['String']>;
  assemblyType?: Maybe<Scalars['String']>;
  bibliographyComment?: Maybe<Scalars['String']>;
  bindingAdditionalOptions?: Maybe<Scalars['String']>;
  bindingAge?: Maybe<Scalars['String']>;
  bindingColor?: Maybe<Scalars['String']>;
  bindingFormat?: Maybe<Scalars['String']>;
  bindingFormatDetails?: Maybe<Scalars['String']>;
  bindingMaterial?: Maybe<Scalars['String']>;
  bindingMaterialOptions?: Maybe<Scalars['String']>;
  bindingStyle?: Maybe<Scalars['String']>;
  bindingType?: Maybe<Scalars['String']>;
  bindingTypeOptions?: Maybe<Scalars['String']>;
  bookBinder?: Maybe<Scalars['String']>;
  bookSpineMaterial?: Maybe<Scalars['String']>;
  bookSpineOrnament1?: Maybe<Scalars['String']>;
  bookSpineOrnament1Option?: Maybe<Scalars['String']>;
  bookSpineOrnament2?: Maybe<Scalars['String']>;
  bookSpineOrnament2Option?: Maybe<Scalars['String']>;
  bookSpineOrnamentOption?: Maybe<Scalars['String']>;
  bookSpineType?: Maybe<Scalars['String']>;
  casingType?: Maybe<Scalars['String']>;
  collationComment?: Maybe<Scalars['String']>;
  coverColor?: Maybe<Scalars['String']>;
  coverMaterial?: Maybe<Scalars['String']>;
  coverOrnament1?: Maybe<Scalars['String']>;
  coverOrnament1Option?: Maybe<Scalars['String']>;
  coverOrnament2?: Maybe<Scalars['String']>;
  coverOrnament2Option?: Maybe<Scalars['String']>;
  coverOrnamentOption?: Maybe<Scalars['String']>;
  coverQuality?: Maybe<Scalars['String']>;
  coverType?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  dumbSpine?: Maybe<Scalars['Boolean']>;
  /** color */
  edgeColor?: Maybe<Scalars['String']>;
  edgeStyle?: Maybe<Scalars['String']>;
  edgeType?: Maybe<Scalars['String']>;
  editingAttribute?: Maybe<Scalars['String']>;
  editingAttributeDetails?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  illustrationComment?: Maybe<Scalars['String']>;
  isApproximatelyPaginating?: Maybe<Scalars['Boolean']>;
  isBindingSigned?: Maybe<Scalars['Boolean']>;
  isOblong?: Maybe<Scalars['Boolean']>;
  isPaginating?: Maybe<Scalars['Boolean']>;
  item: Item;
  paginationInfo?: Maybe<Scalars['String']>;
  paperbackType?: Maybe<Scalars['String']>;
  publisherKnownButNotMentioned?: Maybe<Scalars['Boolean']>;
  publishers?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  volumes?: Maybe<VolumeConnection>;
  withFolder?: Maybe<Scalars['Boolean']>;
};


/** A Book */
export type BookVolumesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An Buyer */
export type Buyer = Node & {
  __typename?: 'Buyer';
  account: Account;
  autoTags?: Maybe<TagConnection>;
  bookmarkTags?: Maybe<TagConnection>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  expertTags?: Maybe<TagConnection>;
  id: Scalars['ID'];
  importTags?: Maybe<TagConnection>;
  items?: Maybe<ItemConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** An Buyer */
export type BuyerAutoTagsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Buyer */
export type BuyerBookmarkTagsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Buyer */
export type BuyerExpertTagsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Buyer */
export type BuyerImportTagsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Buyer */
export type BuyerItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BuyerFilter = {
  OR?: Maybe<Array<BuyerFilter>>;
  description?: Maybe<Scalars['String']>;
  account?: Maybe<AccountFilter>;
  id?: Maybe<OperatorIdFilter>;
};

/** Attributes to create a Account. */
export type CreateAccountInput = {
  /** first_name */
  firstName?: Maybe<Scalars['String']>;
  /** last_name */
  lastName?: Maybe<Scalars['String']>;
  /** email */
  email: Scalars['String'];
  /** role */
  role?: Maybe<Scalars['String']>;
  /** writerType */
  writerType?: Maybe<Scalars['String']>;
  /** writer_pourcentage_fees */
  writerPourcentageFees?: Maybe<Scalars['Float']>;
  /** writer_fix_fees */
  writerFixFees?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of CreateAccountMutation */
export type CreateAccountMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateAccountInput;
};

/** Autogenerated return type of CreateAccountMutation. */
export type CreateAccountMutationPayload = {
  __typename?: 'CreateAccountMutationPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to update a Address. */
export type CreateAddressInput = {
  /** lang */
  number?: Maybe<Scalars['String']>;
  /** lang */
  addressLine1?: Maybe<Scalars['String']>;
  /** lang */
  addressLine2?: Maybe<Scalars['String']>;
  /** lang */
  addressLine3?: Maybe<Scalars['String']>;
  /** lang */
  zip?: Maybe<Scalars['String']>;
  /** lang */
  city?: Maybe<Scalars['String']>;
  /** lang */
  country?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateAddressMutation */
export type CreateAddressMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: CreateAddressInput;
};

/** Autogenerated return type of CreateAddressMutation. */
export type CreateAddressMutationPayload = {
  __typename?: 'CreateAddressMutationPayload';
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to create a Artist. */
export type CreateArtistInput = {
  /** first_name */
  firstName?: Maybe<Scalars['String']>;
  /** last_name */
  lastName: Scalars['String'];
  /** tag */
  tag?: Maybe<Scalars['String']>;
  /** is_group */
  isGroup?: Maybe<Scalars['String']>;
  itemCategoryId?: Maybe<Scalars['ID']>;
};

/** Attributes to create a BankInformation. */
export type CreateBankInformationInput = {
  /** lang */
  iban?: Maybe<Scalars['String']>;
  /** lang */
  bic?: Maybe<Scalars['String']>;
  /** lang */
  fullName?: Maybe<Scalars['String']>;
  /** lang */
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateBankInformationMutation */
export type CreateBankInformationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: CreateBankInformationInput;
};

/** Autogenerated return type of CreateBankInformationMutation. */
export type CreateBankInformationMutationPayload = {
  __typename?: 'CreateBankInformationMutationPayload';
  bankInformation?: Maybe<BankInformation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to create a book. */
export type CreateBookInput = {
  item?: Maybe<NestedItemInput>;
  /** publishers */
  publishers?: Maybe<Scalars['String']>;
  /** publisher_known_but_not_mentioned */
  publisherKnownButNotMentioned?: Maybe<Scalars['Boolean']>;
  /** date_from */
  dateFrom?: Maybe<Scalars['String']>;
  /** date_to */
  dateTo?: Maybe<Scalars['String']>;
  /** date_option */
  dateOption?: Maybe<Scalars['String']>;
  /** editing_attribute */
  editingAttribute?: Maybe<Scalars['String']>;
  /** editing_attribute_details */
  editingAttributeDetails?: Maybe<Scalars['String']>;
  /** assembly_type */
  assemblyType?: Maybe<Scalars['String']>;
  /** assembly_specification */
  assemblySpecification?: Maybe<Scalars['String']>;
  /** paperback_type */
  paperbackType?: Maybe<Scalars['String']>;
  /** binding_format */
  bindingFormat?: Maybe<Scalars['String']>;
  /** binding_type */
  bindingType?: Maybe<Scalars['String']>;
  /** binding_material */
  bindingMaterial?: Maybe<Scalars['String']>;
  /** binding_style */
  bindingStyle?: Maybe<Scalars['String']>;
  /** binding_color */
  bindingColor?: Maybe<Scalars['String']>;
  /** is_binding_signed */
  isBindingSigned?: Maybe<Scalars['Boolean']>;
  /** book_spine_type */
  bookSpineType?: Maybe<Scalars['String']>;
  /** book_spine_material */
  bookSpineMaterial?: Maybe<Scalars['String']>;
  /** book_spine_ornament */
  bookSpineOrnament?: Maybe<Scalars['String']>;
  /** cover_material */
  coverMaterial?: Maybe<Scalars['String']>;
  /** cover_ornament */
  coverOrnament?: Maybe<Scalars['String']>;
  /** cover_color */
  coverColor?: Maybe<Scalars['String']>;
  /** edge_type */
  edgeType?: Maybe<Scalars['String']>;
  /** edge_style */
  edgeStyle?: Maybe<Scalars['String']>;
  /** edge_color */
  edgeColor?: Maybe<Scalars['String']>;
  bibliographyComment?: Maybe<Scalars['String']>;
  illustrationComment?: Maybe<Scalars['String']>;
  collationComment?: Maybe<Scalars['String']>;
  isApproximatelyPaginating?: Maybe<Scalars['Boolean']>;
  paginationInfo?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateBookMutation */
export type CreateBookMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateBookInput;
};

/** Autogenerated return type of CreateBookMutation. */
export type CreateBookMutationPayload = {
  __typename?: 'CreateBookMutationPayload';
  book?: Maybe<Book>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to Create a Customer. */
export type CreateCustomerInput = {
  /** Description */
  fullName?: Maybe<Scalars['String']>;
  /** Description */
  email: Scalars['String'];
  /** Description */
  city?: Maybe<Scalars['String']>;
  /** Description */
  zip?: Maybe<Scalars['String']>;
  /** Description */
  firstName?: Maybe<Scalars['String']>;
  /** Description */
  lastName?: Maybe<Scalars['String']>;
  /** Description */
  phone1?: Maybe<Scalars['String']>;
  /** Description */
  phone2?: Maybe<Scalars['String']>;
  /** Description */
  country?: Maybe<Scalars['String']>;
  /** Description */
  addressLine1?: Maybe<Scalars['String']>;
  /** Description */
  addressLine2?: Maybe<Scalars['String']>;
  /** Description */
  addressLine3?: Maybe<Scalars['String']>;
  /** Vendor Id */
  vendorId?: Maybe<Scalars['ID']>;
  /** Expert Id */
  expertId?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of CreateCustomerMutation */
export type CreateCustomerMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateCustomerInput;
};

/** Autogenerated return type of CreateCustomerMutation. */
export type CreateCustomerMutationPayload = {
  __typename?: 'CreateCustomerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to create a Desire. */
export type CreateDesireInput = {
  /** name */
  title?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateDesireMutation */
export type CreateDesireMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateDesireInput;
};

/** Autogenerated return type of CreateDesireMutation. */
export type CreateDesireMutationPayload = {
  __typename?: 'CreateDesireMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  desire?: Maybe<Desire>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to update a Import. */
export type CreateDrouotImportInput = {
  /** filename */
  filename?: Maybe<Scalars['String']>;
  /** type */
  type?: Maybe<Scalars['String']>;
  /** filename */
  accountId: Scalars['String'];
  salesTransactionFiles: Array<UploadFileInput>;
  salesInvoiceFiles: Array<UploadFileInput>;
};

/** Autogenerated input type of CreateDrouotImportMutation */
export type CreateDrouotImportMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateDrouotImportInput;
};

/** Autogenerated return type of CreateDrouotImportMutation. */
export type CreateDrouotImportMutationPayload = {
  __typename?: 'CreateDrouotImportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  import?: Maybe<Import>;
};

/** Attributes to update a Import. */
export type CreateImportInput = {
  /** filename */
  filename?: Maybe<Scalars['String']>;
  /** type */
  type?: Maybe<Scalars['String']>;
  /** filename */
  accountId: Scalars['String'];
  /** list of tags */
  tags?: Maybe<Array<Scalars['String']>>;
  /** list of item_category ids */
  itemCategories?: Maybe<Array<Scalars['ID']>>;
  /** llist of item_sub_category ids */
  itemSubCategories?: Maybe<Array<Scalars['ID']>>;
  files: Array<UploadFileInput>;
};

/** Autogenerated input type of CreateImportMutation */
export type CreateImportMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateImportInput;
};

/** Autogenerated return type of CreateImportMutation. */
export type CreateImportMutationPayload = {
  __typename?: 'CreateImportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  import?: Maybe<Import>;
};

/** Attributes to create an item. */
export type CreateItemInput = {
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** The template id */
  templateId?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  expertId?: Maybe<Scalars['String']>;
  vendorId: Scalars['String'];
  vacationId?: Maybe<Scalars['Int']>;
  lotId?: Maybe<Scalars['Int']>;
  writerId?: Maybe<Scalars['ID']>;
  sku?: Maybe<Scalars['String']>;
  customSkuPart?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  otherComment?: Maybe<Scalars['String']>;
  conditionsComment?: Maybe<Scalars['String']>;
  globalComment?: Maybe<Scalars['String']>;
  startingPrice?: Maybe<Scalars['Float']>;
  estimatedPrice?: Maybe<Scalars['Float']>;
  reservedPrice?: Maybe<Scalars['Float']>;
  locations?: Maybe<Scalars['String']>;
  locationKnownButNotMentioned?: Maybe<Scalars['Boolean']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  dateOption?: Maybe<Scalars['String']>;
  itemCategoryId?: Maybe<Scalars['String']>;
  itemSubCategoryId?: Maybe<Scalars['String']>;
  artistUnknown?: Maybe<Scalars['Boolean']>;
  artistCollectif?: Maybe<Scalars['Boolean']>;
  artistKnownButNotMentioned?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of CreateItemMutation */
export type CreateItemMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateItemInput;
};

/** Autogenerated return type of CreateItemMutation. */
export type CreateItemMutationPayload = {
  __typename?: 'CreateItemMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  item?: Maybe<Item>;
};

/** Attributes to update a Import. */
export type CreateLotUrlRainworxImportInput = {
  /** filename */
  filename?: Maybe<Scalars['String']>;
  /** type */
  type?: Maybe<Scalars['String']>;
  /** filename */
  accountId: Scalars['String'];
  lotUrlsRainworxFiles: Array<UploadFileInput>;
};

/** Autogenerated input type of CreateLotUrlsRainworxImportMutation */
export type CreateLotUrlsRainworxImportMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateLotUrlRainworxImportInput;
};

/** Autogenerated return type of CreateLotUrlsRainworxImportMutation. */
export type CreateLotUrlsRainworxImportMutationPayload = {
  __typename?: 'CreateLotUrlsRainworxImportMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  import?: Maybe<Import>;
};

/** Attributes to Create a NewsLetter. */
export type CreateNewsLetterInput = {
  /** sale */
  saleId: Scalars['ID'];
  /** Is it newsletter for test or not ? */
  test: Scalars['Boolean'];
};

/** Autogenerated input type of CreateNewsLetterMutation */
export type CreateNewsLetterMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateNewsLetterInput;
};

/** Autogenerated return type of CreateNewsLetterMutation. */
export type CreateNewsLetterMutationPayload = {
  __typename?: 'CreateNewsLetterMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  sale?: Maybe<Sale>;
};

/** Attributes to Create a PickupRequest. */
export type CreatePickupRequestInput = {
  /** pickup_location_close_time */
  pickupLocationCloseTime: Scalars['String'];
  /** instructions */
  instructions?: Maybe<Scalars['String']>;
  pickupLocation?: Maybe<Scalars['String']>;
  pickupTimestamp?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of CreatePickupRequestMutation */
export type CreatePickupRequestMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreatePickupRequestInput;
};

/** Autogenerated return type of CreatePickupRequestMutation. */
export type CreatePickupRequestMutationPayload = {
  __typename?: 'CreatePickupRequestMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  pickupRequest?: Maybe<PickupRequest>;
};

/** Attributes to Create a Project. */
export type CreateProjectInput = {
  /** title */
  title: Scalars['String'];
  ownerId: Scalars['ID'];
};

/** Autogenerated input type of CreateProjectMutation */
export type CreateProjectMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateProjectInput;
};

/** Autogenerated return type of CreateProjectMutation. */
export type CreateProjectMutationPayload = {
  __typename?: 'CreateProjectMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  project?: Maybe<Project>;
};

/** Attributes to Create a QuickText. */
export type CreateQuickTextInput = {
  /** title */
  title?: Maybe<Scalars['String']>;
  /** text */
  text: Scalars['String'];
  accountId: Scalars['ID'];
};

/** Autogenerated input type of CreateQuickTextMutation */
export type CreateQuickTextMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateQuickTextInput;
};

/** Autogenerated return type of CreateQuickTextMutation. */
export type CreateQuickTextMutationPayload = {
  __typename?: 'CreateQuickTextMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  quickText?: Maybe<QuickText>;
};

/** Attributes to Create a Sale. */
export type CreateSaleInput = {
  /** name */
  name: Scalars['String'];
  /** state */
  state?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** start_at */
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** end_at */
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  image?: Maybe<UploadImageInput>;
  /** external_event_rainworx_id */
  externalEventRainworxId?: Maybe<Scalars['Int']>;
  expertId: Scalars['ID'];
  vacationId?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of CreateSaleMandatMutation */
export type CreateSaleMandatMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  saleId: Scalars['ID'];
};

/** Autogenerated return type of CreateSaleMandatMutation. */
export type CreateSaleMandatMutationPayload = {
  __typename?: 'CreateSaleMandatMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  sale?: Maybe<Sale>;
};

/** Autogenerated input type of CreateSaleMutation */
export type CreateSaleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateSaleInput;
};

/** Autogenerated return type of CreateSaleMutation. */
export type CreateSaleMutationPayload = {
  __typename?: 'CreateSaleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  sale?: Maybe<Sale>;
};

/** Attributes to create a Tag. */
export type CreateTagInput = {
  /** name */
  name?: Maybe<Scalars['String']>;
};

/** Attributes to Create a Vacation. */
export type CreateVacationInput = {
  /** name */
  name: Scalars['String'];
  /** state */
  state?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** start_at */
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** end_at */
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  image?: Maybe<UploadImageInput>;
  expertId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of CreateVacationMutation */
export type CreateVacationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: CreateVacationInput;
};

/** Autogenerated return type of CreateVacationMutation. */
export type CreateVacationMutationPayload = {
  __typename?: 'CreateVacationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  vacation?: Maybe<Vacation>;
};

/** Attributes to update a Writer. */
export type CreateWriterInput = {
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** The account id */
  accountId?: Maybe<Scalars['Int']>;
};

/** Custom File Type */
export type CustomFile = {
  __typename?: 'CustomFile';
  extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CustomSignIn. */
export type CustomSignInPayload = {
  __typename?: 'CustomSignInPayload';
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Autogenerated return type of CustomSignUp. */
export type CustomSignUpPayload = {
  __typename?: 'CustomSignUpPayload';
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Autogenerated input type of CustomUpdateAccountMutation */
export type CustomUpdateAccountMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateAccountInput;
};

/** Autogenerated return type of CustomUpdateAccountMutation. */
export type CustomUpdateAccountMutationPayload = {
  __typename?: 'CustomUpdateAccountMutationPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
};

/** An Customer */
export type Customer = Node & {
  __typename?: 'Customer';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  buyer?: Maybe<Buyer>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  expert: Expert;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  import?: Maybe<Import>;
  itemCategories?: Maybe<CustomerItemCategoryConnection>;
  itemSubCategories?: Maybe<CustomerItemSubCategoryConnection>;
  lastName?: Maybe<Scalars['String']>;
  phone1?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  tags?: Maybe<TagConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor: Vendor;
  zip?: Maybe<Scalars['String']>;
};


/** An Customer */
export type CustomerItemCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Customer */
export type CustomerItemSubCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Customer */
export type CustomerTagsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Customer. */
export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Customer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Customer>;
};

export type CustomerFilter = {
  OR?: Maybe<Array<CustomerFilter>>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
};

/** A Customer Item Category */
export type CustomerItemCategory = Node & {
  __typename?: 'CustomerItemCategory';
  customerItemSubCategories: CustomerItemSubCategoryConnection;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
};


/** A Customer Item Category */
export type CustomerItemCategoryCustomerItemSubCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for CustomerItemCategory. */
export type CustomerItemCategoryConnection = {
  __typename?: 'CustomerItemCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomerItemCategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CustomerItemCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerItemCategoryEdge = {
  __typename?: 'CustomerItemCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerItemCategory>;
};

/** An Customer Sub Item Category */
export type CustomerItemSubCategory = Node & {
  __typename?: 'CustomerItemSubCategory';
  id: Scalars['ID'];
  itemCategory?: Maybe<CustomerItemCategory>;
  name?: Maybe<Scalars['String']>;
};

/** The connection type for CustomerItemSubCategory. */
export type CustomerItemSubCategoryConnection = {
  __typename?: 'CustomerItemSubCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomerItemSubCategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CustomerItemSubCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerItemSubCategoryEdge = {
  __typename?: 'CustomerItemSubCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerItemSubCategory>;
};

/** Attributes to delete an Artist. */
export type DeleteArtistInput = {
  /** id */
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteUser */
export type DeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** A DepositSlip */
export type DepositSlip = Node & {
  __typename?: 'DepositSlip';
  createdAt: Scalars['ISO8601DateTime'];
  document?: Maybe<CustomFile>;
  id: Scalars['ID'];
  items?: Maybe<ItemConnection>;
  nbItems?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
};


/** A DepositSlip */
export type DepositSlipItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for DepositSlip. */
export type DepositSlipConnection = {
  __typename?: 'DepositSlipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DepositSlipEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DepositSlip>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DepositSlipEdge = {
  __typename?: 'DepositSlipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DepositSlip>;
};

export type DepositSlipFilter = {
  OR?: Maybe<Array<DepositSlipFilter>>;
  number?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  id?: Maybe<OperatorIdFilter>;
  vendor?: Maybe<VendorFilter>;
};

/** A Desire */
export type Desire = Node & {
  __typename?: 'Desire';
  buyer?: Maybe<Buyer>;
  createdAt: Scalars['ISO8601DateTime'];
  customer?: Maybe<Customer>;
  id: Scalars['ID'];
  tags?: Maybe<TagConnection>;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A Desire */
export type DesireTagsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Desire. */
export type DesireConnection = {
  __typename?: 'DesireConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DesireEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Desire>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DesireEdge = {
  __typename?: 'DesireEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Desire>;
};

export type DesireFilter = {
  OR?: Maybe<Array<DesireFilter>>;
  title?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DestroyCustomerMutation */
export type DestroyCustomerMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyCustomerMutation. */
export type DestroyCustomerMutationPayload = {
  __typename?: 'DestroyCustomerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  errors?: Maybe<ValidationErrors>;
};

/** Autogenerated input type of DestroyDesireMutation */
export type DestroyDesireMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyDesireMutation. */
export type DestroyDesireMutationPayload = {
  __typename?: 'DestroyDesireMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  desire?: Maybe<Desire>;
  errors?: Maybe<ValidationErrors>;
};

/** Autogenerated input type of DestroyItemMutation */
export type DestroyItemMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyItemMutation. */
export type DestroyItemMutationPayload = {
  __typename?: 'DestroyItemMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  item?: Maybe<Item>;
};

/** Autogenerated input type of DestroyQuickTextMutation */
export type DestroyQuickTextMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyQuickTextMutation. */
export type DestroyQuickTextMutationPayload = {
  __typename?: 'DestroyQuickTextMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  quickText?: Maybe<QuickText>;
};

/** Autogenerated input type of DestroySaleMutation */
export type DestroySaleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroySaleMutation. */
export type DestroySaleMutationPayload = {
  __typename?: 'DestroySaleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  sale?: Maybe<Sale>;
};

/** Autogenerated input type of DestroyTagMutation */
export type DestroyTagMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyTagMutation. */
export type DestroyTagMutationPayload = {
  __typename?: 'DestroyTagMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  tag?: Maybe<Tag>;
};

/** Autogenerated input type of DestroyVacationMutation */
export type DestroyVacationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyVacationMutation. */
export type DestroyVacationMutationPayload = {
  __typename?: 'DestroyVacationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  vacation?: Maybe<Vacation>;
};

/** Autogenerated input type of DuplicateItemMutation */
export type DuplicateItemMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DuplicateItemMutation. */
export type DuplicateItemMutationPayload = {
  __typename?: 'DuplicateItemMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  item: Item;
};

/** Form error */
export type Error = {
  __typename?: 'Error';
  /** Error details */
  details?: Maybe<Scalars['JSON']>;
  /** Field of the error */
  field: Scalars['String'];
  /** Error message */
  message: Scalars['String'];
};

/** An Expert */
export type Expert = Node & {
  __typename?: 'Expert';
  account: Account;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<ItemConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** An Expert */
export type ExpertItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Expert. */
export type ExpertConnection = {
  __typename?: 'ExpertConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExpertEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Expert>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ExpertEdge = {
  __typename?: 'ExpertEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Expert>;
};

export type ExpertFilter = {
  OR?: Maybe<Array<ExpertFilter>>;
  description?: Maybe<Scalars['String']>;
  account?: Maybe<AccountFilter>;
};

/** A ExpertPayment */
export type ExpertPayment = Node & {
  __typename?: 'ExpertPayment';
  createdAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  expert?: Maybe<Expert>;
  expertPaymentLines?: Maybe<ExpertPaymentLineConnection>;
  /** ID of the object. */
  id: Scalars['ID'];
  invoices?: Maybe<Array<CustomFile>>;
  orderItems?: Maybe<OrderItemConnection>;
  paid?: Maybe<Scalars['Boolean']>;
  startAt: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A ExpertPayment */
export type ExpertPaymentExpertPaymentLinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A ExpertPayment */
export type ExpertPaymentOrderItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for ExpertPayment. */
export type ExpertPaymentConnection = {
  __typename?: 'ExpertPaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExpertPaymentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ExpertPayment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ExpertPaymentEdge = {
  __typename?: 'ExpertPaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExpertPayment>;
};

export type ExpertPaymentFilter = {
  OR?: Maybe<Array<ExpertPaymentFilter>>;
  meta?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** A ExpertPaymentLine */
export type ExpertPaymentLine = Node & {
  __typename?: 'ExpertPaymentLine';
  amount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['ISO8601DateTime'];
  /** ID of the object. */
  id: Scalars['ID'];
  orderItems?: Maybe<OrderItemConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A ExpertPaymentLine */
export type ExpertPaymentLineOrderItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for ExpertPaymentLine. */
export type ExpertPaymentLineConnection = {
  __typename?: 'ExpertPaymentLineConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExpertPaymentLineEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ExpertPaymentLine>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ExpertPaymentLineEdge = {
  __typename?: 'ExpertPaymentLineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExpertPaymentLine>;
};

/** Autogenerated return type of ForgotPassword. */
export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
  valid: Scalars['Boolean'];
};

export type GraphqlAuth = {
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  lockAccount?: Maybe<LockAccountPayload>;
  resendConfirmationInstructions?: Maybe<ResendConfirmationInstructionsPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  signIn?: Maybe<SignInPayload>;
  signUp?: Maybe<SignUpPayload>;
  unlockAccount?: Maybe<UnlockAccountPayload>;
  updateAccount?: Maybe<UpdateAccountPayload>;
  validateToken?: Maybe<ValidateTokenPayload>;
};


export type GraphqlAuthForgotPasswordArgs = {
  email: Scalars['String'];
};


export type GraphqlAuthLockAccountArgs = {
  id: Scalars['ID'];
};


export type GraphqlAuthResendConfirmationInstructionsArgs = {
  email: Scalars['String'];
};


export type GraphqlAuthResetPasswordArgs = {
  resetPasswordToken: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type GraphqlAuthSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};


export type GraphqlAuthSignUpArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type GraphqlAuthUnlockAccountArgs = {
  id: Scalars['ID'];
};


export type GraphqlAuthUpdateAccountArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


/** Image Type */
export type Image = {
  __typename?: 'Image';
  imageUrl?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** A Import of Volume */
export type Import = Node & {
  __typename?: 'Import';
  createdAt: Scalars['ISO8601DateTime'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Import. */
export type ImportConnection = {
  __typename?: 'ImportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ImportEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Import>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ImportEdge = {
  __typename?: 'ImportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Import>;
};

export type ImportFilter = {
  OR?: Maybe<Array<ImportFilter>>;
  filename?: Maybe<Scalars['String']>;
};

/** An Item */
export type Item = Node & {
  __typename?: 'Item';
  artistCollectif?: Maybe<Scalars['Boolean']>;
  artistKnownButNotMentioned?: Maybe<Scalars['Boolean']>;
  artistUnknown?: Maybe<Scalars['Boolean']>;
  artists?: Maybe<ArtistConnection>;
  authenticityDocuments?: Maybe<Array<CustomFile>>;
  auto?: Maybe<Scalars['Boolean']>;
  autoDescription?: Maybe<Scalars['String']>;
  autoTags?: Maybe<TagConnection>;
  catchPhrase?: Maybe<Scalars['String']>;
  catchPhraseEnabled?: Maybe<Scalars['Boolean']>;
  conditionAccepted?: Maybe<Scalars['Boolean']>;
  conditionsComment?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  customDescription?: Maybe<Scalars['String']>;
  /** Enable the custom description */
  customDescriptionEnabled?: Maybe<Scalars['Boolean']>;
  /** first part of the sku, the second is generated automatically */
  customSkuPart?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateOption?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  depositSlip?: Maybe<DepositSlipConnection>;
  description?: Maybe<Scalars['String']>;
  estimatedPrice?: Maybe<Scalars['Float']>;
  expert?: Maybe<Expert>;
  /** id of rainworx lot, we are using it to create the newsletter's item url  */
  externalRainworxId?: Maybe<Scalars['Int']>;
  finalPrice?: Maybe<Scalars['Float']>;
  firstImage?: Maybe<Image>;
  globalComment?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Image>>;
  invoices?: Maybe<Array<CustomFile>>;
  itemCategory?: Maybe<ItemCategory>;
  itemSteps?: Maybe<ItemStepConnection>;
  itemSubCategory?: Maybe<ItemSubCategory>;
  length?: Maybe<Scalars['Int']>;
  locationKnownButNotMentioned?: Maybe<Scalars['Boolean']>;
  locations?: Maybe<Scalars['String']>;
  lot?: Maybe<Lot>;
  otherComment?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  reservedPrice?: Maybe<Scalars['Float']>;
  sale?: Maybe<Sale>;
  saleMandat?: Maybe<SaleMandatConnection>;
  /** complete sku */
  sku?: Maybe<Scalars['String']>;
  startingPrice?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  tags?: Maybe<TagConnection>;
  template: Template;
  templateId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vacation?: Maybe<Vacation>;
  vendor?: Maybe<Vendor>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Int']>;
  writer?: Maybe<Writer>;
};


/** An Item */
export type ItemArtistsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
};


/** An Item */
export type ItemAutoTagsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Item */
export type ItemDepositSlipArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Item */
export type ItemItemStepsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Item */
export type ItemSaleMandatArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Item */
export type ItemTagsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An Item Category */
export type ItemCategory = Node & {
  __typename?: 'ItemCategory';
  externalRainworxId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  itemSubCategories: ItemSubCategoryConnection;
  name?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
};


/** An Item Category */
export type ItemCategoryItemSubCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for ItemCategory. */
export type ItemCategoryConnection = {
  __typename?: 'ItemCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ItemCategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ItemCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ItemCategoryEdge = {
  __typename?: 'ItemCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ItemCategory>;
};

export type ItemCategoryFilter = {
  OR?: Maybe<Array<ItemCategoryFilter>>;
  id?: Maybe<OperatorIdFilter>;
  name?: Maybe<OperatorStringFilter>;
  templateType?: Maybe<OperatorStringFilter>;
};

/** The connection type for Item. */
export type ItemConnection = {
  __typename?: 'ItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Item>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ItemEdge = {
  __typename?: 'ItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Item>;
};

export type ItemFilter = {
  OR?: Maybe<Array<ItemFilter>>;
  id?: Maybe<OperatorIdFilter>;
  title?: Maybe<OperatorStringFilter>;
  state?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['String']>;
  writer?: Maybe<WriterFilter>;
  expert?: Maybe<ExpertFilter>;
  vendor?: Maybe<VendorFilter>;
  itemCategory?: Maybe<ItemCategoryFilter>;
  vacationId?: Maybe<OperatorIdFilter>;
  saleId?: Maybe<OperatorIdFilter>;
  projectId?: Maybe<OperatorIdFilter>;
  artists?: Maybe<ArtistFilter>;
  autoDescription?: Maybe<OperatorStringFilter>;
  sku?: Maybe<OperatorStringFilter>;
  firstArtistLastName?: Maybe<OperatorStringFilter>;
};

export type ItemOrder = {
  /** The attribute you want to order by. */
  attribute: Scalars['String'];
  /** Set a direction with "asc" or "desc". */
  direction: Scalars['String'];
  nested?: Maybe<Array<ItemOrder>>;
};

/** Item Steps */
export type ItemStep = Node & {
  __typename?: 'ItemStep';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  item?: Maybe<Item>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for ItemStep. */
export type ItemStepConnection = {
  __typename?: 'ItemStepConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ItemStepEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ItemStep>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ItemStepEdge = {
  __typename?: 'ItemStepEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ItemStep>;
};

/** An Sub Item Category */
export type ItemSubCategory = Node & {
  __typename?: 'ItemSubCategory';
  externalRainworxId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  itemCategory?: Maybe<ItemCategory>;
  name?: Maybe<Scalars['String']>;
};

/** The connection type for ItemSubCategory. */
export type ItemSubCategoryConnection = {
  __typename?: 'ItemSubCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ItemSubCategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ItemSubCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ItemSubCategoryEdge = {
  __typename?: 'ItemSubCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ItemSubCategory>;
};

export type ItemSubCategoryFilter = {
  OR?: Maybe<Array<ItemSubCategoryFilter>>;
  name?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  itemCategoryId?: Maybe<Scalars['String']>;
};


/** A Jewelry */
export type Jewelry = Node & {
  __typename?: 'Jewelry';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  item: Item;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A Label */
export type Label = Node & {
  __typename?: 'Label';
  cn23Document?: Maybe<CustomFile>;
  externalLabelUrl?: Maybe<Scalars['String']>;
  /** ID of the object. */
  id: Scalars['ID'];
  item: Item;
  labelDocument?: Maybe<CustomFile>;
  orderItems?: Maybe<Array<OrderItem>>;
  trackingNumber?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
  undeplusRef?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
};

/** The connection type for Label. */
export type LabelConnection = {
  __typename?: 'LabelConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LabelEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Label>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LabelEdge = {
  __typename?: 'LabelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Label>;
};

export type LinkInput = {
  id?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of LockAccount. */
export type LockAccountPayload = {
  __typename?: 'LockAccountPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** A Lot */
export type Lot = Node & {
  __typename?: 'Lot';
  createdAt: Scalars['ISO8601DateTime'];
  expert?: Maybe<Expert>;
  id: Scalars['ID'];
  items?: Maybe<ItemConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
  vacation?: Maybe<Vacation>;
  vendor: Vendor;
};


/** A Lot */
export type LotItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Lot. */
export type LotConnection = {
  __typename?: 'LotConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LotEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Lot>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LotEdge = {
  __typename?: 'LotEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Lot>;
};

/** Attributes to update a Tag. */
export type MergeTagInput = {
  /** merge the id tag to the tagToId */
  tagToId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of MergeTagMutation */
export type MergeTagMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: MergeTagInput;
  id: Scalars['ID'];
};

/** Autogenerated return type of MergeTagMutation. */
export type MergeTagMutationPayload = {
  __typename?: 'MergeTagMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  tag?: Maybe<Tag>;
};

/** A MonthlyVendorPayment */
export type MonthlyVendorPayment = Node & {
  __typename?: 'MonthlyVendorPayment';
  createdAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  /** ID of the object. */
  id: Scalars['ID'];
  invoices?: Maybe<Array<CustomFile>>;
  orderItems?: Maybe<OrderItemConnection>;
  paid?: Maybe<Scalars['Boolean']>;
  startAt: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
  vendorPaymentLines?: Maybe<VendorPaymentLineConnection>;
  vendorStatements?: Maybe<VendorStatementConnection>;
};


/** A MonthlyVendorPayment */
export type MonthlyVendorPaymentOrderItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A MonthlyVendorPayment */
export type MonthlyVendorPaymentVendorPaymentLinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A MonthlyVendorPayment */
export type MonthlyVendorPaymentVendorStatementsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for MonthlyVendorPayment. */
export type MonthlyVendorPaymentConnection = {
  __typename?: 'MonthlyVendorPaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MonthlyVendorPaymentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MonthlyVendorPayment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MonthlyVendorPaymentEdge = {
  __typename?: 'MonthlyVendorPaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MonthlyVendorPayment>;
};

export type MonthlyVendorPaymentFilter = {
  OR?: Maybe<Array<MonthlyVendorPaymentFilter>>;
  meta?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type Mutation = GraphqlAuth & {
  __typename?: 'Mutation';
  /** Create Account */
  createAccount?: Maybe<CreateAccountMutationPayload>;
  /** Create Address */
  createAddress?: Maybe<CreateAddressMutationPayload>;
  /** Create BankInformation */
  createBankInformation?: Maybe<CreateBankInformationMutationPayload>;
  /** Create A book */
  createBook?: Maybe<CreateBookMutationPayload>;
  /** Create A Customer */
  createCustomer?: Maybe<CreateCustomerMutationPayload>;
  /** Create Desire */
  createDesire?: Maybe<CreateDesireMutationPayload>;
  /** Create Drouot Import */
  createDrouotImport?: Maybe<CreateDrouotImportMutationPayload>;
  /** Create A Import */
  createImport?: Maybe<CreateImportMutationPayload>;
  createItem?: Maybe<CreateItemMutationPayload>;
  /** Create lot urls Import */
  createLotUrlsRainworxImport?: Maybe<CreateLotUrlsRainworxImportMutationPayload>;
  /** Create NewsLetter */
  createNewsLetter?: Maybe<CreateNewsLetterMutationPayload>;
  /** Update a Pickup Request */
  createPickupRequest?: Maybe<CreatePickupRequestMutationPayload>;
  /** Create Project */
  createProject?: Maybe<CreateProjectMutationPayload>;
  /** Create QuickText */
  createQuickText?: Maybe<CreateQuickTextMutationPayload>;
  /** Create Sale */
  createSale?: Maybe<CreateSaleMutationPayload>;
  /** Create all sale mandat for the sale */
  createSaleMandats?: Maybe<CreateSaleMandatMutationPayload>;
  /** Create Vacation */
  createVacation?: Maybe<CreateVacationMutationPayload>;
  customSignIn?: Maybe<CustomSignInPayload>;
  customSignUp?: Maybe<CustomSignUpPayload>;
  /** Update Account */
  customUpdateAccount?: Maybe<CustomUpdateAccountMutationPayload>;
  /** Deletes an user as an admin. */
  deleteUser?: Maybe<Scalars['Boolean']>;
  /** Destroy Customer */
  destroyCustomer?: Maybe<DestroyCustomerMutationPayload>;
  /** Destroy Desire */
  destroyDesire?: Maybe<DestroyDesireMutationPayload>;
  destroyItem?: Maybe<DestroyItemMutationPayload>;
  /** Destroy QuickText */
  destroyQuickText?: Maybe<DestroyQuickTextMutationPayload>;
  /** Destroy Sale */
  destroySale?: Maybe<DestroySaleMutationPayload>;
  /** Destroy Tag */
  destroyTag?: Maybe<DestroyTagMutationPayload>;
  /** Destroy Vacation */
  destroyVacation?: Maybe<DestroyVacationMutationPayload>;
  duplicateItem?: Maybe<DuplicateItemMutationPayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  lockAccount?: Maybe<LockAccountPayload>;
  /** Merge 2 Tags */
  mergeTag?: Maybe<MergeTagMutationPayload>;
  resendConfirmationInstructions?: Maybe<ResendConfirmationInstructionsPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  signIn?: Maybe<SignInPayload>;
  signUp?: Maybe<SignUpPayload>;
  unlockAccount?: Maybe<UnlockAccountPayload>;
  updateAccount?: Maybe<UpdateAccountPayload>;
  /** Update Addresses */
  updateAddress?: Maybe<UpdateAddressMutationPayload>;
  /** Update BankInformation */
  updateBankInformation?: Maybe<UpdateBankInformationMutationPayload>;
  /** Update A book */
  updateBook?: Maybe<UpdateBookMutationPayload>;
  /** Update A Customer */
  updateCustomer?: Maybe<UpdateCustomerMutationPayload>;
  /** Update Desire */
  updateDesire?: Maybe<UpdateDesireMutationPayload>;
  /** Update ExpertPayments */
  updateExpertPayment?: Maybe<UpdateExpertPaymentMutationPayload>;
  updateItem?: Maybe<UpdateItemMutationPayload>;
  /** Update item step */
  updateItemStep?: Maybe<UpdateItemStepMutationPayload>;
  /** Update MonthlyVendorPayments */
  updateMonthlyVendorPayment?: Maybe<UpdateMonthlyVendorPaymentMutationPayload>;
  /** Update Order Item */
  updateOrderItem?: Maybe<UpdateOrderItemMutationPayload>;
  /** Update Projectes */
  updateProject?: Maybe<UpdateProjectMutationPayload>;
  /** Update QuickTextes */
  updateQuickText?: Maybe<UpdateQuickTextMutationPayload>;
  /** Update Salees */
  updateSale?: Maybe<UpdateSaleMutationPayload>;
  /** Update A Tag */
  updateTag?: Maybe<UpdateTagMutationPayload>;
  /** Updates an existing user */
  updateUser?: Maybe<User>;
  /** Updates the role for an user as an admin. */
  updateUserRole?: Maybe<Scalars['Boolean']>;
  /** Update Vacationes */
  updateVacation?: Maybe<UpdateVacationMutationPayload>;
  /** Update Volumes */
  updateVolumes?: Maybe<UpdateVolumeMutationPayload>;
  /** Update WriterPayments */
  updateWriterPayment?: Maybe<UpdateWriterPaymentMutationPayload>;
  validateToken?: Maybe<ValidateTokenPayload>;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountMutationInput;
};


export type MutationCreateAddressArgs = {
  input: CreateAddressMutationInput;
};


export type MutationCreateBankInformationArgs = {
  input: CreateBankInformationMutationInput;
};


export type MutationCreateBookArgs = {
  input: CreateBookMutationInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerMutationInput;
};


export type MutationCreateDesireArgs = {
  input: CreateDesireMutationInput;
};


export type MutationCreateDrouotImportArgs = {
  input: CreateDrouotImportMutationInput;
};


export type MutationCreateImportArgs = {
  input: CreateImportMutationInput;
};


export type MutationCreateItemArgs = {
  input: CreateItemMutationInput;
};


export type MutationCreateLotUrlsRainworxImportArgs = {
  input: CreateLotUrlsRainworxImportMutationInput;
};


export type MutationCreateNewsLetterArgs = {
  input: CreateNewsLetterMutationInput;
};


export type MutationCreatePickupRequestArgs = {
  input: CreatePickupRequestMutationInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectMutationInput;
};


export type MutationCreateQuickTextArgs = {
  input: CreateQuickTextMutationInput;
};


export type MutationCreateSaleArgs = {
  input: CreateSaleMutationInput;
};


export type MutationCreateSaleMandatsArgs = {
  input: CreateSaleMandatMutationInput;
};


export type MutationCreateVacationArgs = {
  input: CreateVacationMutationInput;
};


export type MutationCustomSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};


export type MutationCustomSignUpArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  role: Scalars['String'];
  phone: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationCustomUpdateAccountArgs = {
  input: CustomUpdateAccountMutationInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDestroyCustomerArgs = {
  input: DestroyCustomerMutationInput;
};


export type MutationDestroyDesireArgs = {
  input: DestroyDesireMutationInput;
};


export type MutationDestroyItemArgs = {
  input: DestroyItemMutationInput;
};


export type MutationDestroyQuickTextArgs = {
  input: DestroyQuickTextMutationInput;
};


export type MutationDestroySaleArgs = {
  input: DestroySaleMutationInput;
};


export type MutationDestroyTagArgs = {
  input: DestroyTagMutationInput;
};


export type MutationDestroyVacationArgs = {
  input: DestroyVacationMutationInput;
};


export type MutationDuplicateItemArgs = {
  input: DuplicateItemMutationInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLockAccountArgs = {
  id: Scalars['ID'];
};


export type MutationMergeTagArgs = {
  input: MergeTagMutationInput;
};


export type MutationResendConfirmationInstructionsArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  resetPasswordToken: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};


export type MutationSignUpArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationUnlockAccountArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateAccountArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressMutationInput;
};


export type MutationUpdateBankInformationArgs = {
  input: UpdateBankInformationMutationInput;
};


export type MutationUpdateBookArgs = {
  input: UpdateBookMutationInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerMutationInput;
};


export type MutationUpdateDesireArgs = {
  input: UpdateDesireMutationInput;
};


export type MutationUpdateExpertPaymentArgs = {
  input: UpdateExpertPaymentMutationInput;
};


export type MutationUpdateItemArgs = {
  input: UpdateItemMutationInput;
};


export type MutationUpdateItemStepArgs = {
  input: UpdateItemStepMutationInput;
};


export type MutationUpdateMonthlyVendorPaymentArgs = {
  input: UpdateMonthlyVendorPaymentMutationInput;
};


export type MutationUpdateOrderItemArgs = {
  input: UpdateOrderItemMutationInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectMutationInput;
};


export type MutationUpdateQuickTextArgs = {
  input: UpdateQuickTextMutationInput;
};


export type MutationUpdateSaleArgs = {
  input: UpdateSaleMutationInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagMutationInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};


export type MutationUpdateVacationArgs = {
  input: UpdateVacationMutationInput;
};


export type MutationUpdateVolumesArgs = {
  input: UpdateVolumeMutationInput;
};


export type MutationUpdateWriterPaymentArgs = {
  input: UpdateWriterPaymentMutationInput;
};

export type NestedArtistInput = {
  createAndLink?: Maybe<Array<CreateArtistInput>>;
  update?: Maybe<UpdateArtistInput>;
  link?: Maybe<Array<LinkInput>>;
  deleteLink?: Maybe<Array<DeleteArtistInput>>;
};

export type NestedItemCategoryInput = {
  link?: Maybe<Array<LinkInput>>;
};

export type NestedItemInput = {
  createAndLink?: Maybe<Array<CreateItemInput>>;
  update?: Maybe<UpdateItemInput>;
  link?: Maybe<Array<LinkInput>>;
};

export type NestedItemSubCategoryInput = {
  link?: Maybe<Array<LinkInput>>;
};

export type NestedTagInput = {
  createAndLink?: Maybe<Array<CreateTagInput>>;
  deleteLink?: Maybe<Array<DeleteArtistInput>>;
};

export type NestedWriterInput = {
  createAndLink?: Maybe<Array<CreateWriterInput>>;
  update?: Maybe<UpdateWriterInput>;
  link?: Maybe<Array<LinkInput>>;
};

/** A NewsLetter */
export type NewsLetter = Node & {
  __typename?: 'NewsLetter';
  buyer?: Maybe<Buyer>;
  createdAt: Scalars['ISO8601DateTime'];
  customer?: Maybe<Customer>;
  emailFrom?: Maybe<Scalars['String']>;
  emailTo?: Maybe<Scalars['String']>;
  htmlContent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nbItems?: Maybe<Scalars['Int']>;
  sale: Sale;
  subject?: Maybe<Scalars['String']>;
  test: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for NewsLetter. */
export type NewsLetterConnection = {
  __typename?: 'NewsLetterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NewsLetterEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NewsLetter>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type NewsLetterEdge = {
  __typename?: 'NewsLetterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NewsLetter>;
};

export type NewsLetterFilter = {
  OR?: Maybe<Array<NewsLetterFilter>>;
  sale?: Maybe<SaleFilter>;
  test?: Maybe<OperatorBooleanFilter>;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID'];
};

export type OperatorBooleanFilter = {
  equalTo?: Maybe<Scalars['Boolean']>;
};

export type OperatorIdFilter = {
  contain?: Maybe<Scalars['ID']>;
  equalTo?: Maybe<Scalars['ID']>;
};

export type OperatorStringFilter = {
  contain?: Maybe<Scalars['String']>;
  equalTo?: Maybe<Scalars['String']>;
  isIn?: Maybe<Array<Scalars['String']>>;
};

/** A Order */
export type Order = Node & {
  __typename?: 'Order';
  buyer?: Maybe<Buyer>;
  carrierName?: Maybe<Scalars['String']>;
  carrierServiceName?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  labelUrls?: Maybe<Array<Scalars['String']>>;
  labels?: Maybe<LabelConnection>;
  orderItems?: Maybe<OrderItemConnection>;
  originState?: Maybe<Scalars['String']>;
  sale?: Maybe<Sale>;
  shippedAt?: Maybe<Scalars['ISO8601DateTime']>;
  shippingAddress?: Maybe<Address>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingPriceTaxIncluded?: Maybe<Scalars['String']>;
  shippingTax?: Maybe<Scalars['Float']>;
  source?: Maybe<Scalars['String']>;
  sourceRef?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  stripePaymentLink?: Maybe<Scalars['String']>;
  totalItemsPrice?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalPriceTaxIncluded?: Maybe<Scalars['String']>;
  totalTax?: Maybe<Scalars['Float']>;
  trackingNumbers?: Maybe<Array<Scalars['String']>>;
  trackingUrls?: Maybe<Array<Scalars['String']>>;
  undeplusRef?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A Order */
export type OrderLabelsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Order */
export type OrderOrderItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export type OrderFilter = {
  OR?: Maybe<Array<OrderFilter>>;
  meta?: Maybe<Scalars['String']>;
  state?: Maybe<OperatorStringFilter>;
  orderItems?: Maybe<OrderItemFilter>;
};

/** A OrderItem */
export type OrderItem = Node & {
  __typename?: 'OrderItem';
  /** ID of the object. */
  id: Scalars['ID'];
  item: Item;
  label?: Maybe<Label>;
  order?: Maybe<Order>;
  state?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Float']>;
  undeplusRef?: Maybe<Scalars['String']>;
  vendor?: Maybe<Vendor>;
};

/** The connection type for OrderItem. */
export type OrderItemConnection = {
  __typename?: 'OrderItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrderItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrderItemEdge = {
  __typename?: 'OrderItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderItem>;
};

export type OrderItemFilter = {
  OR?: Maybe<Array<OrderItemFilter>>;
  meta?: Maybe<Scalars['String']>;
  state?: Maybe<OperatorStringFilter>;
  item?: Maybe<ItemFilter>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** A PaidSlip */
export type PaidSlip = Node & {
  __typename?: 'PaidSlip';
  buyer?: Maybe<Buyer>;
  createdAt: Scalars['ISO8601DateTime'];
  document?: Maybe<CustomFile>;
  id: Scalars['ID'];
  items?: Maybe<ItemConnection>;
  nbItems?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  sale?: Maybe<Sale>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A PaidSlip */
export type PaidSlipItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for PaidSlip. */
export type PaidSlipConnection = {
  __typename?: 'PaidSlipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaidSlipEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PaidSlip>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PaidSlipEdge = {
  __typename?: 'PaidSlipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PaidSlip>;
};

export type PaidSlipFilter = {
  OR?: Maybe<Array<PaidSlipFilter>>;
  number?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  id?: Maybe<OperatorIdFilter>;
  vendor?: Maybe<VendorFilter>;
  sale?: Maybe<SaleFilter>;
};

/** A Part of Volume */
export type Part = Node & {
  __typename?: 'Part';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  volume: Volume;
};

/** The connection type for Part. */
export type PartConnection = {
  __typename?: 'PartConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PartEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Part>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PartEdge = {
  __typename?: 'PartEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Part>;
};

/** A PickupRequest */
export type PickupRequest = Node & {
  __typename?: 'PickupRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  pickupLocation?: Maybe<Scalars['String']>;
  pickupLocationCloseTime?: Maybe<Scalars['String']>;
  pickupTimestamp?: Maybe<Scalars['ISO8601DateTime']>;
  replans?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
};

/** The connection type for PickupRequest. */
export type PickupRequestConnection = {
  __typename?: 'PickupRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PickupRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PickupRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PickupRequestEdge = {
  __typename?: 'PickupRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PickupRequest>;
};

export type PickupRequestFilter = {
  OR?: Maybe<Array<PickupRequestFilter>>;
  pickupLocation?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
};

/** A Project */
export type Project = Node & {
  __typename?: 'Project';
  auctioneerNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  exclusif?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isClassicSale?: Maybe<Scalars['Boolean']>;
  itemNumberStart?: Maybe<Scalars['String']>;
  items?: Maybe<ItemConnection>;
  nbItems?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  owner?: Maybe<Vendor>;
  percentageProfitForOwner?: Maybe<Scalars['Float']>;
  percentageProfitForVendor?: Maybe<Scalars['Float']>;
  projectFees?: Maybe<ProjectFeeConnection>;
  sharingProfit?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vacations?: Maybe<VacationConnection>;
  vendor?: Maybe<Vendor>;
  writers?: Maybe<WriterConnection>;
};


/** A Project */
export type ProjectItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Project */
export type ProjectProjectFeesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Project */
export type ProjectVacationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Project */
export type ProjectWritersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Project. */
export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Project>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Project>;
};

/** A Project Fee */
export type ProjectFee = Node & {
  __typename?: 'ProjectFee';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  updatedAt: Scalars['ISO8601DateTime'];
  value?: Maybe<Scalars['Float']>;
};

/** The connection type for ProjectFee. */
export type ProjectFeeConnection = {
  __typename?: 'ProjectFeeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectFeeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProjectFee>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProjectFeeEdge = {
  __typename?: 'ProjectFeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProjectFee>;
};

export type ProjectFilter = {
  OR?: Maybe<Array<ProjectFilter>>;
  title?: Maybe<OperatorStringFilter>;
  number?: Maybe<OperatorStringFilter>;
  meta?: Maybe<Scalars['String']>;
  id?: Maybe<OperatorIdFilter>;
};

/** A PurchaseSlip */
export type PurchaseSlip = Node & {
  __typename?: 'PurchaseSlip';
  buyer?: Maybe<Buyer>;
  createdAt: Scalars['ISO8601DateTime'];
  document?: Maybe<CustomFile>;
  id: Scalars['ID'];
  items?: Maybe<ItemConnection>;
  nbItems?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  sale?: Maybe<Sale>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A PurchaseSlip */
export type PurchaseSlipItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for PurchaseSlip. */
export type PurchaseSlipConnection = {
  __typename?: 'PurchaseSlipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PurchaseSlipEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PurchaseSlip>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PurchaseSlipEdge = {
  __typename?: 'PurchaseSlipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PurchaseSlip>;
};

export type PurchaseSlipFilter = {
  OR?: Maybe<Array<PurchaseSlipFilter>>;
  number?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  id?: Maybe<OperatorIdFilter>;
  buyer?: Maybe<BuyerFilter>;
  sale?: Maybe<SaleFilter>;
};

/** The query root of this schema */
export type Query = {
  __typename?: 'Query';
  /** Returns the Account for a requested id */
  account?: Maybe<Account>;
  /** Returns the Address for a requested id */
  address?: Maybe<Address>;
  /** Addresses query */
  addresses: AddressConnection;
  /** Artists query */
  artists: ArtistConnection;
  /** Returns the BankInformation for a requested id */
  bankInformation?: Maybe<BankInformation>;
  /** Returns the Book for a requested id */
  book?: Maybe<Book>;
  /** Returns the Customer for a requested id */
  customer?: Maybe<Customer>;
  /** Customers query */
  customers: CustomerConnection;
  /** DepositSlips query */
  depositSlips: DepositSlipConnection;
  /** Returns the Desire for a desire id */
  desire?: Maybe<Desire>;
  /** Desires query */
  desires: DesireConnection;
  /** Returns the expert for a requested id */
  expert?: Maybe<Expert>;
  /** ExpertPayment query */
  expertPayment?: Maybe<ExpertPayment>;
  /** ExpertPayments query */
  expertPayments: ExpertPaymentConnection;
  /** Experts query */
  experts: ExpertConnection;
  /** Returns the Import for a requested id */
  import?: Maybe<Import>;
  /** Imports query */
  imports: ImportConnection;
  /** Returns the item for a requested id */
  item?: Maybe<Item>;
  /** Items Categories query */
  itemCategories: ItemCategoryConnection;
  /** Items Sub Categories query */
  itemSubCategories: ItemSubCategoryConnection;
  /** Items query */
  items: ItemConnection;
  /** MonthlyVendorPayment query */
  monthlyVendorPayment?: Maybe<MonthlyVendorPayment>;
  /** MonthlyVendorPayments query */
  monthlyVendorPayments: MonthlyVendorPaymentConnection;
  /** NewsLetters query */
  newsLetters: NewsLetterConnection;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /** Returns the Order for a requested id */
  order?: Maybe<Order>;
  /** Order Items query */
  orderItems: OrderItemConnection;
  /** Orders query */
  orders: OrderConnection;
  /** PaidSlips query */
  paidSlips: PaidSlipConnection;
  /** Returns the PickupRequest for a requested id */
  pickupRequest?: Maybe<PickupRequest>;
  /** PickupRequests query */
  pickupRequests: PickupRequestConnection;
  /** Returns the Project for a requested id */
  project?: Maybe<Project>;
  /** Projects query */
  projects: ProjectConnection;
  /** PurchaseSlips query */
  purchaseSlips: PurchaseSlipConnection;
  /** Returns the QuickText for a requested id */
  quickText?: Maybe<QuickText>;
  /** QuickTexts query */
  quickTexts: QuickTextConnection;
  /** Returns the Sale for a requested id */
  sale?: Maybe<Sale>;
  /** SaleMandats query */
  saleMandats: SaleMandatConnection;
  /** Sales query */
  sales: SaleConnection;
  /** Tag query */
  tag?: Maybe<Tag>;
  /** Tags query */
  tags: TagConnection;
  /** Returns the user for a requested id */
  user?: Maybe<User>;
  /** Users query */
  users: UserConnection;
  /** Returns the Vacation for a requested id */
  vacation?: Maybe<Vacation>;
  /** Vacations query */
  vacations: VacationConnection;
  /** Returns the vendor for a requested id */
  vendor?: Maybe<Vendor>;
  /** VendorStatements query */
  vendorStatements: VendorStatementConnection;
  /** Vendors query */
  vendors: VendorConnection;
  /** Returns the current user */
  viewer?: Maybe<User>;
  /** Returns the Writer for a requested id */
  writer?: Maybe<Writer>;
  /** WriterPayment query */
  writerPayment?: Maybe<WriterPayment>;
  /** WriterPayments query */
  writerPayments: WriterPaymentConnection;
  /** Writers query */
  writers: WriterConnection;
};


/** The query root of this schema */
export type QueryAccountArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAddressArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryAddressesArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<AddressFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryArtistsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<ArtistFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryBankInformationArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryBookArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryCustomersArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<CustomerFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryDepositSlipsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<DepositSlipFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryDesireArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryDesiresArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<DesireFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryExpertArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryExpertPaymentArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryExpertPaymentsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<ExpertPaymentFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryExpertsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<ExpertFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryImportArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryImportsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<ImportFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryItemArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryItemCategoriesArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<ItemCategoryFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryItemSubCategoriesArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<ItemSubCategoryFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryItemsArgs = {
  orderBy?: Maybe<Array<ItemOrder>>;
  where?: Maybe<ItemFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryMonthlyVendorPaymentArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryMonthlyVendorPaymentsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<MonthlyVendorPaymentFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryNewsLettersArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<NewsLetterFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryOrderArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryOrderItemsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<OrderItemFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryOrdersArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<OrderFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryPaidSlipsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<PaidSlipFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryPickupRequestArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryPickupRequestsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<PickupRequestFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryProjectArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryProjectsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<ProjectFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryPurchaseSlipsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<PurchaseSlipFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryQuickTextArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryQuickTextsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<QuickTextFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QuerySaleArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QuerySaleMandatsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<SaleMandatFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QuerySalesArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<SaleFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryTagArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryTagsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<TagFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryUserArgs = {
  accountableType?: Maybe<Scalars['String']>;
};


/** The query root of this schema */
export type QueryUsersArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<UserFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryVacationArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryVacationsArgs = {
  orderBy?: Maybe<Array<ItemOrder>>;
  where?: Maybe<VacationFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryVendorArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryVendorStatementsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<VendorStatementFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryVendorsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<VendorFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryViewerArgs = {
  accountableType?: Maybe<Scalars['String']>;
};


/** The query root of this schema */
export type QueryWriterArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryWriterPaymentArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QueryWriterPaymentsArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<WriterPaymentFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** The query root of this schema */
export type QueryWritersArgs = {
  orderBy?: Maybe<ItemOrder>;
  where?: Maybe<WriterFilter>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A QuickText */
export type QuickText = Node & {
  __typename?: 'QuickText';
  account: Account;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for QuickText. */
export type QuickTextConnection = {
  __typename?: 'QuickTextConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuickTextEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<QuickText>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type QuickTextEdge = {
  __typename?: 'QuickTextEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<QuickText>;
};

export type QuickTextFilter = {
  OR?: Maybe<Array<QuickTextFilter>>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ResendConfirmationInstructions. */
export type ResendConfirmationInstructionsPayload = {
  __typename?: 'ResendConfirmationInstructionsPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
  valid: Scalars['Boolean'];
};

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
};

/** A User */
export type Role = Node & {
  __typename?: 'Role';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** A Sale */
export type Sale = Node & {
  __typename?: 'Sale';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  expert?: Maybe<Expert>;
  externalEventRainworxId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  items?: Maybe<ItemConnection>;
  name?: Maybe<Scalars['String']>;
  nbItems?: Maybe<Scalars['Int']>;
  saleMandats?: Maybe<SaleMandatConnection>;
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A Sale */
export type SaleItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Sale */
export type SaleSaleMandatsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Sale. */
export type SaleConnection = {
  __typename?: 'SaleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SaleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Sale>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SaleEdge = {
  __typename?: 'SaleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Sale>;
};

export type SaleFilter = {
  OR?: Maybe<Array<SaleFilter>>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  id?: Maybe<OperatorIdFilter>;
};

/** A SaleMandat */
export type SaleMandat = Node & {
  __typename?: 'SaleMandat';
  createdAt: Scalars['ISO8601DateTime'];
  document?: Maybe<CustomFile>;
  id: Scalars['ID'];
  items?: Maybe<ItemConnection>;
  nbItems?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  sale?: Maybe<Sale>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
};


/** A SaleMandat */
export type SaleMandatItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for SaleMandat. */
export type SaleMandatConnection = {
  __typename?: 'SaleMandatConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SaleMandatEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SaleMandat>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SaleMandatEdge = {
  __typename?: 'SaleMandatEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SaleMandat>;
};

export type SaleMandatFilter = {
  OR?: Maybe<Array<SaleMandatFilter>>;
  number?: Maybe<Scalars['String']>;
  id?: Maybe<OperatorIdFilter>;
  vendor?: Maybe<VendorFilter>;
  sale?: Maybe<SaleFilter>;
};

/** Autogenerated return type of SignIn. */
export type SignInPayload = {
  __typename?: 'SignInPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Autogenerated return type of SignUp. */
export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** A Tag of Volume */
export type Tag = Node & {
  __typename?: 'Tag';
  account?: Maybe<Account>;
  auto?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Tag. */
export type TagConnection = {
  __typename?: 'TagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TagEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Tag>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TagEdge = {
  __typename?: 'TagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Tag>;
};

export type TagFilter = {
  OR?: Maybe<Array<TagFilter>>;
  tagName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
};

/** Objects which may belongs_to an item */
export type Template = Book | Jewelry;

/** Autogenerated return type of UnlockAccount. */
export type UnlockAccountPayload = {
  __typename?: 'UnlockAccountPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Attributes to update a Account. */
export type UpdateAccountInput = {
  /** first_name */
  firstName?: Maybe<Scalars['String']>;
  /** last_name */
  lastName?: Maybe<Scalars['String']>;
  /** civility */
  civility?: Maybe<Scalars['String']>;
  /** birth_date */
  birthDate?: Maybe<Scalars['String']>;
  /** corporate_form */
  corporateForm?: Maybe<Scalars['String']>;
  /** phone */
  phone?: Maybe<Scalars['String']>;
  /** company_name */
  companyName?: Maybe<Scalars['String']>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** is_professional */
  isProfessional?: Maybe<Scalars['String']>;
  /** import_condition_accepted */
  importConditionAccepted?: Maybe<Scalars['Boolean']>;
  /** writer_pourcentage_fees */
  writerPourcentageFees?: Maybe<Scalars['Float']>;
  /** writer_fix_fees */
  writerFixFees?: Maybe<Scalars['Float']>;
  /** writerType */
  writerType?: Maybe<Scalars['String']>;
  /** state */
  state?: Maybe<Scalars['String']>;
  identityDocuments?: Maybe<Array<UploadFileInput>>;
  ribDocuments?: Maybe<Array<UploadFileInput>>;
};

/** Autogenerated return type of UpdateAccount. */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Attributes to update a Address. */
export type UpdateAddressInput = {
  /** lang */
  number?: Maybe<Scalars['String']>;
  /** lang */
  addressLine1?: Maybe<Scalars['String']>;
  /** lang */
  addressLine2?: Maybe<Scalars['String']>;
  /** lang */
  addressLine3?: Maybe<Scalars['String']>;
  /** lang */
  companyName?: Maybe<Scalars['String']>;
  /** lang */
  firstName?: Maybe<Scalars['String']>;
  /** lang */
  lastName?: Maybe<Scalars['String']>;
  /** lang */
  phone1?: Maybe<Scalars['String']>;
  /** lang */
  phone2?: Maybe<Scalars['String']>;
  /** lang */
  email?: Maybe<Scalars['String']>;
  /** lang */
  zip?: Maybe<Scalars['String']>;
  /** lang */
  city?: Maybe<Scalars['String']>;
  /** lang */
  country?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateAddressMutation */
export type UpdateAddressMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateAddressInput;
};

/** Autogenerated return type of UpdateAddressMutation. */
export type UpdateAddressMutationPayload = {
  __typename?: 'UpdateAddressMutationPayload';
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to update a Artist. */
export type UpdateArtistInput = {
  /** first_name */
  firstName?: Maybe<Scalars['String']>;
  /** last_name */
  lastName: Scalars['String'];
  /** tag */
  tag?: Maybe<Scalars['String']>;
  /** is_group */
  isGroup?: Maybe<Scalars['String']>;
};

/** Attributes to update a BankInformation. */
export type UpdateBankInformationInput = {
  /** lang */
  iban?: Maybe<Scalars['String']>;
  /** lang */
  bic?: Maybe<Scalars['String']>;
  /** lang */
  fullName?: Maybe<Scalars['String']>;
  /** lang */
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateBankInformationMutation */
export type UpdateBankInformationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateBankInformationInput;
};

/** Autogenerated return type of UpdateBankInformationMutation. */
export type UpdateBankInformationMutationPayload = {
  __typename?: 'UpdateBankInformationMutationPayload';
  bankInformation?: Maybe<BankInformation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to update a book. */
export type UpdateBookInput = {
  /** lang */
  lang?: Maybe<Scalars['String']>;
  item?: Maybe<NestedItemInput>;
  /** publishers */
  publishers?: Maybe<Scalars['String']>;
  /** publisher_known_but_not_mentioned */
  publisherKnownButNotMentioned?: Maybe<Scalars['Boolean']>;
  /** date_from */
  dateFrom?: Maybe<Scalars['String']>;
  /** date_to */
  dateTo?: Maybe<Scalars['String']>;
  /** date_option */
  dateOption?: Maybe<Scalars['String']>;
  /** editing_attribute */
  editingAttribute?: Maybe<Scalars['String']>;
  /** editing_attribute_details */
  editingAttributeDetails?: Maybe<Scalars['String']>;
  /** assembly_type */
  assemblyType?: Maybe<Scalars['String']>;
  /** assembly_specification */
  assemblySpecification?: Maybe<Scalars['String']>;
  /** paperback_type */
  paperbackType?: Maybe<Scalars['String']>;
  /** binding_format */
  bindingFormat?: Maybe<Scalars['String']>;
  /** binding_format_details */
  bindingFormatDetails?: Maybe<Scalars['String']>;
  /** binding_type */
  bindingType?: Maybe<Scalars['String']>;
  /** binding_material */
  bindingMaterial?: Maybe<Scalars['String']>;
  /** binding_style */
  bindingStyle?: Maybe<Scalars['String']>;
  /** binding_color */
  bindingColor?: Maybe<Scalars['String']>;
  /** is_binding_signed */
  isBindingSigned?: Maybe<Scalars['Boolean']>;
  /** book_spine_type */
  bookSpineType?: Maybe<Scalars['String']>;
  /** book_spine_material */
  bookSpineMaterial?: Maybe<Scalars['String']>;
  /** book_spine_ornament */
  bookSpineOrnament1?: Maybe<Scalars['String']>;
  /** book_spine_ornament */
  bookSpineOrnament1Option?: Maybe<Scalars['String']>;
  /** book_spine_ornament */
  bookSpineOrnament2?: Maybe<Scalars['String']>;
  /** book_spine_ornament */
  bookSpineOrnament2Option?: Maybe<Scalars['String']>;
  /** book_spine_ornament */
  bookSpineOrnamentOption?: Maybe<Scalars['String']>;
  /** cover_material */
  coverMaterial?: Maybe<Scalars['String']>;
  /** cover_ornament */
  coverOrnament1?: Maybe<Scalars['String']>;
  /** cover_ornament */
  coverOrnament1Option?: Maybe<Scalars['String']>;
  /** cover_ornament2 */
  coverOrnament2?: Maybe<Scalars['String']>;
  /** cover_ornament */
  coverOrnament2Option?: Maybe<Scalars['String']>;
  /** cover_ornament_option */
  coverOrnamentOption?: Maybe<Scalars['String']>;
  /** cover_color */
  coverColor?: Maybe<Scalars['String']>;
  /** binding_format */
  coverType?: Maybe<Scalars['String']>;
  /** cover_quality */
  coverQuality?: Maybe<Scalars['String']>;
  /** edge_type */
  edgeType?: Maybe<Scalars['String']>;
  /** edge_style */
  edgeStyle?: Maybe<Scalars['String']>;
  /** edge_color */
  edgeColor?: Maybe<Scalars['String']>;
  bibliographyComment?: Maybe<Scalars['String']>;
  illustrationComment?: Maybe<Scalars['String']>;
  collationComment?: Maybe<Scalars['String']>;
  isApproximatelyPaginating?: Maybe<Scalars['Boolean']>;
  isPaginating?: Maybe<Scalars['Boolean']>;
  paginationInfo?: Maybe<Scalars['String']>;
  /** binding_format */
  size?: Maybe<Scalars['String']>;
  /** binding_format */
  bindingTypeOptions?: Maybe<Scalars['String']>;
  /** binding_format */
  bindingMaterialOptions?: Maybe<Scalars['String']>;
  /** binding_format */
  bindingAge?: Maybe<Scalars['String']>;
  /** binding_format */
  bindingAdditionalOptions?: Maybe<Scalars['String']>;
  /** binding_format */
  bookBinder?: Maybe<Scalars['String']>;
  /** is_oblong */
  isOblong?: Maybe<Scalars['Boolean']>;
  /** dumb_spine */
  dumbSpine?: Maybe<Scalars['Boolean']>;
  /** casing_type */
  casingType?: Maybe<Scalars['String']>;
  /** with_folder */
  withFolder?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateBookMutation */
export type UpdateBookMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateBookInput;
};

/** Autogenerated return type of UpdateBookMutation. */
export type UpdateBookMutationPayload = {
  __typename?: 'UpdateBookMutationPayload';
  book?: Maybe<Book>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to update a Customer. */
export type UpdateCustomerInput = {
  /** Description */
  addressLine1?: Maybe<Scalars['String']>;
  /** Description */
  addressLine2?: Maybe<Scalars['String']>;
  /** Description */
  addressLine3?: Maybe<Scalars['String']>;
  /** Description */
  city?: Maybe<Scalars['String']>;
  /** Description */
  country?: Maybe<Scalars['String']>;
  /** Description */
  email: Scalars['String'];
  /** Description */
  firstName?: Maybe<Scalars['String']>;
  /** Description */
  fullName?: Maybe<Scalars['String']>;
  /** Description */
  lastName?: Maybe<Scalars['String']>;
  /** Description */
  phone1?: Maybe<Scalars['String']>;
  /** Description */
  phone2?: Maybe<Scalars['String']>;
  /** Description */
  zip?: Maybe<Scalars['String']>;
  itemCategories?: Maybe<NestedItemCategoryInput>;
  itemSubCategories?: Maybe<NestedItemSubCategoryInput>;
  tags?: Maybe<NestedTagInput>;
};

/** Autogenerated input type of UpdateCustomerMutation */
export type UpdateCustomerMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: UpdateCustomerInput;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateCustomerMutation. */
export type UpdateCustomerMutationPayload = {
  __typename?: 'UpdateCustomerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to update a Desire. */
export type UpdateDesireInput = {
  /** name */
  title?: Maybe<Scalars['String']>;
  tags?: Maybe<NestedTagInput>;
};

/** Autogenerated input type of UpdateDesireMutation */
export type UpdateDesireMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: UpdateDesireInput;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateDesireMutation. */
export type UpdateDesireMutationPayload = {
  __typename?: 'UpdateDesireMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  desire?: Maybe<Desire>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to update a ExpertPayment. */
export type UpdateExpertPaymentInput = {
  invoices?: Maybe<Array<UploadFileInput>>;
  /** paid */
  paid?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateExpertPaymentMutation */
export type UpdateExpertPaymentMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateExpertPaymentInput;
};

/** Autogenerated return type of UpdateExpertPaymentMutation. */
export type UpdateExpertPaymentMutationPayload = {
  __typename?: 'UpdateExpertPaymentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  expertPayment?: Maybe<ExpertPayment>;
};

export type UpdateImagePositionInput = {
  from: Scalars['Int'];
  to: Scalars['Int'];
};

/** Attributes to update a user. */
export type UpdateItemInput = {
  artistUnknown?: Maybe<Scalars['Boolean']>;
  artistCollectif?: Maybe<Scalars['Boolean']>;
  artistKnownButNotMentioned?: Maybe<Scalars['Boolean']>;
  artists?: Maybe<NestedArtistInput>;
  authenticityDocuments?: Maybe<Array<UploadFileInput>>;
  catchPhrase?: Maybe<Scalars['String']>;
  catchPhraseEnabled?: Maybe<Scalars['Boolean']>;
  conditionAccepted?: Maybe<Scalars['Boolean']>;
  conditionsComment?: Maybe<Scalars['String']>;
  customDescriptionEnabled?: Maybe<Scalars['Boolean']>;
  customDescription?: Maybe<Scalars['String']>;
  autoDescription?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  dateOption?: Maybe<Scalars['String']>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  estimatedPrice?: Maybe<Scalars['Float']>;
  expertId?: Maybe<Scalars['ID']>;
  globalComment?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<UploadImageInput>>;
  imagePositionsToDelete?: Maybe<Array<Scalars['Int']>>;
  invoices?: Maybe<Array<UploadFileInput>>;
  itemCategoryId?: Maybe<Scalars['String']>;
  itemSubCategoryId?: Maybe<Scalars['String']>;
  /** lang */
  lang?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
  locations?: Maybe<Scalars['String']>;
  locationKnownButNotMentioned?: Maybe<Scalars['Boolean']>;
  lotId?: Maybe<Scalars['Int']>;
  otherComment?: Maybe<Scalars['String']>;
  reservedPrice?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  customSkuPart?: Maybe<Scalars['String']>;
  startingPrice?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  tags?: Maybe<NestedTagInput>;
  /** The template id */
  templateId?: Maybe<Scalars['Int']>;
  /** Title */
  title?: Maybe<Scalars['String']>;
  updateImagePositions?: Maybe<UpdateImagePositionInput>;
  vacationId?: Maybe<Scalars['ID']>;
  saleId?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
  writerId?: Maybe<Scalars['ID']>;
  width?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of UpdateItemMutation */
export type UpdateItemMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: UpdateItemInput;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateItemMutation. */
export type UpdateItemMutationPayload = {
  __typename?: 'UpdateItemMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  item: Item;
};

/** Attributes to update an item step. */
export type UpdateItemStepInput = {
  /** comment */
  comment?: Maybe<Scalars['String']>;
  /** number */
  number?: Maybe<Scalars['Int']>;
  /** state */
  state?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateItemStepMutation */
export type UpdateItemStepMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateItemStepInput;
};

/** Autogenerated return type of UpdateItemStepMutation. */
export type UpdateItemStepMutationPayload = {
  __typename?: 'UpdateItemStepMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  itemStep?: Maybe<ItemStep>;
};

/** Attributes to update a MonthlyVendorPayment. */
export type UpdateMonthlyVendorPaymentInput = {
  invoices?: Maybe<Array<UploadFileInput>>;
  /** paid */
  paid?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateMonthlyVendorPaymentMutation */
export type UpdateMonthlyVendorPaymentMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateMonthlyVendorPaymentInput;
};

/** Autogenerated return type of UpdateMonthlyVendorPaymentMutation. */
export type UpdateMonthlyVendorPaymentMutationPayload = {
  __typename?: 'UpdateMonthlyVendorPaymentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  monthlyVendorPayment?: Maybe<MonthlyVendorPayment>;
};

/** Attributes to update a OrderItem. */
export type UpdateOrderItemInput = {
  /** state */
  state?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateOrderItemMutation */
export type UpdateOrderItemMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateOrderItemInput;
};

/** Autogenerated return type of UpdateOrderItemMutation. */
export type UpdateOrderItemMutationPayload = {
  __typename?: 'UpdateOrderItemMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  orderItem?: Maybe<OrderItem>;
};

/** Attributes to update a Project. */
export type UpdateProjectInput = {
  /** title */
  title?: Maybe<Scalars['String']>;
  /** number */
  number?: Maybe<Scalars['String']>;
  /** item_number_start */
  itemNumberStart?: Maybe<Scalars['String']>;
  /** exclusif */
  exclusif?: Maybe<Scalars['Boolean']>;
  /** is_classic_sale */
  isClassicSale?: Maybe<Scalars['Boolean']>;
  /** sharing_profit */
  sharingProfit?: Maybe<Scalars['Boolean']>;
  /** percentage_profit_for_owner */
  percentageProfitForOwner?: Maybe<Scalars['Float']>;
  /** percentage_profit_for_vendor */
  percentageProfitForVendor?: Maybe<Scalars['Float']>;
  /** auctioneer_number */
  auctioneerNumber?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['ID']>;
  items?: Maybe<NestedItemInput>;
  writers?: Maybe<NestedWriterInput>;
};

/** Autogenerated input type of UpdateProjectMutation */
export type UpdateProjectMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateProjectInput;
};

/** Autogenerated return type of UpdateProjectMutation. */
export type UpdateProjectMutationPayload = {
  __typename?: 'UpdateProjectMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  project?: Maybe<Project>;
};

/** Attributes to update a QuickText. */
export type UpdateQuickTextInput = {
  /** title */
  title?: Maybe<Scalars['String']>;
  /** text */
  text?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of UpdateQuickTextMutation */
export type UpdateQuickTextMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateQuickTextInput;
};

/** Autogenerated return type of UpdateQuickTextMutation. */
export type UpdateQuickTextMutationPayload = {
  __typename?: 'UpdateQuickTextMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  quickText?: Maybe<QuickText>;
};

/** Attributes to update a Sale. */
export type UpdateSaleInput = {
  /** name */
  name?: Maybe<Scalars['String']>;
  /** state */
  state?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** start_at */
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** end_at */
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  image?: Maybe<UploadImageInput>;
  /** external_event_rainworx_id */
  externalEventRainworxId?: Maybe<Scalars['Int']>;
  expertId?: Maybe<Scalars['ID']>;
  items?: Maybe<NestedItemInput>;
};

/** Autogenerated input type of UpdateSaleMutation */
export type UpdateSaleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateSaleInput;
};

/** Autogenerated return type of UpdateSaleMutation. */
export type UpdateSaleMutationPayload = {
  __typename?: 'UpdateSaleMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  sale?: Maybe<Sale>;
};

/** Attributes to update a Tag. */
export type UpdateTagInput = {
  /** name */
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateTagMutation */
export type UpdateTagMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: UpdateTagInput;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateTagMutation. */
export type UpdateTagMutationPayload = {
  __typename?: 'UpdateTagMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  tag?: Maybe<Tag>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UserUpdateInput;
};

/** Autogenerated input type of UpdateUserRole */
export type UpdateUserRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** "user" or "admin" or "expert" or "vendor" */
  role: Scalars['String'];
};

/** Attributes to update a Vacation. */
export type UpdateVacationInput = {
  /** name */
  name?: Maybe<Scalars['String']>;
  /** state */
  state?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** start_at */
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** end_at */
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  image?: Maybe<UploadImageInput>;
  expertId?: Maybe<Scalars['ID']>;
  items?: Maybe<NestedItemInput>;
};

/** Autogenerated input type of UpdateVacationMutation */
export type UpdateVacationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateVacationInput;
};

/** Autogenerated return type of UpdateVacationMutation. */
export type UpdateVacationMutationPayload = {
  __typename?: 'UpdateVacationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  vacation?: Maybe<Vacation>;
};

/** Attributes to update some Volumes and parts. */
export type UpdateVolumeInput = {
  /** book_id */
  bookId: Scalars['String'];
  /** nb_volumes */
  nbVolumes: Scalars['Int'];
  /** nb_parts */
  nbParts: Scalars['Int'];
};

/** Autogenerated input type of UpdateVolumeMutation */
export type UpdateVolumeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  attributes: UpdateVolumeInput;
};

/** Autogenerated return type of UpdateVolumeMutation. */
export type UpdateVolumeMutationPayload = {
  __typename?: 'UpdateVolumeMutationPayload';
  book?: Maybe<Book>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
};

/** Attributes to update a Writer. */
export type UpdateWriterInput = {
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** The account id */
  accountId?: Maybe<Scalars['Int']>;
};

/** Attributes to update a WriterPayment. */
export type UpdateWriterPaymentInput = {
  invoices?: Maybe<Array<UploadFileInput>>;
  /** paid */
  paid?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateWriterPaymentMutation */
export type UpdateWriterPaymentMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  attributes: UpdateWriterPaymentInput;
};

/** Autogenerated return type of UpdateWriterPaymentMutation. */
export type UpdateWriterPaymentMutationPayload = {
  __typename?: 'UpdateWriterPaymentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ValidationErrors>;
  writerPayment?: Maybe<WriterPayment>;
};


export type UploadFileInput = {
  file: Scalars['Upload'];
};

export type UploadImageInput = {
  position?: Maybe<Scalars['Int']>;
  image: Scalars['Upload'];
};

/** A User */
export type User = Node & {
  __typename?: 'User';
  accountTypes?: Maybe<Array<Scalars['String']>>;
  accounts: AccountConnection;
  createdAt: Scalars['ISO8601DateTime'];
  currentRole: Role;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  lastAccountTypeUsed?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A User */
export type UserAccountsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  accountableType?: Maybe<Scalars['String']>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserFilter = {
  OR?: Maybe<Array<UserFilter>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

/** Attributes to update a user. */
export type UserUpdateInput = {
  /** Email of user */
  email?: Maybe<Scalars['String']>;
  /** Firstname of user */
  firstName?: Maybe<Scalars['String']>;
  /** Lastname of user */
  lastName?: Maybe<Scalars['String']>;
  /** Password of user */
  password?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
};

/** A Vacation */
export type Vacation = Node & {
  __typename?: 'Vacation';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['ISO8601DateTime']>;
  expert?: Maybe<Expert>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  items?: Maybe<ItemConnection>;
  lots?: Maybe<LotConnection>;
  name?: Maybe<Scalars['String']>;
  nbItems?: Maybe<Scalars['Int']>;
  project?: Maybe<Project>;
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vacation?: Maybe<Vacation>;
};


/** A Vacation */
export type VacationItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Vacation */
export type VacationLotsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Vacation. */
export type VacationConnection = {
  __typename?: 'VacationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VacationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Vacation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VacationEdge = {
  __typename?: 'VacationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Vacation>;
};

export type VacationFilter = {
  OR?: Maybe<Array<VacationFilter>>;
  vacationName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  projectId?: Maybe<OperatorIdFilter>;
};

/** Autogenerated return type of ValidateToken. */
export type ValidateTokenPayload = {
  __typename?: 'ValidateTokenPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
  valid: Scalars['Boolean'];
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  details: Scalars['String'];
  fullMessages: Array<Scalars['String']>;
};

/** An Vendor */
export type Vendor = Node & {
  __typename?: 'Vendor';
  account: Account;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  externalRainworxId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  items?: Maybe<ItemConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendorPlan?: Maybe<VendorPlan>;
};


/** An Vendor */
export type VendorItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Vendor. */
export type VendorConnection = {
  __typename?: 'VendorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VendorEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Vendor>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VendorEdge = {
  __typename?: 'VendorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Vendor>;
};

export type VendorFilter = {
  OR?: Maybe<Array<VendorFilter>>;
  description?: Maybe<Scalars['String']>;
  account?: Maybe<AccountFilter>;
  id?: Maybe<OperatorIdFilter>;
};

/** A VendorPaymentLine */
export type VendorPaymentLine = Node & {
  __typename?: 'VendorPaymentLine';
  amount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['ISO8601DateTime'];
  feeAmount?: Maybe<Scalars['Float']>;
  feePercentage?: Maybe<Scalars['Float']>;
  /** ID of the object. */
  id: Scalars['ID'];
  orderItems?: Maybe<OrderItemConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A VendorPaymentLine */
export type VendorPaymentLineOrderItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for VendorPaymentLine. */
export type VendorPaymentLineConnection = {
  __typename?: 'VendorPaymentLineConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VendorPaymentLineEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<VendorPaymentLine>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VendorPaymentLineEdge = {
  __typename?: 'VendorPaymentLineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<VendorPaymentLine>;
};

/** An Vendor */
export type VendorPlan = Node & {
  __typename?: 'VendorPlan';
  createdAt: Scalars['ISO8601DateTime'];
  expertiseFirmEnabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  items?: Maybe<ItemConnection>;
  name?: Maybe<Scalars['String']>;
  projectsEnabled?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
};


/** An Vendor */
export type VendorPlanItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A VendorStatement */
export type VendorStatement = Node & {
  __typename?: 'VendorStatement';
  createdAt: Scalars['ISO8601DateTime'];
  document?: Maybe<CustomFile>;
  id: Scalars['ID'];
  nbItems?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  orderItems?: Maybe<OrderItemConnection>;
  sale?: Maybe<Sale>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
};


/** A VendorStatement */
export type VendorStatementOrderItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for VendorStatement. */
export type VendorStatementConnection = {
  __typename?: 'VendorStatementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VendorStatementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<VendorStatement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VendorStatementEdge = {
  __typename?: 'VendorStatementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<VendorStatement>;
};

export type VendorStatementFilter = {
  OR?: Maybe<Array<VendorStatementFilter>>;
  number?: Maybe<Scalars['String']>;
  id?: Maybe<OperatorIdFilter>;
  vendor?: Maybe<VendorFilter>;
  sale?: Maybe<SaleFilter>;
};

/** A Volume */
export type Volume = Node & {
  __typename?: 'Volume';
  book: Book;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  parts?: Maybe<PartConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A Volume */
export type VolumePartsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Volume. */
export type VolumeConnection = {
  __typename?: 'VolumeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VolumeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Volume>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VolumeEdge = {
  __typename?: 'VolumeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Volume>;
};

/** An Writer */
export type Writer = Node & {
  __typename?: 'Writer';
  account: Account;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kpis?: Maybe<WriterKpis>;
  nbItemsAfterAccepted?: Maybe<WriterKpiCreated>;
  nbItemsAfterShipped?: Maybe<WriterKpiCreated>;
  nbItemsCreated?: Maybe<WriterKpiCreated>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor: Vendor;
};

/** The connection type for Writer. */
export type WriterConnection = {
  __typename?: 'WriterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WriterEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Writer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WriterEdge = {
  __typename?: 'WriterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Writer>;
};

export type WriterFilter = {
  OR?: Maybe<Array<WriterFilter>>;
  description?: Maybe<Scalars['String']>;
  account?: Maybe<AccountFilter>;
  projects?: Maybe<ProjectFilter>;
};

/** Writer KPIS states */
export type WriterItemStates = {
  __typename?: 'WriterItemStates';
  accepted?: Maybe<Scalars['Int']>;
  draft?: Maybe<Scalars['Int']>;
  needUpdate?: Maybe<Scalars['Int']>;
  refused?: Maybe<Scalars['Int']>;
  underExpertise?: Maybe<Scalars['Int']>;
  waitingExpertise?: Maybe<Scalars['Int']>;
};

/** An Writer */
export type WriterKpiCreated = {
  __typename?: 'WriterKpiCreated';
  currentMonth?: Maybe<Scalars['Int']>;
  lastMonth?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  totalAmountCurrentMonth?: Maybe<Scalars['Int']>;
  totalAmountLastMonth?: Maybe<Scalars['Int']>;
};

/** Writer KPIS */
export type WriterKpis = {
  __typename?: 'WriterKpis';
  itemAcceptedPercentage?: Maybe<Scalars['Int']>;
  itemPercentageStates?: Maybe<WriterItemStates>;
  totalFromBeginning?: Maybe<Scalars['Int']>;
};

/** A WriterPayment */
export type WriterPayment = Node & {
  __typename?: 'WriterPayment';
  createdAt: Scalars['ISO8601DateTime'];
  endAt: Scalars['ISO8601DateTime'];
  /** ID of the object. */
  id: Scalars['ID'];
  invoices?: Maybe<Array<CustomFile>>;
  orderItems?: Maybe<OrderItemConnection>;
  paid?: Maybe<Scalars['Boolean']>;
  startAt: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
  writer?: Maybe<Writer>;
  writerPaymentLines?: Maybe<WriterPaymentLineConnection>;
};


/** A WriterPayment */
export type WriterPaymentOrderItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A WriterPayment */
export type WriterPaymentWriterPaymentLinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for WriterPayment. */
export type WriterPaymentConnection = {
  __typename?: 'WriterPaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WriterPaymentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WriterPayment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WriterPaymentEdge = {
  __typename?: 'WriterPaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<WriterPayment>;
};

export type WriterPaymentFilter = {
  OR?: Maybe<Array<WriterPaymentFilter>>;
  meta?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/** A WriterPaymentLine */
export type WriterPaymentLine = Node & {
  __typename?: 'WriterPaymentLine';
  amount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['ISO8601DateTime'];
  /** ID of the object. */
  id: Scalars['ID'];
  orderItems?: Maybe<OrderItemConnection>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** A WriterPaymentLine */
export type WriterPaymentLineOrderItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for WriterPaymentLine. */
export type WriterPaymentLineConnection = {
  __typename?: 'WriterPaymentLineConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WriterPaymentLineEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WriterPaymentLine>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WriterPaymentLineEdge = {
  __typename?: 'WriterPaymentLineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<WriterPaymentLine>;
};

export type CommonViewerWithAccountQueryVariables = Exact<{
  accountableType: Scalars['String'];
}>;


export type CommonViewerWithAccountQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'imageUrl' | 'accountTypes' | 'lastAccountTypeUsed'>
    & { currentRole: (
      { __typename?: 'Role' }
      & Pick<Role, 'name'>
    ), accounts: (
      { __typename?: 'AccountConnection' }
      & { edges?: Maybe<Array<Maybe<(
        { __typename?: 'AccountEdge' }
        & { node?: Maybe<(
          { __typename?: 'Account' }
          & Pick<Account, 'id' | 'importConditionAccepted' | 'state' | 'firstName' | 'lastName' | 'completed' | 'nbItems'>
          & { accountable: (
            { __typename: 'Buyer' }
            & Pick<Buyer, 'id'>
          ) | (
            { __typename: 'Expert' }
            & Pick<Expert, 'id'>
          ) | (
            { __typename: 'Vendor' }
            & Pick<Vendor, 'id'>
            & { vendorPlan?: Maybe<(
              { __typename?: 'VendorPlan' }
              & VendorPlanFragment
            )> }
          ) | (
            { __typename: 'Writer' }
            & Pick<Writer, 'id'>
            & { vendor: (
              { __typename?: 'Vendor' }
              & Pick<Vendor, 'id'>
              & { vendorPlan?: Maybe<(
                { __typename?: 'VendorPlan' }
                & VendorPlanFragment
              )> }
            ) }
          ) }
        )> }
      )>>> }
    ) }
  )> }
);

export type VendorPlanFragment = (
  { __typename?: 'VendorPlan' }
  & Pick<VendorPlan, 'name' | 'expertiseFirmEnabled' | 'projectsEnabled'>
);

export type CommonViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type CommonViewerQuery = (
  { __typename?: 'Query' }
  & { viewer?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'lastAccountTypeUsed' | 'email' | 'firstName' | 'lastName' | 'imageUrl'>
    & { currentRole: (
      { __typename?: 'Role' }
      & Pick<Role, 'name'>
    ) }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type CustomersQueryVariables = Exact<{
  where?: Maybe<CustomerFilter>;
}>;


export type CustomersQuery = (
  { __typename?: 'Query' }
  & { customers: (
    { __typename?: 'CustomerConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CustomerEdge' }
      & { node?: Maybe<(
        { __typename?: 'Customer' }
        & CustomerTableFragment
      )> }
    )>>> }
  ) }
);

export type CustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerFragment
  )> }
);

export type CustomerEdgesFragment = (
  { __typename?: 'CustomerConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'CustomerEdge' }
    & CustomerNodeFragment
  )>>> }
);

export type CustomerNodeFragment = (
  { __typename?: 'CustomerEdge' }
  & { node?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerFragment
  )> }
);

export type ItemSubCategoryFragment = (
  { __typename?: 'ItemSubCategory' }
  & Pick<ItemSubCategory, 'id' | 'name'>
);

export type CustomerTableFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'id' | 'lastName' | 'firstName' | 'email'>
  & { tags?: Maybe<(
    { __typename?: 'TagConnection' }
    & TagEdgesFragment
  )> }
);

export type CustomerFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'id' | 'lastName' | 'firstName' | 'email' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'city' | 'zip' | 'country' | 'phone1' | 'phone2'>
  & { itemCategories?: Maybe<(
    { __typename?: 'CustomerItemCategoryConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CustomerItemCategoryEdge' }
      & { node?: Maybe<(
        { __typename?: 'CustomerItemCategory' }
        & Pick<CustomerItemCategory, 'id' | 'name'>
      )> }
    )>>> }
  )>, itemSubCategories?: Maybe<(
    { __typename?: 'CustomerItemSubCategoryConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CustomerItemSubCategoryEdge' }
      & { node?: Maybe<(
        { __typename?: 'CustomerItemSubCategory' }
        & Pick<CustomerItemSubCategory, 'id' | 'name'>
      )> }
    )>>> }
  )>, tags?: Maybe<(
    { __typename?: 'TagConnection' }
    & TagEdgesFragment
  )> }
);

export type TagEdgesFragment = (
  { __typename?: 'TagConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'TagEdge' }
    & TagNodeFragment
  )>>> }
);

export type TagNodeFragment = (
  { __typename?: 'TagEdge' }
  & { node?: Maybe<(
    { __typename?: 'Tag' }
    & TagFragment
  )> }
);

export type TagFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name'>
);

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer?: Maybe<(
    { __typename?: 'UpdateCustomerMutationPayload' }
    & Pick<UpdateCustomerMutationPayload, 'clientMutationId'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id'>
    )> }
  )> }
);

export type DestroyCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DestroyCustomerMutation = (
  { __typename?: 'Mutation' }
  & { destroyCustomer?: Maybe<(
    { __typename?: 'DestroyCustomerMutationPayload' }
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id'>
    )> }
  )> }
);

export type DepositSlipsQueryVariables = Exact<{
  where?: Maybe<DepositSlipFilter>;
}>;


export type DepositSlipsQuery = (
  { __typename?: 'Query' }
  & { depositSlips: (
    { __typename?: 'DepositSlipConnection' }
    & DepositSlipEdgesFragment
  ) }
);

export type DepositSlipEdgesFragment = (
  { __typename?: 'DepositSlipConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'DepositSlipEdge' }
    & DepositSlipNodeFragment
  )>>> }
);

export type DepositSlipNodeFragment = (
  { __typename?: 'DepositSlipEdge' }
  & { node?: Maybe<(
    { __typename?: 'DepositSlip' }
    & DepositSlipFragment
  )> }
);

export type DepositSlipFragment = (
  { __typename?: 'DepositSlip' }
  & Pick<DepositSlip, 'id' | 'createdAt' | 'nbItems' | 'number'>
  & { document?: Maybe<(
    { __typename?: 'CustomFile' }
    & Pick<CustomFile, 'imageUrl' | 'filename'>
  )>, vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type UpdateDesireMutationVariables = Exact<{
  input: UpdateDesireMutationInput;
}>;


export type UpdateDesireMutation = (
  { __typename?: 'Mutation' }
  & { updateDesire?: Maybe<(
    { __typename?: 'UpdateDesireMutationPayload' }
    & { desire?: Maybe<(
      { __typename?: 'Desire' }
      & Pick<Desire, 'id'>
    )> }
  )> }
);

export type CreateDesireMutationVariables = Exact<{
  input: CreateDesireMutationInput;
}>;


export type CreateDesireMutation = (
  { __typename?: 'Mutation' }
  & { createDesire?: Maybe<(
    { __typename?: 'CreateDesireMutationPayload' }
    & { desire?: Maybe<(
      { __typename?: 'Desire' }
      & Pick<Desire, 'id'>
    )> }
  )> }
);

export type DestroyDesireMutationVariables = Exact<{
  input: DestroyDesireMutationInput;
}>;


export type DestroyDesireMutation = (
  { __typename?: 'Mutation' }
  & { destroyDesire?: Maybe<(
    { __typename?: 'DestroyDesireMutationPayload' }
    & { desire?: Maybe<(
      { __typename?: 'Desire' }
      & Pick<Desire, 'id'>
    )> }
  )> }
);

export type DesireQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DesireQuery = (
  { __typename?: 'Query' }
  & { desire?: Maybe<(
    { __typename?: 'Desire' }
    & DesireFragment
  )> }
);

export type DesiresQueryVariables = Exact<{
  where?: Maybe<DesireFilter>;
  first?: Maybe<Scalars['Int']>;
}>;


export type DesiresQuery = (
  { __typename?: 'Query' }
  & { desires: (
    { __typename?: 'DesireConnection' }
    & DesireEdgesFragment
  ) }
);

export type DesireEdgesFragment = (
  { __typename?: 'DesireConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'DesireEdge' }
    & DesireNodeFragment
  )>>> }
);

export type DesireNodeFragment = (
  { __typename?: 'DesireEdge' }
  & { node?: Maybe<(
    { __typename?: 'Desire' }
    & DesireFragment
  )> }
);

export type DesireFragment = (
  { __typename?: 'Desire' }
  & Pick<Desire, 'id' | 'title' | 'createdAt'>
  & { buyer?: Maybe<(
    { __typename?: 'Buyer' }
    & BuyerFragment
  )>, customer?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerFragment
  )>, tags?: Maybe<(
    { __typename?: 'TagConnection' }
    & TagEdgesFragment
  )> }
);

export type BuyerFragment = (
  { __typename?: 'Buyer' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'email'>
  ) }
);

export type UploadImageInItemMutationVariables = Exact<{
  id: Scalars['ID'];
  images?: Maybe<Array<UploadImageInput>>;
}>;


export type UploadImageInItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItem?: Maybe<(
    { __typename?: 'UpdateItemMutationPayload' }
    & Pick<UpdateItemMutationPayload, 'clientMutationId'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'title'>
      & { images?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'imageUrl' | 'position'>
      )>> }
    ) }
  )> }
);

export type UploadInvoiceInItemMutationVariables = Exact<{
  id: Scalars['ID'];
  files?: Maybe<Array<UploadFileInput>>;
}>;


export type UploadInvoiceInItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItem?: Maybe<(
    { __typename?: 'UpdateItemMutationPayload' }
    & Pick<UpdateItemMutationPayload, 'clientMutationId'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'title'>
      & { invoices?: Maybe<Array<(
        { __typename?: 'CustomFile' }
        & Pick<CustomFile, 'imageUrl'>
      )>> }
    ) }
  )> }
);

export type UploadAuthenticityDocumentInItemMutationVariables = Exact<{
  id: Scalars['ID'];
  files?: Maybe<Array<UploadFileInput>>;
}>;


export type UploadAuthenticityDocumentInItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItem?: Maybe<(
    { __typename?: 'UpdateItemMutationPayload' }
    & Pick<UpdateItemMutationPayload, 'clientMutationId'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'title'>
      & { authenticityDocuments?: Maybe<Array<(
        { __typename?: 'CustomFile' }
        & Pick<CustomFile, 'imageUrl'>
      )>> }
    ) }
  )> }
);

export type UpdateItemStepMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: UpdateItemStepInput;
}>;


export type UpdateItemStepMutation = (
  { __typename?: 'Mutation' }
  & { updateItemStep?: Maybe<(
    { __typename?: 'UpdateItemStepMutationPayload' }
    & Pick<UpdateItemStepMutationPayload, 'clientMutationId'>
    & { itemStep?: Maybe<(
      { __typename?: 'ItemStep' }
      & Pick<ItemStep, 'id'>
    )> }
  )> }
);

export type ExpertPaymentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ExpertPaymentQuery = (
  { __typename?: 'Query' }
  & { expertPayment?: Maybe<(
    { __typename?: 'ExpertPayment' }
    & { expert?: Maybe<(
      { __typename?: 'Expert' }
      & { account: (
        { __typename?: 'Account' }
        & { bankInformation?: Maybe<(
          { __typename?: 'BankInformation' }
          & Pick<BankInformation, 'iban' | 'bic'>
        )> }
        & ExpertAccountFragment
      ) }
      & ExpertFragment
    )> }
    & ExpertPaymentFragment
  )> }
);

export type ExpertPaymentsQueryVariables = Exact<{
  where?: Maybe<ExpertPaymentFilter>;
  first?: Maybe<Scalars['Int']>;
}>;


export type ExpertPaymentsQuery = (
  { __typename?: 'Query' }
  & { expertPayments: (
    { __typename?: 'ExpertPaymentConnection' }
    & ExpertPaymentEdgesFragment
  ) }
);

export type ExpertAccountFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'firstName' | 'lastName' | 'state' | 'isProfessional'>
);

export type ExpertPaymentEdgesFragment = (
  { __typename?: 'ExpertPaymentConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ExpertPaymentEdge' }
    & ExpertPaymentNodeFragment
  )>>> }
);

export type ExpertPaymentNodeFragment = (
  { __typename?: 'ExpertPaymentEdge' }
  & { node?: Maybe<(
    { __typename?: 'ExpertPayment' }
    & ExpertPaymentFragment
  )> }
);

export type ExpertPaymentFragment = (
  { __typename?: 'ExpertPayment' }
  & Pick<ExpertPayment, 'id' | 'paid' | 'createdAt' | 'startAt' | 'endAt'>
  & { expert?: Maybe<(
    { __typename?: 'Expert' }
    & ExpertFragment
  )>, orderItems?: Maybe<(
    { __typename?: 'OrderItemConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'OrderItemEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'totalPrice'>
        & { order?: Maybe<(
          { __typename?: 'Order' }
          & Pick<Order, 'id'>
          & { sale?: Maybe<(
            { __typename?: 'Sale' }
            & Pick<Sale, 'id' | 'name'>
          )> }
        )> }
      )> }
    )>>> }
  )>, invoices?: Maybe<Array<(
    { __typename?: 'CustomFile' }
    & Pick<CustomFile, 'imageUrl' | 'filename'>
  )>>, expertPaymentLines?: Maybe<(
    { __typename?: 'ExpertPaymentLineConnection' }
    & ExpertPaymentLineEdgesFragment
  )> }
);

export type ExpertPaymentLineEdgesFragment = (
  { __typename?: 'ExpertPaymentLineConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ExpertPaymentLineEdge' }
    & ExpertPaymentLineNodeFragment
  )>>> }
);

export type ExpertPaymentLineNodeFragment = (
  { __typename?: 'ExpertPaymentLineEdge' }
  & { node?: Maybe<(
    { __typename?: 'ExpertPaymentLine' }
    & ExpertPaymentLineFragment
  )> }
);

export type ExpertPaymentLineFragment = (
  { __typename?: 'ExpertPaymentLine' }
  & Pick<ExpertPaymentLine, 'amount'>
);

export type UpdateExpertPaymentMutationVariables = Exact<{
  input: UpdateExpertPaymentMutationInput;
}>;


export type UpdateExpertPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateExpertPayment?: Maybe<(
    { __typename?: 'UpdateExpertPaymentMutationPayload' }
    & { expertPayment?: Maybe<(
      { __typename?: 'ExpertPayment' }
      & Pick<ExpertPayment, 'id'>
    )> }
  )> }
);

export type ArtistQueryVariables = Exact<{
  where?: Maybe<ArtistFilter>;
}>;


export type ArtistQuery = (
  { __typename?: 'Query' }
  & { artists: (
    { __typename?: 'ArtistConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ArtistEdge' }
      & { node?: Maybe<(
        { __typename?: 'Artist' }
        & Pick<Artist, 'id' | 'firstName' | 'lastName' | 'tag' | 'itemCategoryId'>
      )> }
    )>>> }
  ) }
);

export type UpdateItemArtistsMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: UpdateItemInput;
}>;


export type UpdateItemArtistsMutation = (
  { __typename?: 'Mutation' }
  & { updateItem?: Maybe<(
    { __typename?: 'UpdateItemMutationPayload' }
    & Pick<UpdateItemMutationPayload, 'clientMutationId'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'title'>
      & { artists?: Maybe<(
        { __typename?: 'ArtistConnection' }
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'ArtistEdge' }
          & { node?: Maybe<(
            { __typename?: 'Artist' }
            & Pick<Artist, 'id' | 'firstName' | 'lastName' | 'tag' | 'itemCategoryId'>
          )> }
        )>>> }
      )> }
    ) }
  )> }
);

export type ItemCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemCategoriesQuery = (
  { __typename?: 'Query' }
  & { itemCategories: (
    { __typename?: 'ItemCategoryConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ItemCategoryEdge' }
      & { node?: Maybe<(
        { __typename?: 'ItemCategory' }
        & Pick<ItemCategory, 'id' | 'name' | 'templateType'>
      )> }
    )>>> }
  ) }
);

export type ItemSubCategoriesQueryVariables = Exact<{
  where?: Maybe<ItemSubCategoryFilter>;
}>;


export type ItemSubCategoriesQuery = (
  { __typename?: 'Query' }
  & { itemSubCategories: (
    { __typename?: 'ItemSubCategoryConnection' }
    & ItemSubCategoryEdgesFragment
  ) }
);

export type ItemSubCategoryEdgesFragment = (
  { __typename?: 'ItemSubCategoryConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ItemSubCategoryEdge' }
    & ItemSubCategoryNodeFragment
  )>>> }
);

export type ItemSubCategoryNodeFragment = (
  { __typename?: 'ItemSubCategoryEdge' }
  & { node?: Maybe<(
    { __typename?: 'ItemSubCategory' }
    & Pick<ItemSubCategory, 'id' | 'name'>
  )> }
);

export type CreateBookMutationVariables = Exact<{
  input: CreateBookMutationInput;
}>;


export type CreateBookMutation = (
  { __typename?: 'Mutation' }
  & { createBook?: Maybe<(
    { __typename?: 'CreateBookMutationPayload' }
    & { book?: Maybe<(
      { __typename?: 'Book' }
      & Pick<Book, 'id'>
      & { item: (
        { __typename?: 'Item' }
        & Pick<Item, 'id' | 'title' | 'description'>
        & { itemCategory?: Maybe<(
          { __typename?: 'ItemCategory' }
          & Pick<ItemCategory, 'id' | 'name'>
        )>, itemSubCategory?: Maybe<(
          { __typename?: 'ItemSubCategory' }
          & Pick<ItemSubCategory, 'id' | 'name'>
        )> }
      ) }
    )> }
  )> }
);

export type UpdateVolumeMutationVariables = Exact<{
  attributes: UpdateVolumeInput;
}>;


export type UpdateVolumeMutation = (
  { __typename?: 'Mutation' }
  & { updateVolumes?: Maybe<(
    { __typename?: 'UpdateVolumeMutationPayload' }
    & Pick<UpdateVolumeMutationPayload, 'clientMutationId'>
    & { errors?: Maybe<(
      { __typename?: 'ValidationErrors' }
      & Pick<ValidationErrors, 'details' | 'fullMessages'>
    )> }
  )> }
);

export type GetItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetItemQuery = (
  { __typename?: 'Query' }
  & { item?: Maybe<(
    { __typename?: 'Item' }
    & ItemFragment
  )> }
);

export type ItemFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'createdAt' | 'description' | 'conditionsComment' | 'globalComment' | 'height' | 'width' | 'length' | 'weight' | 'otherComment' | 'id' | 'sku' | 'customSkuPart' | 'conditionAccepted' | 'dateFrom' | 'dateTo' | 'dateOption' | 'catchPhrase' | 'catchPhraseEnabled' | 'customDescription' | 'customDescriptionEnabled' | 'autoDescription' | 'state' | 'locations' | 'locationKnownButNotMentioned' | 'artistUnknown' | 'artistCollectif' | 'artistKnownButNotMentioned' | 'updatedAt'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'title'>
  )>, images?: Maybe<Array<(
    { __typename?: 'Image' }
    & Pick<Image, 'imageUrl' | 'position'>
  )>>, invoices?: Maybe<Array<(
    { __typename?: 'CustomFile' }
    & FileFragment
  )>>, authenticityDocuments?: Maybe<Array<(
    { __typename?: 'CustomFile' }
    & FileFragment
  )>>, expert?: Maybe<(
    { __typename?: 'Expert' }
    & ExpertFragment
  )>, vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & VendorFragment
  )>, writer?: Maybe<(
    { __typename?: 'Writer' }
    & WriterFragment
  )>, vacation?: Maybe<(
    { __typename?: 'Vacation' }
    & VacationFragment
  )>, template: (
    { __typename: 'Book' }
    & BookFragment
  ) | { __typename: 'Jewelry' } }
  & ItemStepsFragment
  & ItemCategoryFragment
  & ItemTitlesFragment
  & ItemPriceFragment
  & ItemArtistsFragment
  & TagsFragment
  & AutoTagsFragment
);

export type ExpertFragment = (
  { __typename?: 'Expert' }
  & Pick<Expert, 'id'>
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'firstName' | 'lastName' | 'state'>
  ) }
);

export type VendorFragment = (
  { __typename?: 'Vendor' }
  & Pick<Vendor, 'externalRainworxId'>
  & { account: (
    { __typename?: 'Account' }
    & VendorAccountFragment
  ) }
);

export type VendorAccountFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'firstName' | 'lastName' | 'state' | 'isProfessional'>
);

export type FileFragment = (
  { __typename?: 'CustomFile' }
  & Pick<CustomFile, 'imageUrl' | 'filename' | 'extension'>
);

export type AutoTagsFragment = (
  { __typename?: 'Item' }
  & { autoTags?: Maybe<(
    { __typename?: 'TagConnection' }
    & TagEdgesFragment
  )> }
);

export type TagsFragment = (
  { __typename?: 'Item' }
  & { tags?: Maybe<(
    { __typename?: 'TagConnection' }
    & TagEdgesFragment
  )> }
);

export type ItemStepsFragment = (
  { __typename?: 'Item' }
  & { itemSteps?: Maybe<(
    { __typename?: 'ItemStepConnection' }
    & ItemStepEdgesFragment
  )> }
);

export type ItemStepEdgesFragment = (
  { __typename?: 'ItemStepConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ItemStepEdge' }
    & ItemStepNodeFragment
  )>>> }
);

export type ItemStepNodeFragment = (
  { __typename?: 'ItemStepEdge' }
  & { node?: Maybe<(
    { __typename?: 'ItemStep' }
    & ItemStepFragment
  )> }
);

export type ItemStepFragment = (
  { __typename?: 'ItemStep' }
  & Pick<ItemStep, 'id' | 'number' | 'comment' | 'state' | 'name'>
);

export type ItemArtistsFragment = (
  { __typename?: 'Item' }
  & { artists?: Maybe<(
    { __typename?: 'ArtistConnection' }
    & ItemArtistEdgesFragment
  )> }
);

export type ItemArtistEdgesFragment = (
  { __typename?: 'ArtistConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ArtistEdge' }
    & ItemArtistNodeFragment
  )>>> }
);

export type ItemArtistNodeFragment = (
  { __typename?: 'ArtistEdge' }
  & { node?: Maybe<(
    { __typename?: 'Artist' }
    & ArtistFragment
  )> }
);

export type ArtistFragment = (
  { __typename?: 'Artist' }
  & Pick<Artist, 'id' | 'firstName' | 'lastName' | 'isGroup' | 'tag' | 'itemCategoryId'>
);

export type ItemCategoryFragment = (
  { __typename?: 'Item' }
  & { itemCategory?: Maybe<(
    { __typename?: 'ItemCategory' }
    & Pick<ItemCategory, 'id' | 'name' | 'templateType' | 'externalRainworxId'>
  )>, itemSubCategory?: Maybe<(
    { __typename?: 'ItemSubCategory' }
    & Pick<ItemSubCategory, 'id' | 'name' | 'externalRainworxId'>
  )> }
);

export type VacationFragment = (
  { __typename?: 'Vacation' }
  & Pick<Vacation, 'id' | 'name' | 'description' | 'state' | 'startAt' | 'endAt'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'imageUrl'>
  )>, expert?: Maybe<(
    { __typename?: 'Expert' }
    & Pick<Expert, 'id'>
  )> }
);

export type ItemPriceFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'estimatedPrice' | 'reservedPrice' | 'startingPrice'>
);

export type ItemTitlesFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'title' | 'subtitle' | 'state'>
);

export type GetBookQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBookQuery = (
  { __typename?: 'Query' }
  & { book?: Maybe<(
    { __typename?: 'Book' }
    & BookFragment
  )> }
);

export type BookFragment = (
  { __typename?: 'Book' }
  & Pick<Book, 'createdAt' | 'id' | 'updatedAt' | 'publishers' | 'bibliographyComment' | 'illustrationComment' | 'collationComment' | 'assemblySpecification' | 'assemblyType' | 'bindingColor' | 'bindingFormat' | 'bindingStyle' | 'bindingMaterial' | 'bindingFormatDetails' | 'bindingType' | 'bindingTypeOptions' | 'bookSpineMaterial' | 'bookSpineOrnament1' | 'bookSpineOrnament1Option' | 'bookSpineOrnament2' | 'bookSpineOrnament2Option' | 'bookSpineOrnamentOption' | 'bookSpineType' | 'bindingAge' | 'bindingMaterialOptions' | 'bindingAdditionalOptions' | 'bookBinder' | 'size' | 'coverType' | 'coverQuality' | 'coverColor' | 'coverMaterial' | 'coverOrnament1' | 'coverOrnament1Option' | 'coverOrnament2' | 'coverOrnament2Option' | 'coverOrnamentOption' | 'edgeColor' | 'edgeStyle' | 'edgeType' | 'isOblong' | 'dumbSpine' | 'isBindingSigned' | 'paperbackType' | 'isApproximatelyPaginating' | 'isPaginating' | 'paginationInfo' | 'withFolder' | 'casingType'>
  & { volumes?: Maybe<(
    { __typename?: 'VolumeConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'VolumeEdge' }
      & { node?: Maybe<(
        { __typename?: 'Volume' }
        & Pick<Volume, 'id'>
        & { parts?: Maybe<(
          { __typename?: 'PartConnection' }
          & { edges?: Maybe<Array<Maybe<(
            { __typename?: 'PartEdge' }
            & { node?: Maybe<(
              { __typename?: 'Part' }
              & Pick<Part, 'id'>
            )> }
          )>>> }
        )> }
      )> }
    )>>> }
  )>, item: (
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'state'>
  ) }
  & PublishingBookFragment
);

export type PublishingBookFragment = (
  { __typename?: 'Book' }
  & Pick<Book, 'id' | 'publishers' | 'publisherKnownButNotMentioned' | 'editingAttribute' | 'editingAttributeDetails'>
  & { item: (
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'locations' | 'locationKnownButNotMentioned' | 'dateFrom' | 'dateTo' | 'dateOption'>
  ) }
);

export type UpdateItemMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: UpdateItemInput;
}>;


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItem?: Maybe<(
    { __typename?: 'UpdateItemMutationPayload' }
    & Pick<UpdateItemMutationPayload, 'clientMutationId'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'id'>
    ) }
  )> }
);

export type DuplicateItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicateItemMutation = (
  { __typename?: 'Mutation' }
  & { duplicateItem?: Maybe<(
    { __typename?: 'DuplicateItemMutationPayload' }
    & Pick<DuplicateItemMutationPayload, 'clientMutationId'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'id'>
    ) }
  )> }
);

export type DestroyItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DestroyItemMutation = (
  { __typename?: 'Mutation' }
  & { destroyItem?: Maybe<(
    { __typename?: 'DestroyItemMutationPayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'id'>
    )> }
  )> }
);

export type UpdateBookMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: UpdateBookInput;
}>;


export type UpdateBookMutation = (
  { __typename?: 'Mutation' }
  & { updateBook?: Maybe<(
    { __typename?: 'UpdateBookMutationPayload' }
    & { book?: Maybe<(
      { __typename?: 'Book' }
      & Pick<Book, 'id'>
    )> }
  )> }
);

export type ItemsQueryQueryVariables = Exact<{
  where?: Maybe<ItemFilter>;
  orderByAttribute?: Maybe<Array<ItemOrder>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type ItemsQueryQuery = (
  { __typename?: 'Query' }
  & { items: (
    { __typename?: 'ItemConnection' }
    & Pick<ItemConnection, 'totalCount'>
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ItemEdge' }
      & Pick<ItemEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Item' }
        & ItemTableFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type ItemsWithImagesQueryQueryVariables = Exact<{
  where?: Maybe<ItemFilter>;
  orderByAttribute?: Maybe<Array<ItemOrder>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type ItemsWithImagesQueryQuery = (
  { __typename?: 'Query' }
  & { items: (
    { __typename?: 'ItemConnection' }
    & Pick<ItemConnection, 'totalCount'>
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ItemEdge' }
      & Pick<ItemEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Item' }
        & ItemTableFragment
        & ItemFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type ExpertsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExpertsQuery = (
  { __typename?: 'Query' }
  & { experts: (
    { __typename?: 'ExpertConnection' }
    & ExpertEdgesFragment
  ) }
);

export type ExpertEdgesFragment = (
  { __typename?: 'ExpertConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ExpertEdge' }
    & ExpertNodeFragment
  )>>> }
);

export type ExpertNodeFragment = (
  { __typename?: 'ExpertEdge' }
  & { node?: Maybe<(
    { __typename?: 'Expert' }
    & ExpertFragment
  )> }
);

export type ItemEdgesFragment = (
  { __typename?: 'ItemConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ItemEdge' }
    & ItemNodeFragment
  )>>> }
);

export type ItemNodeFragment = (
  { __typename?: 'ItemEdge' }
  & { node?: Maybe<(
    { __typename?: 'Item' }
    & ItemFragment
  )> }
);

export type ItemTableFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'id' | 'artistUnknown' | 'artistCollectif' | 'title' | 'state' | 'sku' | 'createdAt'>
  & { firstImage?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'imageUrl' | 'position'>
  )>, expert?: Maybe<(
    { __typename?: 'Expert' }
    & Pick<Expert, 'id'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'firstName' | 'lastName'>
    ) }
  )>, vacation?: Maybe<(
    { __typename?: 'Vacation' }
    & Pick<Vacation, 'id'>
  )>, sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id'>
  )>, vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'firstName' | 'lastName'>
    ) }
  )>, writer?: Maybe<(
    { __typename?: 'Writer' }
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'firstName' | 'lastName'>
    ) }
  )> }
  & ItemCategoryFragment
  & ItemArtistsFragment
);

export type NewsLettersQueryVariables = Exact<{
  where?: Maybe<NewsLetterFilter>;
  direction?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type NewsLettersQuery = (
  { __typename?: 'Query' }
  & { newsLetters: (
    { __typename?: 'NewsLetterConnection' }
    & NewsLetterEdgesFragment
  ) }
);

export type NewsLetterEdgesFragment = (
  { __typename?: 'NewsLetterConnection' }
  & Pick<NewsLetterConnection, 'totalCount'>
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'NewsLetterEdge' }
    & NewsLetterNodeFragment
  )>>>, pageInfo: (
    { __typename?: 'PageInfo' }
    & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
  ) }
);

export type NewsLetterNodeFragment = (
  { __typename?: 'NewsLetterEdge' }
  & { node?: Maybe<(
    { __typename?: 'NewsLetter' }
    & NewsLetterFragment
  )> }
);

export type NewsLetterFragment = (
  { __typename?: 'NewsLetter' }
  & Pick<NewsLetter, 'id' | 'htmlContent' | 'test' | 'emailFrom' | 'emailTo' | 'subject' | 'nbItems'>
  & { buyer?: Maybe<(
    { __typename?: 'Buyer' }
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'firstName' | 'lastName' | 'email'>
    ), autoTags?: Maybe<(
      { __typename?: 'TagConnection' }
      & TagEdgesFragment
    )>, expertTags?: Maybe<(
      { __typename?: 'TagConnection' }
      & TagEdgesFragment
    )>, importTags?: Maybe<(
      { __typename?: 'TagConnection' }
      & TagEdgesFragment
    )>, bookmarkTags?: Maybe<(
      { __typename?: 'TagConnection' }
      & TagEdgesFragment
    )> }
  )>, customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'firstName' | 'lastName' | 'email'>
    & { tags?: Maybe<(
      { __typename?: 'TagConnection' }
      & TagEdgesFragment
    )> }
  )>, sale: (
    { __typename?: 'Sale' }
    & Pick<Sale, 'id' | 'name'>
  ) }
);

export type CreateNewsLetterMutationVariables = Exact<{
  input: CreateNewsLetterMutationInput;
}>;


export type CreateNewsLetterMutation = (
  { __typename?: 'Mutation' }
  & { createNewsLetter?: Maybe<(
    { __typename?: 'CreateNewsLetterMutationPayload' }
    & { sale?: Maybe<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'id' | 'name'>
    )> }
  )> }
);

export type OrdersQueryVariables = Exact<{
  where?: Maybe<OrderFilter>;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders: (
    { __typename?: 'OrderConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'OrderEdge' }
      & { node?: Maybe<(
        { __typename?: 'Order' }
        & OrderTableFragment
      )> }
    )>>> }
  ) }
);

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & OrderFragment
  )> }
);

export type OrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'totalPrice' | 'totalPriceTaxIncluded' | 'shippingPrice' | 'shippingPriceTaxIncluded' | 'shippingTax' | 'totalTax' | 'totalItemsPrice' | 'state'>
  & { shippingAddress?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'zip' | 'country' | 'email' | 'firstName' | 'lastName' | 'companyName' | 'phone1' | 'phone2'>
  )>, orderItems?: Maybe<(
    { __typename?: 'OrderItemConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'OrderItemEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'state'>
        & { vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id'>
        )>, item: (
          { __typename?: 'Item' }
          & Pick<Item, 'id' | 'title' | 'sku' | 'finalPrice'>
          & { images?: Maybe<Array<(
            { __typename?: 'Image' }
            & Pick<Image, 'imageUrl' | 'position'>
          )>> }
        ) }
      )> }
    )>>> }
  )> }
);

export type OrderTableFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'undeplusRef' | 'totalPrice' | 'totalPriceTaxIncluded' | 'state' | 'stripePaymentLink' | 'trackingUrls' | 'labelUrls' | 'trackingNumbers' | 'createdAt'>
  & { buyer?: Maybe<(
    { __typename?: 'Buyer' }
    & Pick<Buyer, 'id'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'firstName' | 'lastName'>
    ) }
  )>, orderItems?: Maybe<(
    { __typename?: 'OrderItemConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'OrderItemEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'state'>
        & { vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id'>
        )>, item: (
          { __typename?: 'Item' }
          & Pick<Item, 'title' | 'sku'>
        ) }
      )> }
    )>>> }
  )>, labels?: Maybe<(
    { __typename?: 'LabelConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'LabelEdge' }
      & { node?: Maybe<(
        { __typename?: 'Label' }
        & Pick<Label, 'trackingNumber' | 'undeplusRef' | 'externalLabelUrl' | 'trackingUrl'>
        & { labelDocument?: Maybe<(
          { __typename?: 'CustomFile' }
          & Pick<CustomFile, 'imageUrl'>
        )>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id'>
        )> }
      )> }
    )>>> }
  )> }
);

export type UpdateOrderItemMutationVariables = Exact<{
  input: UpdateOrderItemMutationInput;
}>;


export type UpdateOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderItem?: Maybe<(
    { __typename?: 'UpdateOrderItemMutationPayload' }
    & { orderItem?: Maybe<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id'>
    )> }
  )> }
);

export type PaidSlipsQueryVariables = Exact<{
  where?: Maybe<PaidSlipFilter>;
}>;


export type PaidSlipsQuery = (
  { __typename?: 'Query' }
  & { paidSlips: (
    { __typename?: 'PaidSlipConnection' }
    & PaidSlipEdgesFragment
  ) }
);

export type PaidSlipEdgesFragment = (
  { __typename?: 'PaidSlipConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'PaidSlipEdge' }
    & PaidSlipNodeFragment
  )>>> }
);

export type PaidSlipNodeFragment = (
  { __typename?: 'PaidSlipEdge' }
  & { node?: Maybe<(
    { __typename?: 'PaidSlip' }
    & PaidSlipFragment
  )> }
);

export type PaidSlipFragment = (
  { __typename?: 'PaidSlip' }
  & Pick<PaidSlip, 'id' | 'createdAt' | 'nbItems' | 'number'>
  & { document?: Maybe<(
    { __typename?: 'CustomFile' }
    & Pick<CustomFile, 'imageUrl' | 'filename'>
  )>, buyer?: Maybe<(
    { __typename?: 'Buyer' }
    & Pick<Buyer, 'id'>
  )>, sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id'>
  )> }
);

export type UpdateUserPersonalInformationsMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
}>;


export type UpdateUserPersonalInformationsMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )> }
);

export type CreatePickupRequestMutationVariables = Exact<{
  input: CreatePickupRequestMutationInput;
}>;


export type CreatePickupRequestMutation = (
  { __typename?: 'Mutation' }
  & { createPickupRequest?: Maybe<(
    { __typename?: 'CreatePickupRequestMutationPayload' }
    & { pickupRequest?: Maybe<(
      { __typename?: 'PickupRequest' }
      & Pick<PickupRequest, 'id'>
    )> }
  )> }
);

export type PickupRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PickupRequestQuery = (
  { __typename?: 'Query' }
  & { pickupRequest?: Maybe<(
    { __typename?: 'PickupRequest' }
    & PickupRequestFragment
  )> }
);

export type PickupRequestsQueryVariables = Exact<{
  where?: Maybe<PickupRequestFilter>;
  first?: Maybe<Scalars['Int']>;
}>;


export type PickupRequestsQuery = (
  { __typename?: 'Query' }
  & { pickupRequests: (
    { __typename?: 'PickupRequestConnection' }
    & PickupRequestEdgesFragment
  ) }
);

export type PickupRequestEdgesFragment = (
  { __typename?: 'PickupRequestConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'PickupRequestEdge' }
    & PickupRequestNodeFragment
  )>>> }
);

export type PickupRequestNodeFragment = (
  { __typename?: 'PickupRequestEdge' }
  & { node?: Maybe<(
    { __typename?: 'PickupRequest' }
    & PickupRequestFragment
  )> }
);

export type PickupRequestFragment = (
  { __typename?: 'PickupRequest' }
  & Pick<PickupRequest, 'id' | 'pickupLocationCloseTime' | 'instructions' | 'pickupLocation' | 'createdAt' | 'pickupTimestamp' | 'replans'>
  & { vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )> }
);

export type GetAddressesProfileQueryVariables = Exact<{
  where?: Maybe<AddressFilter>;
}>;


export type GetAddressesProfileQuery = (
  { __typename?: 'Query' }
  & { addresses: (
    { __typename?: 'AddressConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'AddressEdge' }
      & { node?: Maybe<(
        { __typename?: 'Address' }
        & AddressProfileFragment
      )> }
    )>>> }
  ) }
);

export type AddressProfileFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'createdAt' | 'updatedAt' | 'number' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'zip' | 'city' | 'country' | 'type'>
);

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: UpdateAddressInput;
}>;


export type UpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateAddress?: Maybe<(
    { __typename?: 'UpdateAddressMutationPayload' }
    & Pick<UpdateAddressMutationPayload, 'clientMutationId'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id'>
    )> }
  )> }
);

export type GetBankInformationProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBankInformationProfileQuery = (
  { __typename?: 'Query' }
  & { bankInformation?: Maybe<(
    { __typename?: 'BankInformation' }
    & BankInformationProfileFragment
  )> }
);

export type BankInformationProfileFragment = (
  { __typename?: 'BankInformation' }
  & Pick<BankInformation, 'id' | 'createdAt' | 'updatedAt' | 'iban' | 'bic' | 'fullName' | 'name'>
);

export type UpdateBankInformationMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: UpdateBankInformationInput;
}>;


export type UpdateBankInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateBankInformation?: Maybe<(
    { __typename?: 'UpdateBankInformationMutationPayload' }
    & Pick<UpdateBankInformationMutationPayload, 'clientMutationId'>
    & { bankInformation?: Maybe<(
      { __typename?: 'BankInformation' }
      & Pick<BankInformation, 'id'>
    )> }
  )> }
);

export type GetAccountProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAccountProfileQuery = (
  { __typename?: 'Query' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & AccountProfileFragment
  )> }
);

export type AccountProfileFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'createdAt' | 'updatedAt' | 'firstName' | 'lastName' | 'civility' | 'birthDate' | 'corporateForm' | 'phone' | 'companyName' | 'email' | 'isProfessional'>
  & { bankInformation?: Maybe<(
    { __typename?: 'BankInformation' }
    & BankInformationProfileFragment
  )>, identityDocuments?: Maybe<Array<(
    { __typename?: 'CustomFile' }
    & FileFragment
  )>>, ribDocuments?: Maybe<Array<(
    { __typename?: 'CustomFile' }
    & FileFragment
  )>>, shippingAddress?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'city' | 'companyName' | 'country' | 'email' | 'firstName' | 'lastName' | 'phone1' | 'phone2' | 'zip'>
  )> }
);

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: UpdateAccountInput;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { customUpdateAccount?: Maybe<(
    { __typename?: 'CustomUpdateAccountMutationPayload' }
    & Pick<CustomUpdateAccountMutationPayload, 'clientMutationId'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )> }
  )> }
);

export type UploadIdentityDocumentInAccountMutationVariables = Exact<{
  id: Scalars['ID'];
  files?: Maybe<Array<UploadFileInput>>;
}>;


export type UploadIdentityDocumentInAccountMutation = (
  { __typename?: 'Mutation' }
  & { customUpdateAccount?: Maybe<(
    { __typename?: 'CustomUpdateAccountMutationPayload' }
    & Pick<CustomUpdateAccountMutationPayload, 'clientMutationId'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
      & { identityDocuments?: Maybe<Array<(
        { __typename?: 'CustomFile' }
        & Pick<CustomFile, 'imageUrl'>
      )>> }
    )> }
  )> }
);

export type UploadRibDocumentInAccountMutationVariables = Exact<{
  id: Scalars['ID'];
  files?: Maybe<Array<UploadFileInput>>;
}>;


export type UploadRibDocumentInAccountMutation = (
  { __typename?: 'Mutation' }
  & { customUpdateAccount?: Maybe<(
    { __typename?: 'CustomUpdateAccountMutationPayload' }
    & Pick<CustomUpdateAccountMutationPayload, 'clientMutationId'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
      & { identityDocuments?: Maybe<Array<(
        { __typename?: 'CustomFile' }
        & Pick<CustomFile, 'imageUrl'>
      )>> }
    )> }
  )> }
);

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & { vendor?: Maybe<(
      { __typename?: 'Vendor' }
      & { account: (
        { __typename?: 'Account' }
        & { bankInformation?: Maybe<(
          { __typename?: 'BankInformation' }
          & Pick<BankInformation, 'iban' | 'bic'>
        )> }
        & VendorAccountFragment
      ) }
      & VendorFragment
    )> }
    & ProjectFragment
  )> }
);

export type ProjectsQueryVariables = Exact<{
  where?: Maybe<ProjectFilter>;
  first?: Maybe<Scalars['Int']>;
}>;


export type ProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: (
    { __typename?: 'ProjectConnection' }
    & ProjectEdgesFragment
  ) }
);

export type ProjectEdgesFragment = (
  { __typename?: 'ProjectConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectEdge' }
    & ProjectNodeFragment
  )>>> }
);

export type ProjectNodeFragment = (
  { __typename?: 'ProjectEdge' }
  & { node?: Maybe<(
    { __typename?: 'Project' }
    & ProjectFragment
  )> }
);

export type ProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'nbItems' | 'createdAt' | 'title' | 'number' | 'itemNumberStart' | 'exclusif' | 'isClassicSale' | 'sharingProfit' | 'percentageProfitForOwner' | 'percentageProfitForVendor' | 'auctioneerNumber'>
  & { vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
    & VendorFragment
  )>, owner?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
    & VendorFragment
  )>, projectFees?: Maybe<(
    { __typename?: 'ProjectFeeConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectFeeEdge' }
      & { node?: Maybe<(
        { __typename?: 'ProjectFee' }
        & Pick<ProjectFee, 'name' | 'value'>
      )> }
    )>>> }
  )> }
);

export type VendorPaymentLineEdgesFragment = (
  { __typename?: 'VendorPaymentLineConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'VendorPaymentLineEdge' }
    & VendorPaymentLineNodeFragment
  )>>> }
);

export type VendorPaymentLineNodeFragment = (
  { __typename?: 'VendorPaymentLineEdge' }
  & { node?: Maybe<(
    { __typename?: 'VendorPaymentLine' }
    & VendorPaymentLineFragment
  )> }
);

export type VendorPaymentLineFragment = (
  { __typename?: 'VendorPaymentLine' }
  & Pick<VendorPaymentLine, 'amount' | 'feePercentage' | 'feeAmount'>
);

export type OrderItemEdgesFragment = (
  { __typename?: 'OrderItemConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'OrderItemEdge' }
    & OrderItemNodeFragment
  )>>> }
);

export type OrderItemNodeFragment = (
  { __typename?: 'OrderItemEdge' }
  & { node?: Maybe<(
    { __typename?: 'OrderItem' }
    & OrderItemFragment
  )> }
);

export type OrderItemFragment = (
  { __typename?: 'OrderItem' }
  & Pick<OrderItem, 'id' | 'totalPrice' | 'undeplusRef'>
  & { item: (
    { __typename?: 'Item' }
    & Pick<Item, 'id'>
  ) }
);

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectMutationInput;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject?: Maybe<(
    { __typename?: 'UpdateProjectMutationPayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
    )> }
  )> }
);

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectMutationInput;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject?: Maybe<(
    { __typename?: 'CreateProjectMutationPayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
    )> }
  )> }
);

export type VendorsQueryVariables = Exact<{
  where?: Maybe<VendorFilter>;
  first?: Maybe<Scalars['Int']>;
}>;


export type VendorsQuery = (
  { __typename?: 'Query' }
  & { vendors: (
    { __typename?: 'VendorConnection' }
    & VendorEdgesFragment
  ) }
);

export type VendorEdgesFragment = (
  { __typename?: 'VendorConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'VendorEdge' }
    & VendorNodeFragment
  )>>> }
);

export type VendorNodeFragment = (
  { __typename?: 'VendorEdge' }
  & { node?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
    & VendorFragment
  )> }
);

export type PurchaseSlipsQueryVariables = Exact<{
  where?: Maybe<PurchaseSlipFilter>;
}>;


export type PurchaseSlipsQuery = (
  { __typename?: 'Query' }
  & { purchaseSlips: (
    { __typename?: 'PurchaseSlipConnection' }
    & PurchaseSlipEdgesFragment
  ) }
);

export type PurchaseSlipEdgesFragment = (
  { __typename?: 'PurchaseSlipConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'PurchaseSlipEdge' }
    & PurchaseSlipNodeFragment
  )>>> }
);

export type PurchaseSlipNodeFragment = (
  { __typename?: 'PurchaseSlipEdge' }
  & { node?: Maybe<(
    { __typename?: 'PurchaseSlip' }
    & PurchaseSlipFragment
  )> }
);

export type PurchaseSlipFragment = (
  { __typename?: 'PurchaseSlip' }
  & Pick<PurchaseSlip, 'id' | 'createdAt' | 'nbItems' | 'number'>
  & { document?: Maybe<(
    { __typename?: 'CustomFile' }
    & Pick<CustomFile, 'imageUrl' | 'filename'>
  )>, buyer?: Maybe<(
    { __typename?: 'Buyer' }
    & Pick<Buyer, 'id'>
  )>, sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id'>
  )> }
);

export type QuickTextQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QuickTextQuery = (
  { __typename?: 'Query' }
  & { quickText?: Maybe<(
    { __typename?: 'QuickText' }
    & QuickTextFragment
  )> }
);

export type QuickTextsQueryVariables = Exact<{
  where?: Maybe<QuickTextFilter>;
  first?: Maybe<Scalars['Int']>;
}>;


export type QuickTextsQuery = (
  { __typename?: 'Query' }
  & { quickTexts: (
    { __typename?: 'QuickTextConnection' }
    & QuickTextEdgesFragment
  ) }
);

export type QuickTextEdgesFragment = (
  { __typename?: 'QuickTextConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'QuickTextEdge' }
    & QuickTextNodeFragment
  )>>> }
);

export type QuickTextNodeFragment = (
  { __typename?: 'QuickTextEdge' }
  & { node?: Maybe<(
    { __typename?: 'QuickText' }
    & QuickTextFragment
  )> }
);

export type QuickTextFragment = (
  { __typename?: 'QuickText' }
  & Pick<QuickText, 'id' | 'title' | 'text' | 'number' | 'createdAt'>
);

export type CreateQuickTextMutationVariables = Exact<{
  input: CreateQuickTextMutationInput;
}>;


export type CreateQuickTextMutation = (
  { __typename?: 'Mutation' }
  & { createQuickText?: Maybe<(
    { __typename?: 'CreateQuickTextMutationPayload' }
    & { quickText?: Maybe<(
      { __typename?: 'QuickText' }
      & Pick<QuickText, 'id'>
    )> }
  )> }
);

export type UpdateQuickTextMutationVariables = Exact<{
  input: UpdateQuickTextMutationInput;
}>;


export type UpdateQuickTextMutation = (
  { __typename?: 'Mutation' }
  & { updateQuickText?: Maybe<(
    { __typename?: 'UpdateQuickTextMutationPayload' }
    & { quickText?: Maybe<(
      { __typename?: 'QuickText' }
      & Pick<QuickText, 'id'>
    )> }
  )> }
);

export type DestroyQuickTextMutationVariables = Exact<{
  input: DestroyQuickTextMutationInput;
}>;


export type DestroyQuickTextMutation = (
  { __typename?: 'Mutation' }
  & { destroyQuickText?: Maybe<(
    { __typename?: 'DestroyQuickTextMutationPayload' }
    & { quickText?: Maybe<(
      { __typename?: 'QuickText' }
      & Pick<QuickText, 'id'>
    )> }
  )> }
);

export type CreateLotUrlsRainworxImportMutationVariables = Exact<{
  attributes: CreateLotUrlRainworxImportInput;
}>;


export type CreateLotUrlsRainworxImportMutation = (
  { __typename?: 'Mutation' }
  & { createLotUrlsRainworxImport?: Maybe<(
    { __typename?: 'CreateLotUrlsRainworxImportMutationPayload' }
    & Pick<CreateLotUrlsRainworxImportMutationPayload, 'clientMutationId'>
    & { import?: Maybe<(
      { __typename?: 'Import' }
      & Pick<Import, 'id'>
    )> }
  )> }
);

export type SaleMandatsQueryVariables = Exact<{
  where?: Maybe<SaleMandatFilter>;
}>;


export type SaleMandatsQuery = (
  { __typename?: 'Query' }
  & { saleMandats: (
    { __typename?: 'SaleMandatConnection' }
    & SaleMandatEdgesFragment
  ) }
);

export type SaleMandatEdgesFragment = (
  { __typename?: 'SaleMandatConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'SaleMandatEdge' }
    & SaleMandatNodeFragment
  )>>> }
);

export type SaleMandatNodeFragment = (
  { __typename?: 'SaleMandatEdge' }
  & { node?: Maybe<(
    { __typename?: 'SaleMandat' }
    & SaleMandatFragment
  )> }
);

export type SaleMandatFragment = (
  { __typename?: 'SaleMandat' }
  & Pick<SaleMandat, 'id' | 'createdAt' | 'nbItems' | 'number'>
  & { document?: Maybe<(
    { __typename?: 'CustomFile' }
    & Pick<CustomFile, 'imageUrl' | 'filename'>
  )>, vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )>, sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id'>
  )> }
);

export type CreateSaleMandatsMutationVariables = Exact<{
  saleId: Scalars['ID'];
}>;


export type CreateSaleMandatsMutation = (
  { __typename?: 'Mutation' }
  & { createSaleMandats?: Maybe<(
    { __typename?: 'CreateSaleMandatMutationPayload' }
    & Pick<CreateSaleMandatMutationPayload, 'clientMutationId'>
    & { sale?: Maybe<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'id'>
    )> }
  )> }
);

export type SaleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SaleQuery = (
  { __typename?: 'Query' }
  & { sale?: Maybe<(
    { __typename?: 'Sale' }
    & SaleFragment
  )> }
);

export type SalesQueryVariables = Exact<{
  where?: Maybe<SaleFilter>;
}>;


export type SalesQuery = (
  { __typename?: 'Query' }
  & { sales: (
    { __typename?: 'SaleConnection' }
    & SaleEdgesFragment
  ) }
);

export type SaleEdgesFragment = (
  { __typename?: 'SaleConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'SaleEdge' }
    & SaleNodeFragment
  )>>> }
);

export type SaleNodeFragment = (
  { __typename?: 'SaleEdge' }
  & { node?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'nbItems'>
    & SaleFragment
  )> }
);

export type SaleFragment = (
  { __typename?: 'Sale' }
  & Pick<Sale, 'id' | 'name' | 'description' | 'state' | 'startAt' | 'endAt' | 'externalEventRainworxId'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'imageUrl'>
  )>, expert?: Maybe<(
    { __typename?: 'Expert' }
    & Pick<Expert, 'id'>
  )>, saleMandats?: Maybe<(
    { __typename?: 'SaleMandatConnection' }
    & SaleMandatEdgesFragment
  )> }
);

export type CreateSaleMutationVariables = Exact<{
  input: CreateSaleMutationInput;
}>;


export type CreateSaleMutation = (
  { __typename?: 'Mutation' }
  & { createSale?: Maybe<(
    { __typename?: 'CreateSaleMutationPayload' }
    & { sale?: Maybe<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateSaleMutationVariables = Exact<{
  input: UpdateSaleMutationInput;
}>;


export type UpdateSaleMutation = (
  { __typename?: 'Mutation' }
  & { updateSale?: Maybe<(
    { __typename?: 'UpdateSaleMutationPayload' }
    & { sale?: Maybe<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'id' | 'name'>
    )> }
  )> }
);

export type DestroySaleMutationVariables = Exact<{
  input: DestroySaleMutationInput;
}>;


export type DestroySaleMutation = (
  { __typename?: 'Mutation' }
  & { destroySale?: Maybe<(
    { __typename?: 'DestroySaleMutationPayload' }
    & { sale?: Maybe<(
      { __typename?: 'Sale' }
      & Pick<Sale, 'id' | 'name'>
    )> }
  )> }
);

export type TagsQueryVariables = Exact<{
  where?: Maybe<TagFilter>;
  direction?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags: (
    { __typename?: 'TagConnection' }
    & Pick<TagConnection, 'totalCount'>
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'TagEdge' }
      & Pick<TagEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Tag' }
        & TagTableFragment
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type TagQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TagQuery = (
  { __typename?: 'Query' }
  & { tag?: Maybe<(
    { __typename?: 'Tag' }
    & TagTableFragment
  )> }
);

export type TagTableFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'createdAt' | 'auto'>
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'lastName' | 'firstName'>
  )> }
);

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: UpdateTagInput;
}>;


export type UpdateTagMutation = (
  { __typename?: 'Mutation' }
  & { updateTag?: Maybe<(
    { __typename?: 'UpdateTagMutationPayload' }
    & Pick<UpdateTagMutationPayload, 'clientMutationId'>
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )> }
);

export type MergeTagMutationVariables = Exact<{
  id: Scalars['ID'];
  attributes: MergeTagInput;
}>;


export type MergeTagMutation = (
  { __typename?: 'Mutation' }
  & { mergeTag?: Maybe<(
    { __typename?: 'MergeTagMutationPayload' }
    & Pick<MergeTagMutationPayload, 'clientMutationId'>
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )> }
);

export type DestroyTagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DestroyTagMutation = (
  { __typename?: 'Mutation' }
  & { destroyTag?: Maybe<(
    { __typename?: 'DestroyTagMutationPayload' }
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )> }
);

export type VacationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VacationQuery = (
  { __typename?: 'Query' }
  & { vacation?: Maybe<(
    { __typename?: 'Vacation' }
    & VacationFragment
  )> }
);

export type VacationsQueryVariables = Exact<{
  where?: Maybe<VacationFilter>;
  orderByAttribute?: Maybe<Array<ItemOrder>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type VacationsQuery = (
  { __typename?: 'Query' }
  & { vacations: (
    { __typename?: 'VacationConnection' }
    & VacationEdgesFragment
  ) }
);

export type VacationEdgesFragment = (
  { __typename?: 'VacationConnection' }
  & Pick<VacationConnection, 'totalCount'>
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'VacationEdge' }
    & Pick<VacationEdge, 'cursor'>
    & VacationNodeFragment
  )>>>, pageInfo: (
    { __typename?: 'PageInfo' }
    & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
  ) }
);

export type VacationNodeFragment = (
  { __typename?: 'VacationEdge' }
  & { node?: Maybe<(
    { __typename?: 'Vacation' }
    & Pick<Vacation, 'nbItems'>
    & VacationFragment
  )> }
);

export type CreateVacationMutationVariables = Exact<{
  input: CreateVacationMutationInput;
}>;


export type CreateVacationMutation = (
  { __typename?: 'Mutation' }
  & { createVacation?: Maybe<(
    { __typename?: 'CreateVacationMutationPayload' }
    & { vacation?: Maybe<(
      { __typename?: 'Vacation' }
      & Pick<Vacation, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateVacationMutationVariables = Exact<{
  input: UpdateVacationMutationInput;
}>;


export type UpdateVacationMutation = (
  { __typename?: 'Mutation' }
  & { updateVacation?: Maybe<(
    { __typename?: 'UpdateVacationMutationPayload' }
    & { vacation?: Maybe<(
      { __typename?: 'Vacation' }
      & Pick<Vacation, 'id' | 'name'>
    )> }
  )> }
);

export type DestroyVacationMutationVariables = Exact<{
  input: DestroyVacationMutationInput;
}>;


export type DestroyVacationMutation = (
  { __typename?: 'Mutation' }
  & { destroyVacation?: Maybe<(
    { __typename?: 'DestroyVacationMutationPayload' }
    & { vacation?: Maybe<(
      { __typename?: 'Vacation' }
      & Pick<Vacation, 'id' | 'name'>
    )> }
  )> }
);

export type MonthlyVendorPaymentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MonthlyVendorPaymentQuery = (
  { __typename?: 'Query' }
  & { monthlyVendorPayment?: Maybe<(
    { __typename?: 'MonthlyVendorPayment' }
    & { vendor?: Maybe<(
      { __typename?: 'Vendor' }
      & { account: (
        { __typename?: 'Account' }
        & { bankInformation?: Maybe<(
          { __typename?: 'BankInformation' }
          & Pick<BankInformation, 'iban' | 'bic'>
        )> }
        & VendorAccountFragment
      ) }
      & VendorFragment
    )> }
    & MonthlyVendorPaymentFragment
  )> }
);

export type MonthlyVendorPaymentsQueryVariables = Exact<{
  where?: Maybe<MonthlyVendorPaymentFilter>;
  first?: Maybe<Scalars['Int']>;
}>;


export type MonthlyVendorPaymentsQuery = (
  { __typename?: 'Query' }
  & { monthlyVendorPayments: (
    { __typename?: 'MonthlyVendorPaymentConnection' }
    & MonthlyVendorPaymentEdgesFragment
  ) }
);

export type MonthlyVendorPaymentEdgesFragment = (
  { __typename?: 'MonthlyVendorPaymentConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'MonthlyVendorPaymentEdge' }
    & MonthlyVendorPaymentNodeFragment
  )>>> }
);

export type MonthlyVendorPaymentNodeFragment = (
  { __typename?: 'MonthlyVendorPaymentEdge' }
  & { node?: Maybe<(
    { __typename?: 'MonthlyVendorPayment' }
    & MonthlyVendorPaymentFragment
  )> }
);

export type MonthlyVendorPaymentFragment = (
  { __typename?: 'MonthlyVendorPayment' }
  & Pick<MonthlyVendorPayment, 'id' | 'paid' | 'createdAt' | 'startAt' | 'endAt'>
  & { vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & VendorFragment
  )>, vendorStatements?: Maybe<(
    { __typename?: 'VendorStatementConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'VendorStatementEdge' }
      & { node?: Maybe<(
        { __typename?: 'VendorStatement' }
        & Pick<VendorStatement, 'id' | 'number'>
        & { document?: Maybe<(
          { __typename?: 'CustomFile' }
          & Pick<CustomFile, 'imageUrl' | 'filename'>
        )> }
      )> }
    )>>> }
  )>, orderItems?: Maybe<(
    { __typename?: 'OrderItemConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'OrderItemEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'totalPrice'>
        & { order?: Maybe<(
          { __typename?: 'Order' }
          & Pick<Order, 'id'>
          & { sale?: Maybe<(
            { __typename?: 'Sale' }
            & Pick<Sale, 'id' | 'name'>
          )> }
        )> }
      )> }
    )>>> }
  )>, invoices?: Maybe<Array<(
    { __typename?: 'CustomFile' }
    & Pick<CustomFile, 'imageUrl' | 'filename'>
  )>>, vendorPaymentLines?: Maybe<(
    { __typename?: 'VendorPaymentLineConnection' }
    & VendorPaymentLineEdgesFragment
  )> }
);

export type UpdateMonthlyVendorPaymentMutationVariables = Exact<{
  input: UpdateMonthlyVendorPaymentMutationInput;
}>;


export type UpdateMonthlyVendorPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateMonthlyVendorPayment?: Maybe<(
    { __typename?: 'UpdateMonthlyVendorPaymentMutationPayload' }
    & { monthlyVendorPayment?: Maybe<(
      { __typename?: 'MonthlyVendorPayment' }
      & Pick<MonthlyVendorPayment, 'id'>
    )> }
  )> }
);

export type VendorStatementsQueryVariables = Exact<{
  where?: Maybe<VendorStatementFilter>;
}>;


export type VendorStatementsQuery = (
  { __typename?: 'Query' }
  & { vendorStatements: (
    { __typename?: 'VendorStatementConnection' }
    & VendorStatementEdgesFragment
  ) }
);

export type VendorStatementEdgesFragment = (
  { __typename?: 'VendorStatementConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'VendorStatementEdge' }
    & VendorStatementNodeFragment
  )>>> }
);

export type VendorStatementNodeFragment = (
  { __typename?: 'VendorStatementEdge' }
  & { node?: Maybe<(
    { __typename?: 'VendorStatement' }
    & VendorStatementFragment
  )> }
);

export type VendorStatementFragment = (
  { __typename?: 'VendorStatement' }
  & Pick<VendorStatement, 'id' | 'createdAt' | 'nbItems' | 'number'>
  & { document?: Maybe<(
    { __typename?: 'CustomFile' }
    & Pick<CustomFile, 'imageUrl' | 'filename'>
  )>, vendor?: Maybe<(
    { __typename?: 'Vendor' }
    & Pick<Vendor, 'id'>
  )>, sale?: Maybe<(
    { __typename?: 'Sale' }
    & Pick<Sale, 'id'>
  )> }
);

export type GetWriterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWriterQuery = (
  { __typename?: 'Query' }
  & { writer?: Maybe<(
    { __typename?: 'Writer' }
    & WriterFragment
  )> }
);

export type WriterFragment = (
  { __typename?: 'Writer' }
  & Pick<Writer, 'id'>
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'firstName' | 'lastName' | 'email' | 'state' | 'createdAt' | 'writerType' | 'writerPourcentageFees' | 'writerFixFees'>
  ), nbItemsCreated?: Maybe<(
    { __typename?: 'WriterKpiCreated' }
    & Pick<WriterKpiCreated, 'currentMonth' | 'lastMonth' | 'total' | 'totalAmountCurrentMonth' | 'totalAmountLastMonth'>
  )>, nbItemsAfterAccepted?: Maybe<(
    { __typename?: 'WriterKpiCreated' }
    & Pick<WriterKpiCreated, 'currentMonth' | 'lastMonth' | 'total' | 'totalAmountCurrentMonth' | 'totalAmountLastMonth'>
  )>, nbItemsAfterShipped?: Maybe<(
    { __typename?: 'WriterKpiCreated' }
    & Pick<WriterKpiCreated, 'currentMonth' | 'lastMonth' | 'total' | 'totalAmountCurrentMonth' | 'totalAmountLastMonth'>
  )>, kpis?: Maybe<(
    { __typename?: 'WriterKpis' }
    & Pick<WriterKpis, 'totalFromBeginning' | 'itemAcceptedPercentage'>
    & { itemPercentageStates?: Maybe<(
      { __typename?: 'WriterItemStates' }
      & Pick<WriterItemStates, 'draft' | 'accepted' | 'refused' | 'needUpdate' | 'waitingExpertise' | 'underExpertise'>
    )> }
  )> }
);

export type WriterPaymentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WriterPaymentQuery = (
  { __typename?: 'Query' }
  & { writerPayment?: Maybe<(
    { __typename?: 'WriterPayment' }
    & { writer?: Maybe<(
      { __typename?: 'Writer' }
      & { account: (
        { __typename?: 'Account' }
        & { bankInformation?: Maybe<(
          { __typename?: 'BankInformation' }
          & Pick<BankInformation, 'iban' | 'bic'>
        )> }
        & WriterAccountFragment
      ) }
      & WriterFragment
    )> }
    & WriterPaymentFragment
  )> }
);

export type WriterPaymentsQueryVariables = Exact<{
  where?: Maybe<WriterPaymentFilter>;
  first?: Maybe<Scalars['Int']>;
}>;


export type WriterPaymentsQuery = (
  { __typename?: 'Query' }
  & { writerPayments: (
    { __typename?: 'WriterPaymentConnection' }
    & WriterPaymentEdgesFragment
  ) }
);

export type WriterAccountFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'firstName' | 'lastName' | 'state' | 'isProfessional'>
);

export type WriterPaymentEdgesFragment = (
  { __typename?: 'WriterPaymentConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'WriterPaymentEdge' }
    & WriterPaymentNodeFragment
  )>>> }
);

export type WriterPaymentNodeFragment = (
  { __typename?: 'WriterPaymentEdge' }
  & { node?: Maybe<(
    { __typename?: 'WriterPayment' }
    & WriterPaymentFragment
  )> }
);

export type WriterPaymentFragment = (
  { __typename?: 'WriterPayment' }
  & Pick<WriterPayment, 'id' | 'paid' | 'createdAt' | 'startAt' | 'endAt'>
  & { writer?: Maybe<(
    { __typename?: 'Writer' }
    & WriterFragment
  )>, orderItems?: Maybe<(
    { __typename?: 'OrderItemConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'OrderItemEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'totalPrice'>
        & { order?: Maybe<(
          { __typename?: 'Order' }
          & Pick<Order, 'id'>
          & { sale?: Maybe<(
            { __typename?: 'Sale' }
            & Pick<Sale, 'id' | 'name'>
          )> }
        )> }
      )> }
    )>>> }
  )>, invoices?: Maybe<Array<(
    { __typename?: 'CustomFile' }
    & Pick<CustomFile, 'imageUrl' | 'filename'>
  )>>, writerPaymentLines?: Maybe<(
    { __typename?: 'WriterPaymentLineConnection' }
    & WriterPaymentLineEdgesFragment
  )> }
);

export type WriterPaymentLineEdgesFragment = (
  { __typename?: 'WriterPaymentLineConnection' }
  & { edges?: Maybe<Array<Maybe<(
    { __typename?: 'WriterPaymentLineEdge' }
    & WriterPaymentLineNodeFragment
  )>>> }
);

export type WriterPaymentLineNodeFragment = (
  { __typename?: 'WriterPaymentLineEdge' }
  & { node?: Maybe<(
    { __typename?: 'WriterPaymentLine' }
    & WriterPaymentLineFragment
  )> }
);

export type WriterPaymentLineFragment = (
  { __typename?: 'WriterPaymentLine' }
  & Pick<WriterPaymentLine, 'amount'>
);

export type UpdateWriterPaymentMutationVariables = Exact<{
  input: UpdateWriterPaymentMutationInput;
}>;


export type UpdateWriterPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateWriterPayment?: Maybe<(
    { __typename?: 'UpdateWriterPaymentMutationPayload' }
    & { writerPayment?: Maybe<(
      { __typename?: 'WriterPayment' }
      & Pick<WriterPayment, 'id'>
    )> }
  )> }
);

export type WriterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WriterQuery = (
  { __typename?: 'Query' }
  & { writer?: Maybe<(
    { __typename?: 'Writer' }
    & { nbItemsCreated?: Maybe<(
      { __typename?: 'WriterKpiCreated' }
      & Pick<WriterKpiCreated, 'currentMonth' | 'lastMonth' | 'total' | 'totalAmountCurrentMonth' | 'totalAmountLastMonth'>
    )>, nbItemsAfterAccepted?: Maybe<(
      { __typename?: 'WriterKpiCreated' }
      & Pick<WriterKpiCreated, 'currentMonth' | 'lastMonth' | 'total' | 'totalAmountCurrentMonth' | 'totalAmountLastMonth'>
    )>, kpis?: Maybe<(
      { __typename?: 'WriterKpis' }
      & Pick<WriterKpis, 'totalFromBeginning' | 'itemAcceptedPercentage'>
      & { itemPercentageStates?: Maybe<(
        { __typename?: 'WriterItemStates' }
        & Pick<WriterItemStates, 'draft' | 'accepted' | 'refused' | 'needUpdate' | 'waitingExpertise' | 'underExpertise'>
      )> }
    )> }
    & WriterTableFragment
  )> }
);

export type WritersQueryVariables = Exact<{
  where?: Maybe<WriterFilter>;
}>;


export type WritersQuery = (
  { __typename?: 'Query' }
  & { writers: (
    { __typename?: 'WriterConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'WriterEdge' }
      & { node?: Maybe<(
        { __typename?: 'Writer' }
        & WriterTableFragment
      )> }
    )>>> }
  ) }
);

export type WriterTableFragment = (
  { __typename?: 'Writer' }
  & Pick<Writer, 'id'>
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'firstName' | 'lastName' | 'email' | 'state' | 'createdAt' | 'writerType'>
  ), nbItemsCreated?: Maybe<(
    { __typename?: 'WriterKpiCreated' }
    & Pick<WriterKpiCreated, 'currentMonth' | 'lastMonth' | 'total'>
  )>, nbItemsAfterAccepted?: Maybe<(
    { __typename?: 'WriterKpiCreated' }
    & Pick<WriterKpiCreated, 'currentMonth' | 'lastMonth' | 'total'>
  )> }
);

export type CreateImportMutationVariables = Exact<{
  attributes: CreateImportInput;
}>;


export type CreateImportMutation = (
  { __typename?: 'Mutation' }
  & { createImport?: Maybe<(
    { __typename?: 'CreateImportMutationPayload' }
    & Pick<CreateImportMutationPayload, 'clientMutationId'>
    & { import?: Maybe<(
      { __typename?: 'Import' }
      & Pick<Import, 'id'>
    )> }
  )> }
);

export type CreateDrouotImportMutationVariables = Exact<{
  attributes: CreateDrouotImportInput;
}>;


export type CreateDrouotImportMutation = (
  { __typename?: 'Mutation' }
  & { createDrouotImport?: Maybe<(
    { __typename?: 'CreateDrouotImportMutationPayload' }
    & Pick<CreateDrouotImportMutationPayload, 'clientMutationId'>
    & { import?: Maybe<(
      { __typename?: 'Import' }
      & Pick<Import, 'id'>
    )> }
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'ForgotPasswordPayload' }
    & Pick<ForgotPasswordPayload, 'success'>
    & { errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'details' | 'message'>
    )> }
  )> }
);

export type LogInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LogInMutation = (
  { __typename?: 'Mutation' }
  & { customSignIn?: Maybe<(
    { __typename?: 'CustomSignInPayload' }
    & Pick<CustomSignInPayload, 'success' | 'sessionToken'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'lastAccountTypeUsed'>
      & { accounts: (
        { __typename?: 'AccountConnection' }
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'AccountEdge' }
          & { node?: Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'id'>
            & { accountable: { __typename: 'Buyer' } | (
              { __typename: 'Expert' }
              & Pick<Expert, 'id'>
            ) | (
              { __typename: 'Vendor' }
              & Pick<Vendor, 'id'>
            ) | { __typename: 'Writer' } }
          )> }
        )>>> }
      ) }
    )> }
  )> }
);

export type UpdateUserOnBoardingMutationVariables = Exact<{
  userId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type UpdateUserOnBoardingMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName'>
  )> }
);

export type CreateAccountMutationVariables = Exact<{
  attributes: CreateAccountInput;
}>;


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount?: Maybe<(
    { __typename?: 'CreateAccountMutationPayload' }
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'firstName' | 'lastName' | 'email'>
      & { accountable: (
        { __typename: 'Buyer' }
        & Pick<Buyer, 'id'>
      ) | (
        { __typename: 'Expert' }
        & Pick<Expert, 'id'>
      ) | (
        { __typename: 'Vendor' }
        & Pick<Vendor, 'id'>
      ) | (
        { __typename: 'Writer' }
        & Pick<Writer, 'id'>
      ) }
    )> }
  )> }
);

export type SignUpMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  role: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { customSignUp?: Maybe<(
    { __typename?: 'CustomSignUpPayload' }
    & Pick<CustomSignUpPayload, 'sessionToken' | 'success'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      & { accounts: (
        { __typename?: 'AccountConnection' }
        & { edges?: Maybe<Array<Maybe<(
          { __typename?: 'AccountEdge' }
          & { node?: Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'id'>
            & { accountable: { __typename: 'Buyer' } | (
              { __typename: 'Expert' }
              & Pick<Expert, 'id'>
            ) | (
              { __typename: 'Vendor' }
              & Pick<Vendor, 'id'>
            ) | { __typename: 'Writer' } }
          )> }
        )>>> }
      ) }
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  resetPasswordToken: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'success'>
    & { errors: Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'details' | 'message'>
    )> }
  )> }
);

export type GetCustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & CustomerFragment
  )> }
);

export type CreateCustomerMutationVariables = Exact<{
  attributes: CreateCustomerInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer?: Maybe<(
    { __typename?: 'CreateCustomerMutationPayload' }
    & Pick<CreateCustomerMutationPayload, 'clientMutationId'>
    & { customer?: Maybe<(
      { __typename?: 'Customer' }
      & CustomerFragment
    )> }
  )> }
);

export type ItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ItemQuery = (
  { __typename?: 'Query' }
  & { item?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'state'>
    & ItemStepsFragment
    & ItemImagesFragment
    & ItemCategoryFragment
  )> }
);

export type ItemImagesFragment = (
  { __typename?: 'Item' }
  & { images?: Maybe<Array<(
    { __typename?: 'Image' }
    & ItemImageFragment
  )>> }
);

export type ItemImageFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'imageUrl' | 'position'>
);

export type UploadImagePositionsMutationVariables = Exact<{
  id: Scalars['ID'];
  positions: UpdateImagePositionInput;
}>;


export type UploadImagePositionsMutation = (
  { __typename?: 'Mutation' }
  & { updateItem?: Maybe<(
    { __typename?: 'UpdateItemMutationPayload' }
    & Pick<UpdateItemMutationPayload, 'clientMutationId'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'title'>
      & { images?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'imageUrl' | 'position'>
      )>> }
    ) }
  )> }
);

export type RemoveImagePositionsMutationVariables = Exact<{
  id: Scalars['ID'];
  positions?: Maybe<Array<Scalars['Int']>>;
}>;


export type RemoveImagePositionsMutation = (
  { __typename?: 'Mutation' }
  & { updateItem?: Maybe<(
    { __typename?: 'UpdateItemMutationPayload' }
    & Pick<UpdateItemMutationPayload, 'clientMutationId'>
    & { item: (
      { __typename?: 'Item' }
      & Pick<Item, 'title'>
      & { images?: Maybe<Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'imageUrl' | 'position'>
      )>> }
    ) }
  )> }
);

export const VendorPlanFragmentDoc = gql`
    fragment VendorPlan on VendorPlan {
  name
  expertiseFirmEnabled
  projectsEnabled
}
    `;
export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  name
}
    `;
export const TagNodeFragmentDoc = gql`
    fragment TagNode on TagEdge {
  node {
    ...Tag
  }
}
    ${TagFragmentDoc}`;
export const TagEdgesFragmentDoc = gql`
    fragment TagEdges on TagConnection {
  edges {
    ...TagNode
  }
}
    ${TagNodeFragmentDoc}`;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  id
  lastName
  firstName
  email
  addressLine1
  addressLine2
  addressLine3
  city
  zip
  country
  phone1
  phone2
  itemCategories {
    edges {
      node {
        id
        name
      }
    }
  }
  itemSubCategories {
    edges {
      node {
        id
        name
      }
    }
  }
  tags {
    ...TagEdges
  }
}
    ${TagEdgesFragmentDoc}`;
export const CustomerNodeFragmentDoc = gql`
    fragment CustomerNode on CustomerEdge {
  node {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;
export const CustomerEdgesFragmentDoc = gql`
    fragment CustomerEdges on CustomerConnection {
  edges {
    ...CustomerNode
  }
}
    ${CustomerNodeFragmentDoc}`;
export const ItemSubCategoryFragmentDoc = gql`
    fragment ItemSubCategory on ItemSubCategory {
  id
  name
}
    `;
export const CustomerTableFragmentDoc = gql`
    fragment CustomerTable on Customer {
  id
  lastName
  firstName
  email
  tags {
    ...TagEdges
  }
}
    ${TagEdgesFragmentDoc}`;
export const DepositSlipFragmentDoc = gql`
    fragment DepositSlip on DepositSlip {
  id
  createdAt
  document {
    imageUrl
    filename
  }
  nbItems
  vendor {
    id
  }
  number
}
    `;
export const DepositSlipNodeFragmentDoc = gql`
    fragment DepositSlipNode on DepositSlipEdge {
  node {
    ...DepositSlip
  }
}
    ${DepositSlipFragmentDoc}`;
export const DepositSlipEdgesFragmentDoc = gql`
    fragment DepositSlipEdges on DepositSlipConnection {
  edges {
    ...DepositSlipNode
  }
}
    ${DepositSlipNodeFragmentDoc}`;
export const BuyerFragmentDoc = gql`
    fragment Buyer on Buyer {
  account {
    id
    email
  }
}
    `;
export const DesireFragmentDoc = gql`
    fragment Desire on Desire {
  id
  buyer {
    ...Buyer
  }
  customer {
    ...Customer
  }
  title
  tags {
    ...TagEdges
  }
  createdAt
}
    ${BuyerFragmentDoc}
${CustomerFragmentDoc}
${TagEdgesFragmentDoc}`;
export const DesireNodeFragmentDoc = gql`
    fragment DesireNode on DesireEdge {
  node {
    ...Desire
  }
}
    ${DesireFragmentDoc}`;
export const DesireEdgesFragmentDoc = gql`
    fragment DesireEdges on DesireConnection {
  edges {
    ...DesireNode
  }
}
    ${DesireNodeFragmentDoc}`;
export const ExpertAccountFragmentDoc = gql`
    fragment ExpertAccount on Account {
  firstName
  lastName
  state
  isProfessional
}
    `;
export const ExpertFragmentDoc = gql`
    fragment Expert on Expert {
  id
  account {
    id
    firstName
    lastName
    state
  }
}
    `;
export const ExpertPaymentLineFragmentDoc = gql`
    fragment ExpertPaymentLine on ExpertPaymentLine {
  amount
}
    `;
export const ExpertPaymentLineNodeFragmentDoc = gql`
    fragment ExpertPaymentLineNode on ExpertPaymentLineEdge {
  node {
    ...ExpertPaymentLine
  }
}
    ${ExpertPaymentLineFragmentDoc}`;
export const ExpertPaymentLineEdgesFragmentDoc = gql`
    fragment ExpertPaymentLineEdges on ExpertPaymentLineConnection {
  edges {
    ...ExpertPaymentLineNode
  }
}
    ${ExpertPaymentLineNodeFragmentDoc}`;
export const ExpertPaymentFragmentDoc = gql`
    fragment ExpertPayment on ExpertPayment {
  id
  expert {
    ...Expert
  }
  orderItems {
    edges {
      node {
        id
        totalPrice
        order {
          id
          sale {
            id
            name
          }
        }
      }
    }
  }
  invoices {
    imageUrl
    filename
  }
  paid
  createdAt
  startAt
  endAt
  expertPaymentLines {
    ...ExpertPaymentLineEdges
  }
}
    ${ExpertFragmentDoc}
${ExpertPaymentLineEdgesFragmentDoc}`;
export const ExpertPaymentNodeFragmentDoc = gql`
    fragment ExpertPaymentNode on ExpertPaymentEdge {
  node {
    ...ExpertPayment
  }
}
    ${ExpertPaymentFragmentDoc}`;
export const ExpertPaymentEdgesFragmentDoc = gql`
    fragment ExpertPaymentEdges on ExpertPaymentConnection {
  edges {
    ...ExpertPaymentNode
  }
}
    ${ExpertPaymentNodeFragmentDoc}`;
export const ItemSubCategoryNodeFragmentDoc = gql`
    fragment ItemSubCategoryNode on ItemSubCategoryEdge {
  node {
    id
    name
  }
}
    `;
export const ItemSubCategoryEdgesFragmentDoc = gql`
    fragment ItemSubCategoryEdges on ItemSubCategoryConnection {
  edges {
    ...ItemSubCategoryNode
  }
}
    ${ItemSubCategoryNodeFragmentDoc}`;
export const ExpertNodeFragmentDoc = gql`
    fragment ExpertNode on ExpertEdge {
  node {
    ...Expert
  }
}
    ${ExpertFragmentDoc}`;
export const ExpertEdgesFragmentDoc = gql`
    fragment ExpertEdges on ExpertConnection {
  edges {
    ...ExpertNode
  }
}
    ${ExpertNodeFragmentDoc}`;
export const FileFragmentDoc = gql`
    fragment File on CustomFile {
  imageUrl
  filename
  extension
}
    `;
export const ItemStepFragmentDoc = gql`
    fragment ItemStep on ItemStep {
  id
  number
  comment
  state
  name
}
    `;
export const ItemStepNodeFragmentDoc = gql`
    fragment ItemStepNode on ItemStepEdge {
  node {
    ...ItemStep
  }
}
    ${ItemStepFragmentDoc}`;
export const ItemStepEdgesFragmentDoc = gql`
    fragment ItemStepEdges on ItemStepConnection {
  edges {
    ...ItemStepNode
  }
}
    ${ItemStepNodeFragmentDoc}`;
export const ItemStepsFragmentDoc = gql`
    fragment ItemSteps on Item {
  itemSteps {
    ...ItemStepEdges
  }
}
    ${ItemStepEdgesFragmentDoc}`;
export const VendorAccountFragmentDoc = gql`
    fragment VendorAccount on Account {
  firstName
  lastName
  state
  isProfessional
}
    `;
export const VendorFragmentDoc = gql`
    fragment Vendor on Vendor {
  account {
    ...VendorAccount
  }
  externalRainworxId
}
    ${VendorAccountFragmentDoc}`;
export const WriterFragmentDoc = gql`
    fragment Writer on Writer {
  id
  account {
    id
    firstName
    lastName
    email
    state
    createdAt
    writerType
    writerPourcentageFees
    writerFixFees
  }
  nbItemsCreated {
    currentMonth
    lastMonth
    total
    totalAmountCurrentMonth
    totalAmountLastMonth
  }
  nbItemsAfterAccepted {
    currentMonth
    lastMonth
    total
    totalAmountCurrentMonth
    totalAmountLastMonth
  }
  nbItemsAfterShipped {
    currentMonth
    lastMonth
    total
    totalAmountCurrentMonth
    totalAmountLastMonth
  }
  kpis {
    totalFromBeginning
    itemAcceptedPercentage
    itemPercentageStates {
      draft
      accepted
      refused
      needUpdate
      waitingExpertise
      underExpertise
    }
  }
}
    `;
export const ItemCategoryFragmentDoc = gql`
    fragment ItemCategory on Item {
  itemCategory {
    id
    name
    templateType
    externalRainworxId
  }
  itemSubCategory {
    id
    name
    externalRainworxId
  }
}
    `;
export const ItemTitlesFragmentDoc = gql`
    fragment ItemTitles on Item {
  title
  subtitle
  state
}
    `;
export const ItemPriceFragmentDoc = gql`
    fragment ItemPrice on Item {
  estimatedPrice
  reservedPrice
  startingPrice
}
    `;
export const ArtistFragmentDoc = gql`
    fragment Artist on Artist {
  id
  firstName
  lastName
  isGroup
  tag
  itemCategoryId
}
    `;
export const ItemArtistNodeFragmentDoc = gql`
    fragment ItemArtistNode on ArtistEdge {
  node {
    ...Artist
  }
}
    ${ArtistFragmentDoc}`;
export const ItemArtistEdgesFragmentDoc = gql`
    fragment ItemArtistEdges on ArtistConnection {
  edges {
    ...ItemArtistNode
  }
}
    ${ItemArtistNodeFragmentDoc}`;
export const ItemArtistsFragmentDoc = gql`
    fragment ItemArtists on Item {
  artists {
    ...ItemArtistEdges
  }
}
    ${ItemArtistEdgesFragmentDoc}`;
export const VacationFragmentDoc = gql`
    fragment Vacation on Vacation {
  id
  name
  description
  state
  startAt
  endAt
  image {
    imageUrl
  }
  expert {
    id
  }
}
    `;
export const PublishingBookFragmentDoc = gql`
    fragment PublishingBook on Book {
  id
  publishers
  publisherKnownButNotMentioned
  editingAttribute
  editingAttributeDetails
  item {
    id
    locations
    locationKnownButNotMentioned
    dateFrom
    dateTo
    dateOption
  }
}
    `;
export const BookFragmentDoc = gql`
    fragment Book on Book {
  createdAt
  id
  updatedAt
  publishers
  bibliographyComment
  illustrationComment
  collationComment
  assemblySpecification
  assemblyType
  bindingColor
  bindingFormat
  bindingStyle
  bindingMaterial
  bindingFormatDetails
  bindingType
  bindingTypeOptions
  bookSpineMaterial
  bookSpineOrnament1
  bookSpineOrnament1Option
  bookSpineOrnament2
  bookSpineOrnament2Option
  bookSpineOrnamentOption
  bookSpineType
  bindingAge
  bindingMaterialOptions
  bindingAdditionalOptions
  bookBinder
  size
  coverType
  coverQuality
  coverColor
  coverMaterial
  coverOrnament1
  coverOrnament1Option
  coverOrnament2
  coverOrnament2Option
  coverOrnamentOption
  edgeColor
  edgeStyle
  edgeType
  isOblong
  dumbSpine
  isBindingSigned
  paperbackType
  isApproximatelyPaginating
  isPaginating
  paginationInfo
  withFolder
  casingType
  volumes {
    edges {
      node {
        id
        parts {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
  ...PublishingBook
  item {
    id
    state
  }
}
    ${PublishingBookFragmentDoc}`;
export const TagsFragmentDoc = gql`
    fragment Tags on Item {
  tags {
    ...TagEdges
  }
}
    ${TagEdgesFragmentDoc}`;
export const AutoTagsFragmentDoc = gql`
    fragment AutoTags on Item {
  autoTags {
    ...TagEdges
  }
}
    ${TagEdgesFragmentDoc}`;
export const ItemFragmentDoc = gql`
    fragment Item on Item {
  createdAt
  description
  conditionsComment
  globalComment
  height
  width
  length
  weight
  otherComment
  id
  sku
  project {
    id
    title
  }
  customSkuPart
  conditionAccepted
  images {
    imageUrl
    position
  }
  invoices {
    ...File
  }
  authenticityDocuments {
    ...File
  }
  ...ItemSteps
  dateFrom
  dateTo
  dateOption
  catchPhrase
  catchPhraseEnabled
  customDescription
  customDescriptionEnabled
  autoDescription
  state
  locations
  locationKnownButNotMentioned
  artistUnknown
  artistCollectif
  artistKnownButNotMentioned
  expert {
    ...Expert
  }
  vendor {
    ...Vendor
  }
  writer {
    ...Writer
  }
  updatedAt
  ...ItemCategory
  ...ItemTitles
  ...ItemPrice
  ...ItemArtists
  vacation {
    ...Vacation
  }
  template {
    __typename
    ... on Book {
      ...Book
    }
  }
  ...Tags
  ...AutoTags
}
    ${FileFragmentDoc}
${ItemStepsFragmentDoc}
${ExpertFragmentDoc}
${VendorFragmentDoc}
${WriterFragmentDoc}
${ItemCategoryFragmentDoc}
${ItemTitlesFragmentDoc}
${ItemPriceFragmentDoc}
${ItemArtistsFragmentDoc}
${VacationFragmentDoc}
${BookFragmentDoc}
${TagsFragmentDoc}
${AutoTagsFragmentDoc}`;
export const ItemNodeFragmentDoc = gql`
    fragment ItemNode on ItemEdge {
  node {
    ...Item
  }
}
    ${ItemFragmentDoc}`;
export const ItemEdgesFragmentDoc = gql`
    fragment ItemEdges on ItemConnection {
  edges {
    ...ItemNode
  }
}
    ${ItemNodeFragmentDoc}`;
export const ItemTableFragmentDoc = gql`
    fragment ItemTable on Item {
  id
  artistUnknown
  artistCollectif
  title
  state
  ...ItemCategory
  firstImage {
    imageUrl
    position
  }
  ...ItemArtists
  expert {
    id
    account {
      firstName
      lastName
    }
  }
  vacation {
    id
  }
  sale {
    id
  }
  vendor {
    account {
      firstName
      lastName
    }
  }
  writer {
    account {
      firstName
      lastName
    }
  }
  sku
  createdAt
}
    ${ItemCategoryFragmentDoc}
${ItemArtistsFragmentDoc}`;
export const NewsLetterFragmentDoc = gql`
    fragment NewsLetter on NewsLetter {
  id
  htmlContent
  test
  emailFrom
  emailTo
  subject
  buyer {
    account {
      firstName
      lastName
      email
    }
    autoTags {
      ...TagEdges
    }
    expertTags {
      ...TagEdges
    }
    importTags {
      ...TagEdges
    }
    bookmarkTags {
      ...TagEdges
    }
  }
  customer {
    firstName
    lastName
    email
    tags {
      ...TagEdges
    }
  }
  sale {
    id
    name
  }
  nbItems
}
    ${TagEdgesFragmentDoc}`;
export const NewsLetterNodeFragmentDoc = gql`
    fragment NewsLetterNode on NewsLetterEdge {
  node {
    ...NewsLetter
  }
}
    ${NewsLetterFragmentDoc}`;
export const NewsLetterEdgesFragmentDoc = gql`
    fragment NewsLetterEdges on NewsLetterConnection {
  edges {
    ...NewsLetterNode
  }
  totalCount
  pageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
}
    ${NewsLetterNodeFragmentDoc}`;
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  id
  shippingAddress {
    id
    addressLine1
    addressLine2
    addressLine2
    city
    zip
    country
    email
    firstName
    lastName
    companyName
    phone1
    phone2
  }
  totalPrice
  totalPriceTaxIncluded
  shippingPrice
  shippingPriceTaxIncluded
  shippingTax
  totalTax
  totalItemsPrice
  state
  orderItems {
    edges {
      node {
        id
        state
        vendor {
          id
        }
        item {
          id
          title
          sku
          finalPrice
          images {
            imageUrl
            position
          }
        }
      }
    }
  }
}
    `;
export const OrderTableFragmentDoc = gql`
    fragment OrderTable on Order {
  id
  undeplusRef
  totalPrice
  totalPriceTaxIncluded
  state
  stripePaymentLink
  trackingUrls
  labelUrls
  trackingNumbers
  createdAt
  buyer {
    id
    account {
      firstName
      lastName
    }
  }
  orderItems {
    edges {
      node {
        id
        state
        vendor {
          id
        }
        item {
          title
          sku
        }
      }
    }
  }
  labels {
    edges {
      node {
        trackingNumber
        undeplusRef
        externalLabelUrl
        labelDocument {
          imageUrl
        }
        trackingUrl
        vendor {
          id
        }
      }
    }
  }
}
    `;
export const PaidSlipFragmentDoc = gql`
    fragment PaidSlip on PaidSlip {
  id
  createdAt
  document {
    imageUrl
    filename
  }
  nbItems
  buyer {
    id
  }
  sale {
    id
  }
  number
}
    `;
export const PaidSlipNodeFragmentDoc = gql`
    fragment PaidSlipNode on PaidSlipEdge {
  node {
    ...PaidSlip
  }
}
    ${PaidSlipFragmentDoc}`;
export const PaidSlipEdgesFragmentDoc = gql`
    fragment PaidSlipEdges on PaidSlipConnection {
  edges {
    ...PaidSlipNode
  }
}
    ${PaidSlipNodeFragmentDoc}`;
export const PickupRequestFragmentDoc = gql`
    fragment PickupRequest on PickupRequest {
  id
  pickupLocationCloseTime
  instructions
  pickupLocation
  createdAt
  pickupTimestamp
  replans
  vendor {
    id
  }
}
    `;
export const PickupRequestNodeFragmentDoc = gql`
    fragment PickupRequestNode on PickupRequestEdge {
  node {
    ...PickupRequest
  }
}
    ${PickupRequestFragmentDoc}`;
export const PickupRequestEdgesFragmentDoc = gql`
    fragment PickupRequestEdges on PickupRequestConnection {
  edges {
    ...PickupRequestNode
  }
}
    ${PickupRequestNodeFragmentDoc}`;
export const AddressProfileFragmentDoc = gql`
    fragment AddressProfile on Address {
  id
  createdAt
  updatedAt
  number
  addressLine1
  addressLine2
  addressLine3
  zip
  city
  country
  type
}
    `;
export const BankInformationProfileFragmentDoc = gql`
    fragment BankInformationProfile on BankInformation {
  id
  createdAt
  updatedAt
  iban
  bic
  fullName
  name
}
    `;
export const AccountProfileFragmentDoc = gql`
    fragment AccountProfile on Account {
  id
  createdAt
  updatedAt
  firstName
  lastName
  civility
  birthDate
  corporateForm
  phone
  companyName
  email
  isProfessional
  bankInformation {
    ...BankInformationProfile
  }
  identityDocuments {
    ...File
  }
  ribDocuments {
    ...File
  }
  shippingAddress {
    id
    addressLine1
    addressLine2
    addressLine3
    city
    companyName
    country
    email
    firstName
    lastName
    phone1
    phone2
    zip
  }
}
    ${BankInformationProfileFragmentDoc}
${FileFragmentDoc}`;
export const ProjectFragmentDoc = gql`
    fragment Project on Project {
  id
  vendor {
    id
    ...Vendor
  }
  owner {
    id
    ...Vendor
  }
  nbItems
  createdAt
  title
  number
  itemNumberStart
  exclusif
  isClassicSale
  sharingProfit
  percentageProfitForOwner
  percentageProfitForVendor
  auctioneerNumber
  projectFees {
    edges {
      node {
        name
        value
      }
    }
  }
}
    ${VendorFragmentDoc}`;
export const ProjectNodeFragmentDoc = gql`
    fragment ProjectNode on ProjectEdge {
  node {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export const ProjectEdgesFragmentDoc = gql`
    fragment ProjectEdges on ProjectConnection {
  edges {
    ...ProjectNode
  }
}
    ${ProjectNodeFragmentDoc}`;
export const OrderItemFragmentDoc = gql`
    fragment OrderItem on OrderItem {
  id
  totalPrice
  undeplusRef
  item {
    id
  }
}
    `;
export const OrderItemNodeFragmentDoc = gql`
    fragment OrderItemNode on OrderItemEdge {
  node {
    ...OrderItem
  }
}
    ${OrderItemFragmentDoc}`;
export const OrderItemEdgesFragmentDoc = gql`
    fragment OrderItemEdges on OrderItemConnection {
  edges {
    ...OrderItemNode
  }
}
    ${OrderItemNodeFragmentDoc}`;
export const VendorNodeFragmentDoc = gql`
    fragment VendorNode on VendorEdge {
  node {
    id
    ...Vendor
  }
}
    ${VendorFragmentDoc}`;
export const VendorEdgesFragmentDoc = gql`
    fragment VendorEdges on VendorConnection {
  edges {
    ...VendorNode
  }
}
    ${VendorNodeFragmentDoc}`;
export const PurchaseSlipFragmentDoc = gql`
    fragment PurchaseSlip on PurchaseSlip {
  id
  createdAt
  document {
    imageUrl
    filename
  }
  nbItems
  buyer {
    id
  }
  sale {
    id
  }
  number
}
    `;
export const PurchaseSlipNodeFragmentDoc = gql`
    fragment PurchaseSlipNode on PurchaseSlipEdge {
  node {
    ...PurchaseSlip
  }
}
    ${PurchaseSlipFragmentDoc}`;
export const PurchaseSlipEdgesFragmentDoc = gql`
    fragment PurchaseSlipEdges on PurchaseSlipConnection {
  edges {
    ...PurchaseSlipNode
  }
}
    ${PurchaseSlipNodeFragmentDoc}`;
export const QuickTextFragmentDoc = gql`
    fragment QuickText on QuickText {
  id
  title
  text
  number
  createdAt
}
    `;
export const QuickTextNodeFragmentDoc = gql`
    fragment QuickTextNode on QuickTextEdge {
  node {
    ...QuickText
  }
}
    ${QuickTextFragmentDoc}`;
export const QuickTextEdgesFragmentDoc = gql`
    fragment QuickTextEdges on QuickTextConnection {
  edges {
    ...QuickTextNode
  }
}
    ${QuickTextNodeFragmentDoc}`;
export const SaleMandatFragmentDoc = gql`
    fragment SaleMandat on SaleMandat {
  id
  createdAt
  document {
    imageUrl
    filename
  }
  nbItems
  vendor {
    id
  }
  sale {
    id
  }
  number
}
    `;
export const SaleMandatNodeFragmentDoc = gql`
    fragment SaleMandatNode on SaleMandatEdge {
  node {
    ...SaleMandat
  }
}
    ${SaleMandatFragmentDoc}`;
export const SaleMandatEdgesFragmentDoc = gql`
    fragment SaleMandatEdges on SaleMandatConnection {
  edges {
    ...SaleMandatNode
  }
}
    ${SaleMandatNodeFragmentDoc}`;
export const SaleFragmentDoc = gql`
    fragment Sale on Sale {
  id
  name
  description
  state
  startAt
  endAt
  externalEventRainworxId
  image {
    imageUrl
  }
  expert {
    id
  }
  saleMandats {
    ...SaleMandatEdges
  }
}
    ${SaleMandatEdgesFragmentDoc}`;
export const SaleNodeFragmentDoc = gql`
    fragment SaleNode on SaleEdge {
  node {
    ...Sale
    nbItems
  }
}
    ${SaleFragmentDoc}`;
export const SaleEdgesFragmentDoc = gql`
    fragment SaleEdges on SaleConnection {
  edges {
    ...SaleNode
  }
}
    ${SaleNodeFragmentDoc}`;
export const TagTableFragmentDoc = gql`
    fragment TagTable on Tag {
  id
  name
  createdAt
  auto
  account {
    lastName
    firstName
  }
}
    `;
export const VacationNodeFragmentDoc = gql`
    fragment VacationNode on VacationEdge {
  node {
    ...Vacation
    nbItems
  }
}
    ${VacationFragmentDoc}`;
export const VacationEdgesFragmentDoc = gql`
    fragment VacationEdges on VacationConnection {
  edges {
    cursor
    ...VacationNode
  }
  totalCount
  pageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
}
    ${VacationNodeFragmentDoc}`;
export const VendorPaymentLineFragmentDoc = gql`
    fragment VendorPaymentLine on VendorPaymentLine {
  amount
  feePercentage
  feeAmount
}
    `;
export const VendorPaymentLineNodeFragmentDoc = gql`
    fragment VendorPaymentLineNode on VendorPaymentLineEdge {
  node {
    ...VendorPaymentLine
  }
}
    ${VendorPaymentLineFragmentDoc}`;
export const VendorPaymentLineEdgesFragmentDoc = gql`
    fragment VendorPaymentLineEdges on VendorPaymentLineConnection {
  edges {
    ...VendorPaymentLineNode
  }
}
    ${VendorPaymentLineNodeFragmentDoc}`;
export const MonthlyVendorPaymentFragmentDoc = gql`
    fragment MonthlyVendorPayment on MonthlyVendorPayment {
  id
  vendor {
    ...Vendor
  }
  vendorStatements {
    edges {
      node {
        id
        number
        document {
          imageUrl
          filename
        }
      }
    }
  }
  orderItems {
    edges {
      node {
        id
        totalPrice
        order {
          id
          sale {
            id
            name
          }
        }
      }
    }
  }
  invoices {
    imageUrl
    filename
  }
  paid
  createdAt
  startAt
  endAt
  vendorPaymentLines {
    ...VendorPaymentLineEdges
  }
}
    ${VendorFragmentDoc}
${VendorPaymentLineEdgesFragmentDoc}`;
export const MonthlyVendorPaymentNodeFragmentDoc = gql`
    fragment MonthlyVendorPaymentNode on MonthlyVendorPaymentEdge {
  node {
    ...MonthlyVendorPayment
  }
}
    ${MonthlyVendorPaymentFragmentDoc}`;
export const MonthlyVendorPaymentEdgesFragmentDoc = gql`
    fragment MonthlyVendorPaymentEdges on MonthlyVendorPaymentConnection {
  edges {
    ...MonthlyVendorPaymentNode
  }
}
    ${MonthlyVendorPaymentNodeFragmentDoc}`;
export const VendorStatementFragmentDoc = gql`
    fragment VendorStatement on VendorStatement {
  id
  createdAt
  document {
    imageUrl
    filename
  }
  nbItems
  vendor {
    id
  }
  sale {
    id
  }
  number
}
    `;
export const VendorStatementNodeFragmentDoc = gql`
    fragment VendorStatementNode on VendorStatementEdge {
  node {
    ...VendorStatement
  }
}
    ${VendorStatementFragmentDoc}`;
export const VendorStatementEdgesFragmentDoc = gql`
    fragment VendorStatementEdges on VendorStatementConnection {
  edges {
    ...VendorStatementNode
  }
}
    ${VendorStatementNodeFragmentDoc}`;
export const WriterAccountFragmentDoc = gql`
    fragment WriterAccount on Account {
  firstName
  lastName
  state
  isProfessional
}
    `;
export const WriterPaymentLineFragmentDoc = gql`
    fragment WriterPaymentLine on WriterPaymentLine {
  amount
}
    `;
export const WriterPaymentLineNodeFragmentDoc = gql`
    fragment WriterPaymentLineNode on WriterPaymentLineEdge {
  node {
    ...WriterPaymentLine
  }
}
    ${WriterPaymentLineFragmentDoc}`;
export const WriterPaymentLineEdgesFragmentDoc = gql`
    fragment WriterPaymentLineEdges on WriterPaymentLineConnection {
  edges {
    ...WriterPaymentLineNode
  }
}
    ${WriterPaymentLineNodeFragmentDoc}`;
export const WriterPaymentFragmentDoc = gql`
    fragment WriterPayment on WriterPayment {
  id
  writer {
    ...Writer
  }
  orderItems {
    edges {
      node {
        id
        totalPrice
        order {
          id
          sale {
            id
            name
          }
        }
      }
    }
  }
  invoices {
    imageUrl
    filename
  }
  paid
  createdAt
  startAt
  endAt
  writerPaymentLines {
    ...WriterPaymentLineEdges
  }
}
    ${WriterFragmentDoc}
${WriterPaymentLineEdgesFragmentDoc}`;
export const WriterPaymentNodeFragmentDoc = gql`
    fragment WriterPaymentNode on WriterPaymentEdge {
  node {
    ...WriterPayment
  }
}
    ${WriterPaymentFragmentDoc}`;
export const WriterPaymentEdgesFragmentDoc = gql`
    fragment WriterPaymentEdges on WriterPaymentConnection {
  edges {
    ...WriterPaymentNode
  }
}
    ${WriterPaymentNodeFragmentDoc}`;
export const WriterTableFragmentDoc = gql`
    fragment WriterTable on Writer {
  id
  account {
    firstName
    lastName
    email
    state
    createdAt
    writerType
  }
  nbItemsCreated {
    currentMonth
    lastMonth
    total
  }
  nbItemsAfterAccepted {
    currentMonth
    lastMonth
    total
  }
}
    `;
export const ItemImageFragmentDoc = gql`
    fragment ItemImage on Image {
  imageUrl
  position
}
    `;
export const ItemImagesFragmentDoc = gql`
    fragment ItemImages on Item {
  images {
    ...ItemImage
  }
}
    ${ItemImageFragmentDoc}`;
export const CommonViewerWithAccountDocument = gql`
    query CommonViewerWithAccount($accountableType: String!) {
  viewer(accountableType: $accountableType) {
    id
    email
    firstName
    lastName
    imageUrl
    currentRole {
      name
    }
    accountTypes
    lastAccountTypeUsed
    accounts(accountableType: $accountableType) {
      edges {
        node {
          id
          importConditionAccepted
          state
          firstName
          lastName
          completed
          nbItems
          accountable {
            ... on Expert {
              id
            }
            ... on Vendor {
              id
              vendorPlan {
                ...VendorPlan
              }
            }
            ... on Buyer {
              id
            }
            ... on Writer {
              id
              vendor {
                id
                vendorPlan {
                  ...VendorPlan
                }
              }
            }
            __typename
          }
        }
      }
    }
  }
}
    ${VendorPlanFragmentDoc}`;

/**
 * __useCommonViewerWithAccountQuery__
 *
 * To run a query within a React component, call `useCommonViewerWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonViewerWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonViewerWithAccountQuery({
 *   variables: {
 *      accountableType: // value for 'accountableType'
 *   },
 * });
 */
export function useCommonViewerWithAccountQuery(baseOptions: Apollo.QueryHookOptions<CommonViewerWithAccountQuery, CommonViewerWithAccountQueryVariables>) {
        return Apollo.useQuery<CommonViewerWithAccountQuery, CommonViewerWithAccountQueryVariables>(CommonViewerWithAccountDocument, baseOptions);
      }
export function useCommonViewerWithAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommonViewerWithAccountQuery, CommonViewerWithAccountQueryVariables>) {
          return Apollo.useLazyQuery<CommonViewerWithAccountQuery, CommonViewerWithAccountQueryVariables>(CommonViewerWithAccountDocument, baseOptions);
        }
export type CommonViewerWithAccountQueryHookResult = ReturnType<typeof useCommonViewerWithAccountQuery>;
export type CommonViewerWithAccountLazyQueryHookResult = ReturnType<typeof useCommonViewerWithAccountLazyQuery>;
export type CommonViewerWithAccountQueryResult = Apollo.QueryResult<CommonViewerWithAccountQuery, CommonViewerWithAccountQueryVariables>;
export const CommonViewerDocument = gql`
    query CommonViewer {
  viewer {
    id
    currentRole {
      name
    }
    lastAccountTypeUsed
    email
    firstName
    lastName
    imageUrl
  }
}
    `;

/**
 * __useCommonViewerQuery__
 *
 * To run a query within a React component, call `useCommonViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommonViewerQuery(baseOptions?: Apollo.QueryHookOptions<CommonViewerQuery, CommonViewerQueryVariables>) {
        return Apollo.useQuery<CommonViewerQuery, CommonViewerQueryVariables>(CommonViewerDocument, baseOptions);
      }
export function useCommonViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommonViewerQuery, CommonViewerQueryVariables>) {
          return Apollo.useLazyQuery<CommonViewerQuery, CommonViewerQueryVariables>(CommonViewerDocument, baseOptions);
        }
export type CommonViewerQueryHookResult = ReturnType<typeof useCommonViewerQuery>;
export type CommonViewerLazyQueryHookResult = ReturnType<typeof useCommonViewerLazyQuery>;
export type CommonViewerQueryResult = Apollo.QueryResult<CommonViewerQuery, CommonViewerQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($id: ID!, $password: String) {
  updateUser(input: {id: $id, attributes: {password: $password}}) {
    id
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CustomersDocument = gql`
    query Customers($where: CustomerFilter) {
  customers(where: $where, orderBy: {attribute: "updatedAt", direction: "desc"}) {
    edges {
      node {
        ...CustomerTable
      }
    }
  }
}
    ${CustomerTableFragmentDoc}`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, baseOptions);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, baseOptions);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const CustomerDocument = gql`
    query Customer($id: ID!) {
  customer(id: $id) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($id: ID!, $attributes: UpdateCustomerInput!) {
  updateCustomer(input: {id: $id, attributes: $attributes}) {
    clientMutationId
    customer {
      id
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const DestroyCustomerDocument = gql`
    mutation DestroyCustomer($id: ID!) {
  destroyCustomer(input: {id: $id}) {
    customer {
      id
    }
  }
}
    `;
export type DestroyCustomerMutationFn = Apollo.MutationFunction<DestroyCustomerMutation, DestroyCustomerMutationVariables>;

/**
 * __useDestroyCustomerMutation__
 *
 * To run a mutation, you first call `useDestroyCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCustomerMutation, { data, loading, error }] = useDestroyCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DestroyCustomerMutation, DestroyCustomerMutationVariables>) {
        return Apollo.useMutation<DestroyCustomerMutation, DestroyCustomerMutationVariables>(DestroyCustomerDocument, baseOptions);
      }
export type DestroyCustomerMutationHookResult = ReturnType<typeof useDestroyCustomerMutation>;
export type DestroyCustomerMutationResult = Apollo.MutationResult<DestroyCustomerMutation>;
export type DestroyCustomerMutationOptions = Apollo.BaseMutationOptions<DestroyCustomerMutation, DestroyCustomerMutationVariables>;
export const DepositSlipsDocument = gql`
    query DepositSlips($where: DepositSlipFilter) {
  depositSlips(
    where: $where
    orderBy: {attribute: "updatedAt", direction: "desc"}
  ) {
    ...DepositSlipEdges
  }
}
    ${DepositSlipEdgesFragmentDoc}`;

/**
 * __useDepositSlipsQuery__
 *
 * To run a query within a React component, call `useDepositSlipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositSlipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositSlipsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDepositSlipsQuery(baseOptions?: Apollo.QueryHookOptions<DepositSlipsQuery, DepositSlipsQueryVariables>) {
        return Apollo.useQuery<DepositSlipsQuery, DepositSlipsQueryVariables>(DepositSlipsDocument, baseOptions);
      }
export function useDepositSlipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepositSlipsQuery, DepositSlipsQueryVariables>) {
          return Apollo.useLazyQuery<DepositSlipsQuery, DepositSlipsQueryVariables>(DepositSlipsDocument, baseOptions);
        }
export type DepositSlipsQueryHookResult = ReturnType<typeof useDepositSlipsQuery>;
export type DepositSlipsLazyQueryHookResult = ReturnType<typeof useDepositSlipsLazyQuery>;
export type DepositSlipsQueryResult = Apollo.QueryResult<DepositSlipsQuery, DepositSlipsQueryVariables>;
export const UpdateDesireDocument = gql`
    mutation UpdateDesire($input: UpdateDesireMutationInput!) {
  updateDesire(input: $input) {
    desire {
      id
    }
  }
}
    `;
export type UpdateDesireMutationFn = Apollo.MutationFunction<UpdateDesireMutation, UpdateDesireMutationVariables>;

/**
 * __useUpdateDesireMutation__
 *
 * To run a mutation, you first call `useUpdateDesireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDesireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDesireMutation, { data, loading, error }] = useUpdateDesireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDesireMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDesireMutation, UpdateDesireMutationVariables>) {
        return Apollo.useMutation<UpdateDesireMutation, UpdateDesireMutationVariables>(UpdateDesireDocument, baseOptions);
      }
export type UpdateDesireMutationHookResult = ReturnType<typeof useUpdateDesireMutation>;
export type UpdateDesireMutationResult = Apollo.MutationResult<UpdateDesireMutation>;
export type UpdateDesireMutationOptions = Apollo.BaseMutationOptions<UpdateDesireMutation, UpdateDesireMutationVariables>;
export const CreateDesireDocument = gql`
    mutation CreateDesire($input: CreateDesireMutationInput!) {
  createDesire(input: $input) {
    desire {
      id
    }
  }
}
    `;
export type CreateDesireMutationFn = Apollo.MutationFunction<CreateDesireMutation, CreateDesireMutationVariables>;

/**
 * __useCreateDesireMutation__
 *
 * To run a mutation, you first call `useCreateDesireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDesireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDesireMutation, { data, loading, error }] = useCreateDesireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDesireMutation(baseOptions?: Apollo.MutationHookOptions<CreateDesireMutation, CreateDesireMutationVariables>) {
        return Apollo.useMutation<CreateDesireMutation, CreateDesireMutationVariables>(CreateDesireDocument, baseOptions);
      }
export type CreateDesireMutationHookResult = ReturnType<typeof useCreateDesireMutation>;
export type CreateDesireMutationResult = Apollo.MutationResult<CreateDesireMutation>;
export type CreateDesireMutationOptions = Apollo.BaseMutationOptions<CreateDesireMutation, CreateDesireMutationVariables>;
export const DestroyDesireDocument = gql`
    mutation DestroyDesire($input: DestroyDesireMutationInput!) {
  destroyDesire(input: $input) {
    desire {
      id
    }
  }
}
    `;
export type DestroyDesireMutationFn = Apollo.MutationFunction<DestroyDesireMutation, DestroyDesireMutationVariables>;

/**
 * __useDestroyDesireMutation__
 *
 * To run a mutation, you first call `useDestroyDesireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyDesireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyDesireMutation, { data, loading, error }] = useDestroyDesireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyDesireMutation(baseOptions?: Apollo.MutationHookOptions<DestroyDesireMutation, DestroyDesireMutationVariables>) {
        return Apollo.useMutation<DestroyDesireMutation, DestroyDesireMutationVariables>(DestroyDesireDocument, baseOptions);
      }
export type DestroyDesireMutationHookResult = ReturnType<typeof useDestroyDesireMutation>;
export type DestroyDesireMutationResult = Apollo.MutationResult<DestroyDesireMutation>;
export type DestroyDesireMutationOptions = Apollo.BaseMutationOptions<DestroyDesireMutation, DestroyDesireMutationVariables>;
export const DesireDocument = gql`
    query Desire($id: ID!) {
  desire(id: $id) {
    ...Desire
  }
}
    ${DesireFragmentDoc}`;

/**
 * __useDesireQuery__
 *
 * To run a query within a React component, call `useDesireQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesireQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDesireQuery(baseOptions: Apollo.QueryHookOptions<DesireQuery, DesireQueryVariables>) {
        return Apollo.useQuery<DesireQuery, DesireQueryVariables>(DesireDocument, baseOptions);
      }
export function useDesireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DesireQuery, DesireQueryVariables>) {
          return Apollo.useLazyQuery<DesireQuery, DesireQueryVariables>(DesireDocument, baseOptions);
        }
export type DesireQueryHookResult = ReturnType<typeof useDesireQuery>;
export type DesireLazyQueryHookResult = ReturnType<typeof useDesireLazyQuery>;
export type DesireQueryResult = Apollo.QueryResult<DesireQuery, DesireQueryVariables>;
export const DesiresDocument = gql`
    query Desires($where: DesireFilter, $first: Int = 10) {
  desires(
    where: $where
    orderBy: {attribute: "createdAt", direction: "asc"}
    first: $first
  ) {
    ...DesireEdges
  }
}
    ${DesireEdgesFragmentDoc}`;

/**
 * __useDesiresQuery__
 *
 * To run a query within a React component, call `useDesiresQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesiresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesiresQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useDesiresQuery(baseOptions?: Apollo.QueryHookOptions<DesiresQuery, DesiresQueryVariables>) {
        return Apollo.useQuery<DesiresQuery, DesiresQueryVariables>(DesiresDocument, baseOptions);
      }
export function useDesiresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DesiresQuery, DesiresQueryVariables>) {
          return Apollo.useLazyQuery<DesiresQuery, DesiresQueryVariables>(DesiresDocument, baseOptions);
        }
export type DesiresQueryHookResult = ReturnType<typeof useDesiresQuery>;
export type DesiresLazyQueryHookResult = ReturnType<typeof useDesiresLazyQuery>;
export type DesiresQueryResult = Apollo.QueryResult<DesiresQuery, DesiresQueryVariables>;
export const UploadImageInItemDocument = gql`
    mutation UploadImageInItem($id: ID!, $images: [UploadImageInput!]) {
  updateItem(input: {id: $id, attributes: {images: $images}}) {
    clientMutationId
    item {
      title
      images {
        imageUrl
        position
      }
    }
  }
}
    `;
export type UploadImageInItemMutationFn = Apollo.MutationFunction<UploadImageInItemMutation, UploadImageInItemMutationVariables>;

/**
 * __useUploadImageInItemMutation__
 *
 * To run a mutation, you first call `useUploadImageInItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageInItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageInItemMutation, { data, loading, error }] = useUploadImageInItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      images: // value for 'images'
 *   },
 * });
 */
export function useUploadImageInItemMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageInItemMutation, UploadImageInItemMutationVariables>) {
        return Apollo.useMutation<UploadImageInItemMutation, UploadImageInItemMutationVariables>(UploadImageInItemDocument, baseOptions);
      }
export type UploadImageInItemMutationHookResult = ReturnType<typeof useUploadImageInItemMutation>;
export type UploadImageInItemMutationResult = Apollo.MutationResult<UploadImageInItemMutation>;
export type UploadImageInItemMutationOptions = Apollo.BaseMutationOptions<UploadImageInItemMutation, UploadImageInItemMutationVariables>;
export const UploadInvoiceInItemDocument = gql`
    mutation UploadInvoiceInItem($id: ID!, $files: [UploadFileInput!]) {
  updateItem(input: {id: $id, attributes: {invoices: $files}}) {
    clientMutationId
    item {
      title
      invoices {
        imageUrl
      }
    }
  }
}
    `;
export type UploadInvoiceInItemMutationFn = Apollo.MutationFunction<UploadInvoiceInItemMutation, UploadInvoiceInItemMutationVariables>;

/**
 * __useUploadInvoiceInItemMutation__
 *
 * To run a mutation, you first call `useUploadInvoiceInItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInvoiceInItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInvoiceInItemMutation, { data, loading, error }] = useUploadInvoiceInItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadInvoiceInItemMutation(baseOptions?: Apollo.MutationHookOptions<UploadInvoiceInItemMutation, UploadInvoiceInItemMutationVariables>) {
        return Apollo.useMutation<UploadInvoiceInItemMutation, UploadInvoiceInItemMutationVariables>(UploadInvoiceInItemDocument, baseOptions);
      }
export type UploadInvoiceInItemMutationHookResult = ReturnType<typeof useUploadInvoiceInItemMutation>;
export type UploadInvoiceInItemMutationResult = Apollo.MutationResult<UploadInvoiceInItemMutation>;
export type UploadInvoiceInItemMutationOptions = Apollo.BaseMutationOptions<UploadInvoiceInItemMutation, UploadInvoiceInItemMutationVariables>;
export const UploadAuthenticityDocumentInItemDocument = gql`
    mutation UploadAuthenticityDocumentInItem($id: ID!, $files: [UploadFileInput!]) {
  updateItem(input: {id: $id, attributes: {authenticityDocuments: $files}}) {
    clientMutationId
    item {
      title
      authenticityDocuments {
        imageUrl
      }
    }
  }
}
    `;
export type UploadAuthenticityDocumentInItemMutationFn = Apollo.MutationFunction<UploadAuthenticityDocumentInItemMutation, UploadAuthenticityDocumentInItemMutationVariables>;

/**
 * __useUploadAuthenticityDocumentInItemMutation__
 *
 * To run a mutation, you first call `useUploadAuthenticityDocumentInItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAuthenticityDocumentInItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAuthenticityDocumentInItemMutation, { data, loading, error }] = useUploadAuthenticityDocumentInItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadAuthenticityDocumentInItemMutation(baseOptions?: Apollo.MutationHookOptions<UploadAuthenticityDocumentInItemMutation, UploadAuthenticityDocumentInItemMutationVariables>) {
        return Apollo.useMutation<UploadAuthenticityDocumentInItemMutation, UploadAuthenticityDocumentInItemMutationVariables>(UploadAuthenticityDocumentInItemDocument, baseOptions);
      }
export type UploadAuthenticityDocumentInItemMutationHookResult = ReturnType<typeof useUploadAuthenticityDocumentInItemMutation>;
export type UploadAuthenticityDocumentInItemMutationResult = Apollo.MutationResult<UploadAuthenticityDocumentInItemMutation>;
export type UploadAuthenticityDocumentInItemMutationOptions = Apollo.BaseMutationOptions<UploadAuthenticityDocumentInItemMutation, UploadAuthenticityDocumentInItemMutationVariables>;
export const UpdateItemStepDocument = gql`
    mutation UpdateItemStep($id: ID!, $attributes: UpdateItemStepInput!) {
  updateItemStep(input: {id: $id, attributes: $attributes}) {
    clientMutationId
    itemStep {
      id
    }
  }
}
    `;
export type UpdateItemStepMutationFn = Apollo.MutationFunction<UpdateItemStepMutation, UpdateItemStepMutationVariables>;

/**
 * __useUpdateItemStepMutation__
 *
 * To run a mutation, you first call `useUpdateItemStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemStepMutation, { data, loading, error }] = useUpdateItemStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateItemStepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemStepMutation, UpdateItemStepMutationVariables>) {
        return Apollo.useMutation<UpdateItemStepMutation, UpdateItemStepMutationVariables>(UpdateItemStepDocument, baseOptions);
      }
export type UpdateItemStepMutationHookResult = ReturnType<typeof useUpdateItemStepMutation>;
export type UpdateItemStepMutationResult = Apollo.MutationResult<UpdateItemStepMutation>;
export type UpdateItemStepMutationOptions = Apollo.BaseMutationOptions<UpdateItemStepMutation, UpdateItemStepMutationVariables>;
export const ExpertPaymentDocument = gql`
    query ExpertPayment($id: ID!) {
  expertPayment(id: $id) {
    ...ExpertPayment
    expert {
      ...Expert
      account {
        ...ExpertAccount
        bankInformation {
          iban
          bic
        }
      }
    }
  }
}
    ${ExpertPaymentFragmentDoc}
${ExpertFragmentDoc}
${ExpertAccountFragmentDoc}`;

/**
 * __useExpertPaymentQuery__
 *
 * To run a query within a React component, call `useExpertPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpertPaymentQuery(baseOptions: Apollo.QueryHookOptions<ExpertPaymentQuery, ExpertPaymentQueryVariables>) {
        return Apollo.useQuery<ExpertPaymentQuery, ExpertPaymentQueryVariables>(ExpertPaymentDocument, baseOptions);
      }
export function useExpertPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertPaymentQuery, ExpertPaymentQueryVariables>) {
          return Apollo.useLazyQuery<ExpertPaymentQuery, ExpertPaymentQueryVariables>(ExpertPaymentDocument, baseOptions);
        }
export type ExpertPaymentQueryHookResult = ReturnType<typeof useExpertPaymentQuery>;
export type ExpertPaymentLazyQueryHookResult = ReturnType<typeof useExpertPaymentLazyQuery>;
export type ExpertPaymentQueryResult = Apollo.QueryResult<ExpertPaymentQuery, ExpertPaymentQueryVariables>;
export const ExpertPaymentsDocument = gql`
    query ExpertPayments($where: ExpertPaymentFilter, $first: Int = 10) {
  expertPayments(
    where: $where
    orderBy: {attribute: "createdAt", direction: "asc"}
    first: $first
  ) {
    ...ExpertPaymentEdges
  }
}
    ${ExpertPaymentEdgesFragmentDoc}`;

/**
 * __useExpertPaymentsQuery__
 *
 * To run a query within a React component, call `useExpertPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertPaymentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useExpertPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<ExpertPaymentsQuery, ExpertPaymentsQueryVariables>) {
        return Apollo.useQuery<ExpertPaymentsQuery, ExpertPaymentsQueryVariables>(ExpertPaymentsDocument, baseOptions);
      }
export function useExpertPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertPaymentsQuery, ExpertPaymentsQueryVariables>) {
          return Apollo.useLazyQuery<ExpertPaymentsQuery, ExpertPaymentsQueryVariables>(ExpertPaymentsDocument, baseOptions);
        }
export type ExpertPaymentsQueryHookResult = ReturnType<typeof useExpertPaymentsQuery>;
export type ExpertPaymentsLazyQueryHookResult = ReturnType<typeof useExpertPaymentsLazyQuery>;
export type ExpertPaymentsQueryResult = Apollo.QueryResult<ExpertPaymentsQuery, ExpertPaymentsQueryVariables>;
export const UpdateExpertPaymentDocument = gql`
    mutation UpdateExpertPayment($input: UpdateExpertPaymentMutationInput!) {
  updateExpertPayment(input: $input) {
    expertPayment {
      id
    }
  }
}
    `;
export type UpdateExpertPaymentMutationFn = Apollo.MutationFunction<UpdateExpertPaymentMutation, UpdateExpertPaymentMutationVariables>;

/**
 * __useUpdateExpertPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateExpertPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpertPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpertPaymentMutation, { data, loading, error }] = useUpdateExpertPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpertPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpertPaymentMutation, UpdateExpertPaymentMutationVariables>) {
        return Apollo.useMutation<UpdateExpertPaymentMutation, UpdateExpertPaymentMutationVariables>(UpdateExpertPaymentDocument, baseOptions);
      }
export type UpdateExpertPaymentMutationHookResult = ReturnType<typeof useUpdateExpertPaymentMutation>;
export type UpdateExpertPaymentMutationResult = Apollo.MutationResult<UpdateExpertPaymentMutation>;
export type UpdateExpertPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateExpertPaymentMutation, UpdateExpertPaymentMutationVariables>;
export const ArtistDocument = gql`
    query Artist($where: ArtistFilter) {
  artists(where: $where) {
    edges {
      node {
        id
        firstName
        lastName
        tag
        itemCategoryId
      }
    }
  }
}
    `;

/**
 * __useArtistQuery__
 *
 * To run a query within a React component, call `useArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useArtistQuery(baseOptions?: Apollo.QueryHookOptions<ArtistQuery, ArtistQueryVariables>) {
        return Apollo.useQuery<ArtistQuery, ArtistQueryVariables>(ArtistDocument, baseOptions);
      }
export function useArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArtistQuery, ArtistQueryVariables>) {
          return Apollo.useLazyQuery<ArtistQuery, ArtistQueryVariables>(ArtistDocument, baseOptions);
        }
export type ArtistQueryHookResult = ReturnType<typeof useArtistQuery>;
export type ArtistLazyQueryHookResult = ReturnType<typeof useArtistLazyQuery>;
export type ArtistQueryResult = Apollo.QueryResult<ArtistQuery, ArtistQueryVariables>;
export const UpdateItemArtistsDocument = gql`
    mutation UpdateItemArtists($id: ID!, $attributes: UpdateItemInput!) {
  updateItem(input: {id: $id, attributes: $attributes}) {
    clientMutationId
    item {
      title
      artists {
        edges {
          node {
            id
            firstName
            lastName
            tag
            itemCategoryId
          }
        }
      }
    }
  }
}
    `;
export type UpdateItemArtistsMutationFn = Apollo.MutationFunction<UpdateItemArtistsMutation, UpdateItemArtistsMutationVariables>;

/**
 * __useUpdateItemArtistsMutation__
 *
 * To run a mutation, you first call `useUpdateItemArtistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemArtistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemArtistsMutation, { data, loading, error }] = useUpdateItemArtistsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateItemArtistsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemArtistsMutation, UpdateItemArtistsMutationVariables>) {
        return Apollo.useMutation<UpdateItemArtistsMutation, UpdateItemArtistsMutationVariables>(UpdateItemArtistsDocument, baseOptions);
      }
export type UpdateItemArtistsMutationHookResult = ReturnType<typeof useUpdateItemArtistsMutation>;
export type UpdateItemArtistsMutationResult = Apollo.MutationResult<UpdateItemArtistsMutation>;
export type UpdateItemArtistsMutationOptions = Apollo.BaseMutationOptions<UpdateItemArtistsMutation, UpdateItemArtistsMutationVariables>;
export const ItemCategoriesDocument = gql`
    query ItemCategories {
  itemCategories {
    edges {
      node {
        id
        name
        templateType
      }
    }
  }
}
    `;

/**
 * __useItemCategoriesQuery__
 *
 * To run a query within a React component, call `useItemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ItemCategoriesQuery, ItemCategoriesQueryVariables>) {
        return Apollo.useQuery<ItemCategoriesQuery, ItemCategoriesQueryVariables>(ItemCategoriesDocument, baseOptions);
      }
export function useItemCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemCategoriesQuery, ItemCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<ItemCategoriesQuery, ItemCategoriesQueryVariables>(ItemCategoriesDocument, baseOptions);
        }
export type ItemCategoriesQueryHookResult = ReturnType<typeof useItemCategoriesQuery>;
export type ItemCategoriesLazyQueryHookResult = ReturnType<typeof useItemCategoriesLazyQuery>;
export type ItemCategoriesQueryResult = Apollo.QueryResult<ItemCategoriesQuery, ItemCategoriesQueryVariables>;
export const ItemSubCategoriesDocument = gql`
    query ItemSubCategories($where: ItemSubCategoryFilter) {
  itemSubCategories(where: $where) {
    ...ItemSubCategoryEdges
  }
}
    ${ItemSubCategoryEdgesFragmentDoc}`;

/**
 * __useItemSubCategoriesQuery__
 *
 * To run a query within a React component, call `useItemSubCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemSubCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemSubCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useItemSubCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ItemSubCategoriesQuery, ItemSubCategoriesQueryVariables>) {
        return Apollo.useQuery<ItemSubCategoriesQuery, ItemSubCategoriesQueryVariables>(ItemSubCategoriesDocument, baseOptions);
      }
export function useItemSubCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemSubCategoriesQuery, ItemSubCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<ItemSubCategoriesQuery, ItemSubCategoriesQueryVariables>(ItemSubCategoriesDocument, baseOptions);
        }
export type ItemSubCategoriesQueryHookResult = ReturnType<typeof useItemSubCategoriesQuery>;
export type ItemSubCategoriesLazyQueryHookResult = ReturnType<typeof useItemSubCategoriesLazyQuery>;
export type ItemSubCategoriesQueryResult = Apollo.QueryResult<ItemSubCategoriesQuery, ItemSubCategoriesQueryVariables>;
export const CreateBookDocument = gql`
    mutation CreateBook($input: CreateBookMutationInput!) {
  createBook(input: $input) {
    book {
      id
      item {
        id
        title
        description
        itemCategory {
          id
          name
        }
        itemSubCategory {
          id
          name
        }
      }
    }
  }
}
    `;
export type CreateBookMutationFn = Apollo.MutationFunction<CreateBookMutation, CreateBookMutationVariables>;

/**
 * __useCreateBookMutation__
 *
 * To run a mutation, you first call `useCreateBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookMutation, { data, loading, error }] = useCreateBookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookMutation, CreateBookMutationVariables>) {
        return Apollo.useMutation<CreateBookMutation, CreateBookMutationVariables>(CreateBookDocument, baseOptions);
      }
export type CreateBookMutationHookResult = ReturnType<typeof useCreateBookMutation>;
export type CreateBookMutationResult = Apollo.MutationResult<CreateBookMutation>;
export type CreateBookMutationOptions = Apollo.BaseMutationOptions<CreateBookMutation, CreateBookMutationVariables>;
export const UpdateVolumeDocument = gql`
    mutation UpdateVolume($attributes: UpdateVolumeInput!) {
  updateVolumes(input: {attributes: $attributes}) {
    clientMutationId
    errors {
      details
      fullMessages
    }
  }
}
    `;
export type UpdateVolumeMutationFn = Apollo.MutationFunction<UpdateVolumeMutation, UpdateVolumeMutationVariables>;

/**
 * __useUpdateVolumeMutation__
 *
 * To run a mutation, you first call `useUpdateVolumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVolumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVolumeMutation, { data, loading, error }] = useUpdateVolumeMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateVolumeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVolumeMutation, UpdateVolumeMutationVariables>) {
        return Apollo.useMutation<UpdateVolumeMutation, UpdateVolumeMutationVariables>(UpdateVolumeDocument, baseOptions);
      }
export type UpdateVolumeMutationHookResult = ReturnType<typeof useUpdateVolumeMutation>;
export type UpdateVolumeMutationResult = Apollo.MutationResult<UpdateVolumeMutation>;
export type UpdateVolumeMutationOptions = Apollo.BaseMutationOptions<UpdateVolumeMutation, UpdateVolumeMutationVariables>;
export const GetItemDocument = gql`
    query GetItem($id: ID!) {
  item(id: $id) {
    ...Item
  }
}
    ${ItemFragmentDoc}`;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions: Apollo.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        return Apollo.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
      }
export function useGetItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          return Apollo.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = Apollo.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const GetBookDocument = gql`
    query GetBook($id: ID!) {
  book(id: $id) {
    ...Book
  }
}
    ${BookFragmentDoc}`;

/**
 * __useGetBookQuery__
 *
 * To run a query within a React component, call `useGetBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookQuery(baseOptions: Apollo.QueryHookOptions<GetBookQuery, GetBookQueryVariables>) {
        return Apollo.useQuery<GetBookQuery, GetBookQueryVariables>(GetBookDocument, baseOptions);
      }
export function useGetBookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookQuery, GetBookQueryVariables>) {
          return Apollo.useLazyQuery<GetBookQuery, GetBookQueryVariables>(GetBookDocument, baseOptions);
        }
export type GetBookQueryHookResult = ReturnType<typeof useGetBookQuery>;
export type GetBookLazyQueryHookResult = ReturnType<typeof useGetBookLazyQuery>;
export type GetBookQueryResult = Apollo.QueryResult<GetBookQuery, GetBookQueryVariables>;
export const UpdateItemDocument = gql`
    mutation UpdateItem($id: ID!, $attributes: UpdateItemInput!) {
  updateItem(input: {id: $id, attributes: $attributes}) {
    clientMutationId
    item {
      id
    }
  }
}
    `;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, baseOptions);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const DuplicateItemDocument = gql`
    mutation DuplicateItem($id: ID!) {
  duplicateItem(input: {id: $id}) {
    clientMutationId
    item {
      id
    }
  }
}
    `;
export type DuplicateItemMutationFn = Apollo.MutationFunction<DuplicateItemMutation, DuplicateItemMutationVariables>;

/**
 * __useDuplicateItemMutation__
 *
 * To run a mutation, you first call `useDuplicateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateItemMutation, { data, loading, error }] = useDuplicateItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateItemMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateItemMutation, DuplicateItemMutationVariables>) {
        return Apollo.useMutation<DuplicateItemMutation, DuplicateItemMutationVariables>(DuplicateItemDocument, baseOptions);
      }
export type DuplicateItemMutationHookResult = ReturnType<typeof useDuplicateItemMutation>;
export type DuplicateItemMutationResult = Apollo.MutationResult<DuplicateItemMutation>;
export type DuplicateItemMutationOptions = Apollo.BaseMutationOptions<DuplicateItemMutation, DuplicateItemMutationVariables>;
export const DestroyItemDocument = gql`
    mutation DestroyItem($id: ID!) {
  destroyItem(input: {id: $id}) {
    item {
      id
    }
  }
}
    `;
export type DestroyItemMutationFn = Apollo.MutationFunction<DestroyItemMutation, DestroyItemMutationVariables>;

/**
 * __useDestroyItemMutation__
 *
 * To run a mutation, you first call `useDestroyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyItemMutation, { data, loading, error }] = useDestroyItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyItemMutation(baseOptions?: Apollo.MutationHookOptions<DestroyItemMutation, DestroyItemMutationVariables>) {
        return Apollo.useMutation<DestroyItemMutation, DestroyItemMutationVariables>(DestroyItemDocument, baseOptions);
      }
export type DestroyItemMutationHookResult = ReturnType<typeof useDestroyItemMutation>;
export type DestroyItemMutationResult = Apollo.MutationResult<DestroyItemMutation>;
export type DestroyItemMutationOptions = Apollo.BaseMutationOptions<DestroyItemMutation, DestroyItemMutationVariables>;
export const UpdateBookDocument = gql`
    mutation UpdateBook($id: ID!, $attributes: UpdateBookInput!) {
  updateBook(input: {id: $id, attributes: $attributes}) {
    book {
      id
    }
  }
}
    `;
export type UpdateBookMutationFn = Apollo.MutationFunction<UpdateBookMutation, UpdateBookMutationVariables>;

/**
 * __useUpdateBookMutation__
 *
 * To run a mutation, you first call `useUpdateBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookMutation, { data, loading, error }] = useUpdateBookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateBookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookMutation, UpdateBookMutationVariables>) {
        return Apollo.useMutation<UpdateBookMutation, UpdateBookMutationVariables>(UpdateBookDocument, baseOptions);
      }
export type UpdateBookMutationHookResult = ReturnType<typeof useUpdateBookMutation>;
export type UpdateBookMutationResult = Apollo.MutationResult<UpdateBookMutation>;
export type UpdateBookMutationOptions = Apollo.BaseMutationOptions<UpdateBookMutation, UpdateBookMutationVariables>;
export const ItemsQueryDocument = gql`
    query ItemsQuery($where: ItemFilter, $orderByAttribute: [ItemOrder!] = [{attribute: "createdAt", direction: "desc"}], $first: Int, $last: Int = null, $after: String = null, $before: String = null) {
  items(
    where: $where
    orderBy: $orderByAttribute
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    edges {
      cursor
      node {
        ...ItemTable
      }
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    ${ItemTableFragmentDoc}`;

/**
 * __useItemsQueryQuery__
 *
 * To run a query within a React component, call `useItemsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderByAttribute: // value for 'orderByAttribute'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useItemsQueryQuery(baseOptions?: Apollo.QueryHookOptions<ItemsQueryQuery, ItemsQueryQueryVariables>) {
        return Apollo.useQuery<ItemsQueryQuery, ItemsQueryQueryVariables>(ItemsQueryDocument, baseOptions);
      }
export function useItemsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsQueryQuery, ItemsQueryQueryVariables>) {
          return Apollo.useLazyQuery<ItemsQueryQuery, ItemsQueryQueryVariables>(ItemsQueryDocument, baseOptions);
        }
export type ItemsQueryQueryHookResult = ReturnType<typeof useItemsQueryQuery>;
export type ItemsQueryLazyQueryHookResult = ReturnType<typeof useItemsQueryLazyQuery>;
export type ItemsQueryQueryResult = Apollo.QueryResult<ItemsQueryQuery, ItemsQueryQueryVariables>;
export const ItemsWithImagesQueryDocument = gql`
    query ItemsWithImagesQuery($where: ItemFilter, $orderByAttribute: [ItemOrder!] = [{attribute: "createdAt", direction: "desc"}], $first: Int, $last: Int = null, $after: String = null, $before: String = null) {
  items(
    where: $where
    orderBy: $orderByAttribute
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    edges {
      cursor
      node {
        ...ItemTable
        ...Item
      }
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    ${ItemTableFragmentDoc}
${ItemFragmentDoc}`;

/**
 * __useItemsWithImagesQueryQuery__
 *
 * To run a query within a React component, call `useItemsWithImagesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsWithImagesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsWithImagesQueryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderByAttribute: // value for 'orderByAttribute'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useItemsWithImagesQueryQuery(baseOptions?: Apollo.QueryHookOptions<ItemsWithImagesQueryQuery, ItemsWithImagesQueryQueryVariables>) {
        return Apollo.useQuery<ItemsWithImagesQueryQuery, ItemsWithImagesQueryQueryVariables>(ItemsWithImagesQueryDocument, baseOptions);
      }
export function useItemsWithImagesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsWithImagesQueryQuery, ItemsWithImagesQueryQueryVariables>) {
          return Apollo.useLazyQuery<ItemsWithImagesQueryQuery, ItemsWithImagesQueryQueryVariables>(ItemsWithImagesQueryDocument, baseOptions);
        }
export type ItemsWithImagesQueryQueryHookResult = ReturnType<typeof useItemsWithImagesQueryQuery>;
export type ItemsWithImagesQueryLazyQueryHookResult = ReturnType<typeof useItemsWithImagesQueryLazyQuery>;
export type ItemsWithImagesQueryQueryResult = Apollo.QueryResult<ItemsWithImagesQueryQuery, ItemsWithImagesQueryQueryVariables>;
export const ExpertsDocument = gql`
    query Experts {
  experts(orderBy: {attribute: "createdAt", direction: "desc"}) {
    ...ExpertEdges
  }
}
    ${ExpertEdgesFragmentDoc}`;

/**
 * __useExpertsQuery__
 *
 * To run a query within a React component, call `useExpertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpertsQuery(baseOptions?: Apollo.QueryHookOptions<ExpertsQuery, ExpertsQueryVariables>) {
        return Apollo.useQuery<ExpertsQuery, ExpertsQueryVariables>(ExpertsDocument, baseOptions);
      }
export function useExpertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertsQuery, ExpertsQueryVariables>) {
          return Apollo.useLazyQuery<ExpertsQuery, ExpertsQueryVariables>(ExpertsDocument, baseOptions);
        }
export type ExpertsQueryHookResult = ReturnType<typeof useExpertsQuery>;
export type ExpertsLazyQueryHookResult = ReturnType<typeof useExpertsLazyQuery>;
export type ExpertsQueryResult = Apollo.QueryResult<ExpertsQuery, ExpertsQueryVariables>;
export const NewsLettersDocument = gql`
    query NewsLetters($where: NewsLetterFilter, $direction: String = "asc", $first: Int = 50, $last: Int = null, $after: String = null, $before: String = null) {
  newsLetters(
    where: $where
    orderBy: {attribute: "createdAt", direction: $direction}
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    ...NewsLetterEdges
  }
}
    ${NewsLetterEdgesFragmentDoc}`;

/**
 * __useNewsLettersQuery__
 *
 * To run a query within a React component, call `useNewsLettersQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsLettersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsLettersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      direction: // value for 'direction'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useNewsLettersQuery(baseOptions?: Apollo.QueryHookOptions<NewsLettersQuery, NewsLettersQueryVariables>) {
        return Apollo.useQuery<NewsLettersQuery, NewsLettersQueryVariables>(NewsLettersDocument, baseOptions);
      }
export function useNewsLettersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsLettersQuery, NewsLettersQueryVariables>) {
          return Apollo.useLazyQuery<NewsLettersQuery, NewsLettersQueryVariables>(NewsLettersDocument, baseOptions);
        }
export type NewsLettersQueryHookResult = ReturnType<typeof useNewsLettersQuery>;
export type NewsLettersLazyQueryHookResult = ReturnType<typeof useNewsLettersLazyQuery>;
export type NewsLettersQueryResult = Apollo.QueryResult<NewsLettersQuery, NewsLettersQueryVariables>;
export const CreateNewsLetterDocument = gql`
    mutation CreateNewsLetter($input: CreateNewsLetterMutationInput!) {
  createNewsLetter(input: $input) {
    sale {
      id
      name
    }
  }
}
    `;
export type CreateNewsLetterMutationFn = Apollo.MutationFunction<CreateNewsLetterMutation, CreateNewsLetterMutationVariables>;

/**
 * __useCreateNewsLetterMutation__
 *
 * To run a mutation, you first call `useCreateNewsLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsLetterMutation, { data, loading, error }] = useCreateNewsLetterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewsLetterMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewsLetterMutation, CreateNewsLetterMutationVariables>) {
        return Apollo.useMutation<CreateNewsLetterMutation, CreateNewsLetterMutationVariables>(CreateNewsLetterDocument, baseOptions);
      }
export type CreateNewsLetterMutationHookResult = ReturnType<typeof useCreateNewsLetterMutation>;
export type CreateNewsLetterMutationResult = Apollo.MutationResult<CreateNewsLetterMutation>;
export type CreateNewsLetterMutationOptions = Apollo.BaseMutationOptions<CreateNewsLetterMutation, CreateNewsLetterMutationVariables>;
export const OrdersDocument = gql`
    query Orders($where: OrderFilter) {
  orders(where: $where, orderBy: {attribute: "updatedAt", direction: "desc"}) {
    edges {
      node {
        ...OrderTable
      }
    }
  }
}
    ${OrderTableFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderDocument = gql`
    query Order($id: ID!) {
  order(id: $id) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const UpdateOrderItemDocument = gql`
    mutation UpdateOrderItem($input: UpdateOrderItemMutationInput!) {
  updateOrderItem(input: $input) {
    orderItem {
      id
    }
  }
}
    `;
export type UpdateOrderItemMutationFn = Apollo.MutationFunction<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>;

/**
 * __useUpdateOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemMutation, { data, loading, error }] = useUpdateOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>) {
        return Apollo.useMutation<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>(UpdateOrderItemDocument, baseOptions);
      }
export type UpdateOrderItemMutationHookResult = ReturnType<typeof useUpdateOrderItemMutation>;
export type UpdateOrderItemMutationResult = Apollo.MutationResult<UpdateOrderItemMutation>;
export type UpdateOrderItemMutationOptions = Apollo.BaseMutationOptions<UpdateOrderItemMutation, UpdateOrderItemMutationVariables>;
export const PaidSlipsDocument = gql`
    query PaidSlips($where: PaidSlipFilter) {
  paidSlips(where: $where, orderBy: {attribute: "updatedAt", direction: "desc"}) {
    ...PaidSlipEdges
  }
}
    ${PaidSlipEdgesFragmentDoc}`;

/**
 * __usePaidSlipsQuery__
 *
 * To run a query within a React component, call `usePaidSlipsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaidSlipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaidSlipsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePaidSlipsQuery(baseOptions?: Apollo.QueryHookOptions<PaidSlipsQuery, PaidSlipsQueryVariables>) {
        return Apollo.useQuery<PaidSlipsQuery, PaidSlipsQueryVariables>(PaidSlipsDocument, baseOptions);
      }
export function usePaidSlipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaidSlipsQuery, PaidSlipsQueryVariables>) {
          return Apollo.useLazyQuery<PaidSlipsQuery, PaidSlipsQueryVariables>(PaidSlipsDocument, baseOptions);
        }
export type PaidSlipsQueryHookResult = ReturnType<typeof usePaidSlipsQuery>;
export type PaidSlipsLazyQueryHookResult = ReturnType<typeof usePaidSlipsLazyQuery>;
export type PaidSlipsQueryResult = Apollo.QueryResult<PaidSlipsQuery, PaidSlipsQueryVariables>;
export const UpdateUserPersonalInformationsDocument = gql`
    mutation updateUserPersonalInformations($id: ID!, $firstName: String, $lastName: String) {
  updateUser(
    input: {id: $id, attributes: {firstName: $firstName, lastName: $lastName}}
  ) {
    id
    firstName
    lastName
  }
}
    `;
export type UpdateUserPersonalInformationsMutationFn = Apollo.MutationFunction<UpdateUserPersonalInformationsMutation, UpdateUserPersonalInformationsMutationVariables>;

/**
 * __useUpdateUserPersonalInformationsMutation__
 *
 * To run a mutation, you first call `useUpdateUserPersonalInformationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPersonalInformationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPersonalInformationsMutation, { data, loading, error }] = useUpdateUserPersonalInformationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateUserPersonalInformationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPersonalInformationsMutation, UpdateUserPersonalInformationsMutationVariables>) {
        return Apollo.useMutation<UpdateUserPersonalInformationsMutation, UpdateUserPersonalInformationsMutationVariables>(UpdateUserPersonalInformationsDocument, baseOptions);
      }
export type UpdateUserPersonalInformationsMutationHookResult = ReturnType<typeof useUpdateUserPersonalInformationsMutation>;
export type UpdateUserPersonalInformationsMutationResult = Apollo.MutationResult<UpdateUserPersonalInformationsMutation>;
export type UpdateUserPersonalInformationsMutationOptions = Apollo.BaseMutationOptions<UpdateUserPersonalInformationsMutation, UpdateUserPersonalInformationsMutationVariables>;
export const CreatePickupRequestDocument = gql`
    mutation CreatePickupRequest($input: CreatePickupRequestMutationInput!) {
  createPickupRequest(input: $input) {
    pickupRequest {
      id
    }
  }
}
    `;
export type CreatePickupRequestMutationFn = Apollo.MutationFunction<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>;

/**
 * __useCreatePickupRequestMutation__
 *
 * To run a mutation, you first call `useCreatePickupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePickupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPickupRequestMutation, { data, loading, error }] = useCreatePickupRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePickupRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>) {
        return Apollo.useMutation<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>(CreatePickupRequestDocument, baseOptions);
      }
export type CreatePickupRequestMutationHookResult = ReturnType<typeof useCreatePickupRequestMutation>;
export type CreatePickupRequestMutationResult = Apollo.MutationResult<CreatePickupRequestMutation>;
export type CreatePickupRequestMutationOptions = Apollo.BaseMutationOptions<CreatePickupRequestMutation, CreatePickupRequestMutationVariables>;
export const PickupRequestDocument = gql`
    query PickupRequest($id: ID!) {
  pickupRequest(id: $id) {
    ...PickupRequest
  }
}
    ${PickupRequestFragmentDoc}`;

/**
 * __usePickupRequestQuery__
 *
 * To run a query within a React component, call `usePickupRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePickupRequestQuery(baseOptions: Apollo.QueryHookOptions<PickupRequestQuery, PickupRequestQueryVariables>) {
        return Apollo.useQuery<PickupRequestQuery, PickupRequestQueryVariables>(PickupRequestDocument, baseOptions);
      }
export function usePickupRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupRequestQuery, PickupRequestQueryVariables>) {
          return Apollo.useLazyQuery<PickupRequestQuery, PickupRequestQueryVariables>(PickupRequestDocument, baseOptions);
        }
export type PickupRequestQueryHookResult = ReturnType<typeof usePickupRequestQuery>;
export type PickupRequestLazyQueryHookResult = ReturnType<typeof usePickupRequestLazyQuery>;
export type PickupRequestQueryResult = Apollo.QueryResult<PickupRequestQuery, PickupRequestQueryVariables>;
export const PickupRequestsDocument = gql`
    query PickupRequests($where: PickupRequestFilter, $first: Int = 10) {
  pickupRequests(
    where: $where
    orderBy: {attribute: "createdAt", direction: "asc"}
    first: $first
  ) {
    ...PickupRequestEdges
  }
}
    ${PickupRequestEdgesFragmentDoc}`;

/**
 * __usePickupRequestsQuery__
 *
 * To run a query within a React component, call `usePickupRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupRequestsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePickupRequestsQuery(baseOptions?: Apollo.QueryHookOptions<PickupRequestsQuery, PickupRequestsQueryVariables>) {
        return Apollo.useQuery<PickupRequestsQuery, PickupRequestsQueryVariables>(PickupRequestsDocument, baseOptions);
      }
export function usePickupRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupRequestsQuery, PickupRequestsQueryVariables>) {
          return Apollo.useLazyQuery<PickupRequestsQuery, PickupRequestsQueryVariables>(PickupRequestsDocument, baseOptions);
        }
export type PickupRequestsQueryHookResult = ReturnType<typeof usePickupRequestsQuery>;
export type PickupRequestsLazyQueryHookResult = ReturnType<typeof usePickupRequestsLazyQuery>;
export type PickupRequestsQueryResult = Apollo.QueryResult<PickupRequestsQuery, PickupRequestsQueryVariables>;
export const GetAddressesProfileDocument = gql`
    query GetAddressesProfile($where: AddressFilter) {
  addresses(where: $where) {
    edges {
      node {
        ...AddressProfile
      }
    }
  }
}
    ${AddressProfileFragmentDoc}`;

/**
 * __useGetAddressesProfileQuery__
 *
 * To run a query within a React component, call `useGetAddressesProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesProfileQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAddressesProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetAddressesProfileQuery, GetAddressesProfileQueryVariables>) {
        return Apollo.useQuery<GetAddressesProfileQuery, GetAddressesProfileQueryVariables>(GetAddressesProfileDocument, baseOptions);
      }
export function useGetAddressesProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressesProfileQuery, GetAddressesProfileQueryVariables>) {
          return Apollo.useLazyQuery<GetAddressesProfileQuery, GetAddressesProfileQueryVariables>(GetAddressesProfileDocument, baseOptions);
        }
export type GetAddressesProfileQueryHookResult = ReturnType<typeof useGetAddressesProfileQuery>;
export type GetAddressesProfileLazyQueryHookResult = ReturnType<typeof useGetAddressesProfileLazyQuery>;
export type GetAddressesProfileQueryResult = Apollo.QueryResult<GetAddressesProfileQuery, GetAddressesProfileQueryVariables>;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($id: ID!, $attributes: UpdateAddressInput!) {
  updateAddress(input: {id: $id, attributes: $attributes}) {
    clientMutationId
    address {
      id
    }
  }
}
    `;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, baseOptions);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const GetBankInformationProfileDocument = gql`
    query GetBankInformationProfile($id: ID!) {
  bankInformation(id: $id) {
    ...BankInformationProfile
  }
}
    ${BankInformationProfileFragmentDoc}`;

/**
 * __useGetBankInformationProfileQuery__
 *
 * To run a query within a React component, call `useGetBankInformationProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankInformationProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankInformationProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBankInformationProfileQuery(baseOptions: Apollo.QueryHookOptions<GetBankInformationProfileQuery, GetBankInformationProfileQueryVariables>) {
        return Apollo.useQuery<GetBankInformationProfileQuery, GetBankInformationProfileQueryVariables>(GetBankInformationProfileDocument, baseOptions);
      }
export function useGetBankInformationProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankInformationProfileQuery, GetBankInformationProfileQueryVariables>) {
          return Apollo.useLazyQuery<GetBankInformationProfileQuery, GetBankInformationProfileQueryVariables>(GetBankInformationProfileDocument, baseOptions);
        }
export type GetBankInformationProfileQueryHookResult = ReturnType<typeof useGetBankInformationProfileQuery>;
export type GetBankInformationProfileLazyQueryHookResult = ReturnType<typeof useGetBankInformationProfileLazyQuery>;
export type GetBankInformationProfileQueryResult = Apollo.QueryResult<GetBankInformationProfileQuery, GetBankInformationProfileQueryVariables>;
export const UpdateBankInformationDocument = gql`
    mutation UpdateBankInformation($id: ID!, $attributes: UpdateBankInformationInput!) {
  updateBankInformation(input: {id: $id, attributes: $attributes}) {
    clientMutationId
    bankInformation {
      id
    }
  }
}
    `;
export type UpdateBankInformationMutationFn = Apollo.MutationFunction<UpdateBankInformationMutation, UpdateBankInformationMutationVariables>;

/**
 * __useUpdateBankInformationMutation__
 *
 * To run a mutation, you first call `useUpdateBankInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankInformationMutation, { data, loading, error }] = useUpdateBankInformationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateBankInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBankInformationMutation, UpdateBankInformationMutationVariables>) {
        return Apollo.useMutation<UpdateBankInformationMutation, UpdateBankInformationMutationVariables>(UpdateBankInformationDocument, baseOptions);
      }
export type UpdateBankInformationMutationHookResult = ReturnType<typeof useUpdateBankInformationMutation>;
export type UpdateBankInformationMutationResult = Apollo.MutationResult<UpdateBankInformationMutation>;
export type UpdateBankInformationMutationOptions = Apollo.BaseMutationOptions<UpdateBankInformationMutation, UpdateBankInformationMutationVariables>;
export const GetAccountProfileDocument = gql`
    query GetAccountProfile($id: ID!) {
  account(id: $id) {
    ...AccountProfile
  }
}
    ${AccountProfileFragmentDoc}`;

/**
 * __useGetAccountProfileQuery__
 *
 * To run a query within a React component, call `useGetAccountProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountProfileQuery(baseOptions: Apollo.QueryHookOptions<GetAccountProfileQuery, GetAccountProfileQueryVariables>) {
        return Apollo.useQuery<GetAccountProfileQuery, GetAccountProfileQueryVariables>(GetAccountProfileDocument, baseOptions);
      }
export function useGetAccountProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountProfileQuery, GetAccountProfileQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountProfileQuery, GetAccountProfileQueryVariables>(GetAccountProfileDocument, baseOptions);
        }
export type GetAccountProfileQueryHookResult = ReturnType<typeof useGetAccountProfileQuery>;
export type GetAccountProfileLazyQueryHookResult = ReturnType<typeof useGetAccountProfileLazyQuery>;
export type GetAccountProfileQueryResult = Apollo.QueryResult<GetAccountProfileQuery, GetAccountProfileQueryVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($id: ID!, $attributes: UpdateAccountInput!) {
  customUpdateAccount(input: {id: $id, attributes: $attributes}) {
    clientMutationId
    account {
      id
    }
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const UploadIdentityDocumentInAccountDocument = gql`
    mutation UploadIdentityDocumentInAccount($id: ID!, $files: [UploadFileInput!]) {
  customUpdateAccount(input: {id: $id, attributes: {identityDocuments: $files}}) {
    clientMutationId
    account {
      id
      identityDocuments {
        imageUrl
      }
    }
  }
}
    `;
export type UploadIdentityDocumentInAccountMutationFn = Apollo.MutationFunction<UploadIdentityDocumentInAccountMutation, UploadIdentityDocumentInAccountMutationVariables>;

/**
 * __useUploadIdentityDocumentInAccountMutation__
 *
 * To run a mutation, you first call `useUploadIdentityDocumentInAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadIdentityDocumentInAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadIdentityDocumentInAccountMutation, { data, loading, error }] = useUploadIdentityDocumentInAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadIdentityDocumentInAccountMutation(baseOptions?: Apollo.MutationHookOptions<UploadIdentityDocumentInAccountMutation, UploadIdentityDocumentInAccountMutationVariables>) {
        return Apollo.useMutation<UploadIdentityDocumentInAccountMutation, UploadIdentityDocumentInAccountMutationVariables>(UploadIdentityDocumentInAccountDocument, baseOptions);
      }
export type UploadIdentityDocumentInAccountMutationHookResult = ReturnType<typeof useUploadIdentityDocumentInAccountMutation>;
export type UploadIdentityDocumentInAccountMutationResult = Apollo.MutationResult<UploadIdentityDocumentInAccountMutation>;
export type UploadIdentityDocumentInAccountMutationOptions = Apollo.BaseMutationOptions<UploadIdentityDocumentInAccountMutation, UploadIdentityDocumentInAccountMutationVariables>;
export const UploadRibDocumentInAccountDocument = gql`
    mutation UploadRibDocumentInAccount($id: ID!, $files: [UploadFileInput!]) {
  customUpdateAccount(input: {id: $id, attributes: {ribDocuments: $files}}) {
    clientMutationId
    account {
      id
      identityDocuments {
        imageUrl
      }
    }
  }
}
    `;
export type UploadRibDocumentInAccountMutationFn = Apollo.MutationFunction<UploadRibDocumentInAccountMutation, UploadRibDocumentInAccountMutationVariables>;

/**
 * __useUploadRibDocumentInAccountMutation__
 *
 * To run a mutation, you first call `useUploadRibDocumentInAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadRibDocumentInAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadRibDocumentInAccountMutation, { data, loading, error }] = useUploadRibDocumentInAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadRibDocumentInAccountMutation(baseOptions?: Apollo.MutationHookOptions<UploadRibDocumentInAccountMutation, UploadRibDocumentInAccountMutationVariables>) {
        return Apollo.useMutation<UploadRibDocumentInAccountMutation, UploadRibDocumentInAccountMutationVariables>(UploadRibDocumentInAccountDocument, baseOptions);
      }
export type UploadRibDocumentInAccountMutationHookResult = ReturnType<typeof useUploadRibDocumentInAccountMutation>;
export type UploadRibDocumentInAccountMutationResult = Apollo.MutationResult<UploadRibDocumentInAccountMutation>;
export type UploadRibDocumentInAccountMutationOptions = Apollo.BaseMutationOptions<UploadRibDocumentInAccountMutation, UploadRibDocumentInAccountMutationVariables>;
export const ProjectDocument = gql`
    query Project($id: ID!) {
  project(id: $id) {
    ...Project
    vendor {
      ...Vendor
      account {
        ...VendorAccount
        bankInformation {
          iban
          bic
        }
      }
    }
  }
}
    ${ProjectFragmentDoc}
${VendorFragmentDoc}
${VendorAccountFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, baseOptions);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectsDocument = gql`
    query Projects($where: ProjectFilter, $first: Int = 10) {
  projects(
    where: $where
    orderBy: {attribute: "createdAt", direction: "asc"}
    first: $first
  ) {
    ...ProjectEdges
  }
}
    ${ProjectEdgesFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, baseOptions);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($input: UpdateProjectMutationInput!) {
  updateProject(input: $input) {
    project {
      id
    }
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($input: CreateProjectMutationInput!) {
  createProject(input: $input) {
    project {
      id
    }
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const VendorsDocument = gql`
    query Vendors($where: VendorFilter, $first: Int = 50) {
  vendors(
    where: $where
    orderBy: {attribute: "createdAt", direction: "asc"}
    first: $first
  ) {
    ...VendorEdges
  }
}
    ${VendorEdgesFragmentDoc}`;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useVendorsQuery(baseOptions?: Apollo.QueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
        return Apollo.useQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, baseOptions);
      }
export function useVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
          return Apollo.useLazyQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, baseOptions);
        }
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = Apollo.QueryResult<VendorsQuery, VendorsQueryVariables>;
export const PurchaseSlipsDocument = gql`
    query PurchaseSlips($where: PurchaseSlipFilter) {
  purchaseSlips(
    where: $where
    orderBy: {attribute: "updatedAt", direction: "desc"}
  ) {
    ...PurchaseSlipEdges
  }
}
    ${PurchaseSlipEdgesFragmentDoc}`;

/**
 * __usePurchaseSlipsQuery__
 *
 * To run a query within a React component, call `usePurchaseSlipsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseSlipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseSlipsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePurchaseSlipsQuery(baseOptions?: Apollo.QueryHookOptions<PurchaseSlipsQuery, PurchaseSlipsQueryVariables>) {
        return Apollo.useQuery<PurchaseSlipsQuery, PurchaseSlipsQueryVariables>(PurchaseSlipsDocument, baseOptions);
      }
export function usePurchaseSlipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseSlipsQuery, PurchaseSlipsQueryVariables>) {
          return Apollo.useLazyQuery<PurchaseSlipsQuery, PurchaseSlipsQueryVariables>(PurchaseSlipsDocument, baseOptions);
        }
export type PurchaseSlipsQueryHookResult = ReturnType<typeof usePurchaseSlipsQuery>;
export type PurchaseSlipsLazyQueryHookResult = ReturnType<typeof usePurchaseSlipsLazyQuery>;
export type PurchaseSlipsQueryResult = Apollo.QueryResult<PurchaseSlipsQuery, PurchaseSlipsQueryVariables>;
export const QuickTextDocument = gql`
    query QuickText($id: ID!) {
  quickText(id: $id) {
    ...QuickText
  }
}
    ${QuickTextFragmentDoc}`;

/**
 * __useQuickTextQuery__
 *
 * To run a query within a React component, call `useQuickTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickTextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuickTextQuery(baseOptions: Apollo.QueryHookOptions<QuickTextQuery, QuickTextQueryVariables>) {
        return Apollo.useQuery<QuickTextQuery, QuickTextQueryVariables>(QuickTextDocument, baseOptions);
      }
export function useQuickTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickTextQuery, QuickTextQueryVariables>) {
          return Apollo.useLazyQuery<QuickTextQuery, QuickTextQueryVariables>(QuickTextDocument, baseOptions);
        }
export type QuickTextQueryHookResult = ReturnType<typeof useQuickTextQuery>;
export type QuickTextLazyQueryHookResult = ReturnType<typeof useQuickTextLazyQuery>;
export type QuickTextQueryResult = Apollo.QueryResult<QuickTextQuery, QuickTextQueryVariables>;
export const QuickTextsDocument = gql`
    query QuickTexts($where: QuickTextFilter, $first: Int = 10) {
  quickTexts(
    where: $where
    orderBy: {attribute: "createdAt", direction: "asc"}
    first: $first
  ) {
    ...QuickTextEdges
  }
}
    ${QuickTextEdgesFragmentDoc}`;

/**
 * __useQuickTextsQuery__
 *
 * To run a query within a React component, call `useQuickTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickTextsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQuickTextsQuery(baseOptions?: Apollo.QueryHookOptions<QuickTextsQuery, QuickTextsQueryVariables>) {
        return Apollo.useQuery<QuickTextsQuery, QuickTextsQueryVariables>(QuickTextsDocument, baseOptions);
      }
export function useQuickTextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickTextsQuery, QuickTextsQueryVariables>) {
          return Apollo.useLazyQuery<QuickTextsQuery, QuickTextsQueryVariables>(QuickTextsDocument, baseOptions);
        }
export type QuickTextsQueryHookResult = ReturnType<typeof useQuickTextsQuery>;
export type QuickTextsLazyQueryHookResult = ReturnType<typeof useQuickTextsLazyQuery>;
export type QuickTextsQueryResult = Apollo.QueryResult<QuickTextsQuery, QuickTextsQueryVariables>;
export const CreateQuickTextDocument = gql`
    mutation CreateQuickText($input: CreateQuickTextMutationInput!) {
  createQuickText(input: $input) {
    quickText {
      id
    }
  }
}
    `;
export type CreateQuickTextMutationFn = Apollo.MutationFunction<CreateQuickTextMutation, CreateQuickTextMutationVariables>;

/**
 * __useCreateQuickTextMutation__
 *
 * To run a mutation, you first call `useCreateQuickTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuickTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuickTextMutation, { data, loading, error }] = useCreateQuickTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuickTextMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuickTextMutation, CreateQuickTextMutationVariables>) {
        return Apollo.useMutation<CreateQuickTextMutation, CreateQuickTextMutationVariables>(CreateQuickTextDocument, baseOptions);
      }
export type CreateQuickTextMutationHookResult = ReturnType<typeof useCreateQuickTextMutation>;
export type CreateQuickTextMutationResult = Apollo.MutationResult<CreateQuickTextMutation>;
export type CreateQuickTextMutationOptions = Apollo.BaseMutationOptions<CreateQuickTextMutation, CreateQuickTextMutationVariables>;
export const UpdateQuickTextDocument = gql`
    mutation UpdateQuickText($input: UpdateQuickTextMutationInput!) {
  updateQuickText(input: $input) {
    quickText {
      id
    }
  }
}
    `;
export type UpdateQuickTextMutationFn = Apollo.MutationFunction<UpdateQuickTextMutation, UpdateQuickTextMutationVariables>;

/**
 * __useUpdateQuickTextMutation__
 *
 * To run a mutation, you first call `useUpdateQuickTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuickTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuickTextMutation, { data, loading, error }] = useUpdateQuickTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuickTextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuickTextMutation, UpdateQuickTextMutationVariables>) {
        return Apollo.useMutation<UpdateQuickTextMutation, UpdateQuickTextMutationVariables>(UpdateQuickTextDocument, baseOptions);
      }
export type UpdateQuickTextMutationHookResult = ReturnType<typeof useUpdateQuickTextMutation>;
export type UpdateQuickTextMutationResult = Apollo.MutationResult<UpdateQuickTextMutation>;
export type UpdateQuickTextMutationOptions = Apollo.BaseMutationOptions<UpdateQuickTextMutation, UpdateQuickTextMutationVariables>;
export const DestroyQuickTextDocument = gql`
    mutation DestroyQuickText($input: DestroyQuickTextMutationInput!) {
  destroyQuickText(input: $input) {
    quickText {
      id
    }
  }
}
    `;
export type DestroyQuickTextMutationFn = Apollo.MutationFunction<DestroyQuickTextMutation, DestroyQuickTextMutationVariables>;

/**
 * __useDestroyQuickTextMutation__
 *
 * To run a mutation, you first call `useDestroyQuickTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyQuickTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyQuickTextMutation, { data, loading, error }] = useDestroyQuickTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyQuickTextMutation(baseOptions?: Apollo.MutationHookOptions<DestroyQuickTextMutation, DestroyQuickTextMutationVariables>) {
        return Apollo.useMutation<DestroyQuickTextMutation, DestroyQuickTextMutationVariables>(DestroyQuickTextDocument, baseOptions);
      }
export type DestroyQuickTextMutationHookResult = ReturnType<typeof useDestroyQuickTextMutation>;
export type DestroyQuickTextMutationResult = Apollo.MutationResult<DestroyQuickTextMutation>;
export type DestroyQuickTextMutationOptions = Apollo.BaseMutationOptions<DestroyQuickTextMutation, DestroyQuickTextMutationVariables>;
export const CreateLotUrlsRainworxImportDocument = gql`
    mutation CreateLotUrlsRainworxImport($attributes: CreateLotUrlRainworxImportInput!) {
  createLotUrlsRainworxImport(input: {attributes: $attributes}) {
    clientMutationId
    import {
      id
    }
  }
}
    `;
export type CreateLotUrlsRainworxImportMutationFn = Apollo.MutationFunction<CreateLotUrlsRainworxImportMutation, CreateLotUrlsRainworxImportMutationVariables>;

/**
 * __useCreateLotUrlsRainworxImportMutation__
 *
 * To run a mutation, you first call `useCreateLotUrlsRainworxImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLotUrlsRainworxImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLotUrlsRainworxImportMutation, { data, loading, error }] = useCreateLotUrlsRainworxImportMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateLotUrlsRainworxImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateLotUrlsRainworxImportMutation, CreateLotUrlsRainworxImportMutationVariables>) {
        return Apollo.useMutation<CreateLotUrlsRainworxImportMutation, CreateLotUrlsRainworxImportMutationVariables>(CreateLotUrlsRainworxImportDocument, baseOptions);
      }
export type CreateLotUrlsRainworxImportMutationHookResult = ReturnType<typeof useCreateLotUrlsRainworxImportMutation>;
export type CreateLotUrlsRainworxImportMutationResult = Apollo.MutationResult<CreateLotUrlsRainworxImportMutation>;
export type CreateLotUrlsRainworxImportMutationOptions = Apollo.BaseMutationOptions<CreateLotUrlsRainworxImportMutation, CreateLotUrlsRainworxImportMutationVariables>;
export const SaleMandatsDocument = gql`
    query SaleMandats($where: SaleMandatFilter) {
  saleMandats(where: $where, orderBy: {attribute: "updatedAt", direction: "desc"}) {
    ...SaleMandatEdges
  }
}
    ${SaleMandatEdgesFragmentDoc}`;

/**
 * __useSaleMandatsQuery__
 *
 * To run a query within a React component, call `useSaleMandatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaleMandatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaleMandatsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSaleMandatsQuery(baseOptions?: Apollo.QueryHookOptions<SaleMandatsQuery, SaleMandatsQueryVariables>) {
        return Apollo.useQuery<SaleMandatsQuery, SaleMandatsQueryVariables>(SaleMandatsDocument, baseOptions);
      }
export function useSaleMandatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaleMandatsQuery, SaleMandatsQueryVariables>) {
          return Apollo.useLazyQuery<SaleMandatsQuery, SaleMandatsQueryVariables>(SaleMandatsDocument, baseOptions);
        }
export type SaleMandatsQueryHookResult = ReturnType<typeof useSaleMandatsQuery>;
export type SaleMandatsLazyQueryHookResult = ReturnType<typeof useSaleMandatsLazyQuery>;
export type SaleMandatsQueryResult = Apollo.QueryResult<SaleMandatsQuery, SaleMandatsQueryVariables>;
export const CreateSaleMandatsDocument = gql`
    mutation CreateSaleMandats($saleId: ID!) {
  createSaleMandats(input: {saleId: $saleId}) {
    clientMutationId
    sale {
      id
    }
  }
}
    `;
export type CreateSaleMandatsMutationFn = Apollo.MutationFunction<CreateSaleMandatsMutation, CreateSaleMandatsMutationVariables>;

/**
 * __useCreateSaleMandatsMutation__
 *
 * To run a mutation, you first call `useCreateSaleMandatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleMandatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaleMandatsMutation, { data, loading, error }] = useCreateSaleMandatsMutation({
 *   variables: {
 *      saleId: // value for 'saleId'
 *   },
 * });
 */
export function useCreateSaleMandatsMutation(baseOptions?: Apollo.MutationHookOptions<CreateSaleMandatsMutation, CreateSaleMandatsMutationVariables>) {
        return Apollo.useMutation<CreateSaleMandatsMutation, CreateSaleMandatsMutationVariables>(CreateSaleMandatsDocument, baseOptions);
      }
export type CreateSaleMandatsMutationHookResult = ReturnType<typeof useCreateSaleMandatsMutation>;
export type CreateSaleMandatsMutationResult = Apollo.MutationResult<CreateSaleMandatsMutation>;
export type CreateSaleMandatsMutationOptions = Apollo.BaseMutationOptions<CreateSaleMandatsMutation, CreateSaleMandatsMutationVariables>;
export const SaleDocument = gql`
    query Sale($id: ID!) {
  sale(id: $id) {
    ...Sale
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useSaleQuery__
 *
 * To run a query within a React component, call `useSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaleQuery(baseOptions: Apollo.QueryHookOptions<SaleQuery, SaleQueryVariables>) {
        return Apollo.useQuery<SaleQuery, SaleQueryVariables>(SaleDocument, baseOptions);
      }
export function useSaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaleQuery, SaleQueryVariables>) {
          return Apollo.useLazyQuery<SaleQuery, SaleQueryVariables>(SaleDocument, baseOptions);
        }
export type SaleQueryHookResult = ReturnType<typeof useSaleQuery>;
export type SaleLazyQueryHookResult = ReturnType<typeof useSaleLazyQuery>;
export type SaleQueryResult = Apollo.QueryResult<SaleQuery, SaleQueryVariables>;
export const SalesDocument = gql`
    query Sales($where: SaleFilter) {
  sales(where: $where, orderBy: {attribute: "updatedAt", direction: "desc"}) {
    ...SaleEdges
  }
}
    ${SaleEdgesFragmentDoc}`;

/**
 * __useSalesQuery__
 *
 * To run a query within a React component, call `useSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSalesQuery(baseOptions?: Apollo.QueryHookOptions<SalesQuery, SalesQueryVariables>) {
        return Apollo.useQuery<SalesQuery, SalesQueryVariables>(SalesDocument, baseOptions);
      }
export function useSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesQuery, SalesQueryVariables>) {
          return Apollo.useLazyQuery<SalesQuery, SalesQueryVariables>(SalesDocument, baseOptions);
        }
export type SalesQueryHookResult = ReturnType<typeof useSalesQuery>;
export type SalesLazyQueryHookResult = ReturnType<typeof useSalesLazyQuery>;
export type SalesQueryResult = Apollo.QueryResult<SalesQuery, SalesQueryVariables>;
export const CreateSaleDocument = gql`
    mutation CreateSale($input: CreateSaleMutationInput!) {
  createSale(input: $input) {
    sale {
      id
      name
    }
  }
}
    `;
export type CreateSaleMutationFn = Apollo.MutationFunction<CreateSaleMutation, CreateSaleMutationVariables>;

/**
 * __useCreateSaleMutation__
 *
 * To run a mutation, you first call `useCreateSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaleMutation, { data, loading, error }] = useCreateSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSaleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSaleMutation, CreateSaleMutationVariables>) {
        return Apollo.useMutation<CreateSaleMutation, CreateSaleMutationVariables>(CreateSaleDocument, baseOptions);
      }
export type CreateSaleMutationHookResult = ReturnType<typeof useCreateSaleMutation>;
export type CreateSaleMutationResult = Apollo.MutationResult<CreateSaleMutation>;
export type CreateSaleMutationOptions = Apollo.BaseMutationOptions<CreateSaleMutation, CreateSaleMutationVariables>;
export const UpdateSaleDocument = gql`
    mutation UpdateSale($input: UpdateSaleMutationInput!) {
  updateSale(input: $input) {
    sale {
      id
      name
    }
  }
}
    `;
export type UpdateSaleMutationFn = Apollo.MutationFunction<UpdateSaleMutation, UpdateSaleMutationVariables>;

/**
 * __useUpdateSaleMutation__
 *
 * To run a mutation, you first call `useUpdateSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleMutation, { data, loading, error }] = useUpdateSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSaleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSaleMutation, UpdateSaleMutationVariables>) {
        return Apollo.useMutation<UpdateSaleMutation, UpdateSaleMutationVariables>(UpdateSaleDocument, baseOptions);
      }
export type UpdateSaleMutationHookResult = ReturnType<typeof useUpdateSaleMutation>;
export type UpdateSaleMutationResult = Apollo.MutationResult<UpdateSaleMutation>;
export type UpdateSaleMutationOptions = Apollo.BaseMutationOptions<UpdateSaleMutation, UpdateSaleMutationVariables>;
export const DestroySaleDocument = gql`
    mutation DestroySale($input: DestroySaleMutationInput!) {
  destroySale(input: $input) {
    sale {
      id
      name
    }
  }
}
    `;
export type DestroySaleMutationFn = Apollo.MutationFunction<DestroySaleMutation, DestroySaleMutationVariables>;

/**
 * __useDestroySaleMutation__
 *
 * To run a mutation, you first call `useDestroySaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySaleMutation, { data, loading, error }] = useDestroySaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroySaleMutation(baseOptions?: Apollo.MutationHookOptions<DestroySaleMutation, DestroySaleMutationVariables>) {
        return Apollo.useMutation<DestroySaleMutation, DestroySaleMutationVariables>(DestroySaleDocument, baseOptions);
      }
export type DestroySaleMutationHookResult = ReturnType<typeof useDestroySaleMutation>;
export type DestroySaleMutationResult = Apollo.MutationResult<DestroySaleMutation>;
export type DestroySaleMutationOptions = Apollo.BaseMutationOptions<DestroySaleMutation, DestroySaleMutationVariables>;
export const TagsDocument = gql`
    query Tags($where: TagFilter, $direction: String = "desc", $first: Int = 15, $last: Int = null, $after: String = null, $before: String = null) {
  tags(
    where: $where
    orderBy: {attribute: "updatedAt", direction: $direction}
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    edges {
      cursor
      node {
        ...TagTable
      }
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    ${TagTableFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      direction: // value for 'direction'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const TagDocument = gql`
    query Tag($id: ID!) {
  tag(id: $id) {
    ...TagTable
  }
}
    ${TagTableFragmentDoc}`;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, baseOptions);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, baseOptions);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($id: ID!, $attributes: UpdateTagInput!) {
  updateTag(input: {id: $id, attributes: $attributes}) {
    clientMutationId
    tag {
      id
    }
  }
}
    `;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, baseOptions);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const MergeTagDocument = gql`
    mutation MergeTag($id: ID!, $attributes: MergeTagInput!) {
  mergeTag(input: {id: $id, attributes: $attributes}) {
    clientMutationId
    tag {
      id
    }
  }
}
    `;
export type MergeTagMutationFn = Apollo.MutationFunction<MergeTagMutation, MergeTagMutationVariables>;

/**
 * __useMergeTagMutation__
 *
 * To run a mutation, you first call `useMergeTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeTagMutation, { data, loading, error }] = useMergeTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useMergeTagMutation(baseOptions?: Apollo.MutationHookOptions<MergeTagMutation, MergeTagMutationVariables>) {
        return Apollo.useMutation<MergeTagMutation, MergeTagMutationVariables>(MergeTagDocument, baseOptions);
      }
export type MergeTagMutationHookResult = ReturnType<typeof useMergeTagMutation>;
export type MergeTagMutationResult = Apollo.MutationResult<MergeTagMutation>;
export type MergeTagMutationOptions = Apollo.BaseMutationOptions<MergeTagMutation, MergeTagMutationVariables>;
export const DestroyTagDocument = gql`
    mutation DestroyTag($id: ID!) {
  destroyTag(input: {id: $id}) {
    tag {
      id
    }
  }
}
    `;
export type DestroyTagMutationFn = Apollo.MutationFunction<DestroyTagMutation, DestroyTagMutationVariables>;

/**
 * __useDestroyTagMutation__
 *
 * To run a mutation, you first call `useDestroyTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyTagMutation, { data, loading, error }] = useDestroyTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyTagMutation(baseOptions?: Apollo.MutationHookOptions<DestroyTagMutation, DestroyTagMutationVariables>) {
        return Apollo.useMutation<DestroyTagMutation, DestroyTagMutationVariables>(DestroyTagDocument, baseOptions);
      }
export type DestroyTagMutationHookResult = ReturnType<typeof useDestroyTagMutation>;
export type DestroyTagMutationResult = Apollo.MutationResult<DestroyTagMutation>;
export type DestroyTagMutationOptions = Apollo.BaseMutationOptions<DestroyTagMutation, DestroyTagMutationVariables>;
export const VacationDocument = gql`
    query Vacation($id: ID!) {
  vacation(id: $id) {
    ...Vacation
  }
}
    ${VacationFragmentDoc}`;

/**
 * __useVacationQuery__
 *
 * To run a query within a React component, call `useVacationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVacationQuery(baseOptions: Apollo.QueryHookOptions<VacationQuery, VacationQueryVariables>) {
        return Apollo.useQuery<VacationQuery, VacationQueryVariables>(VacationDocument, baseOptions);
      }
export function useVacationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VacationQuery, VacationQueryVariables>) {
          return Apollo.useLazyQuery<VacationQuery, VacationQueryVariables>(VacationDocument, baseOptions);
        }
export type VacationQueryHookResult = ReturnType<typeof useVacationQuery>;
export type VacationLazyQueryHookResult = ReturnType<typeof useVacationLazyQuery>;
export type VacationQueryResult = Apollo.QueryResult<VacationQuery, VacationQueryVariables>;
export const VacationsDocument = gql`
    query Vacations($where: VacationFilter, $orderByAttribute: [ItemOrder!] = [{attribute: "name", direction: "asc"}], $first: Int = 300, $last: Int = null, $after: String = null, $before: String = null) {
  vacations(
    where: $where
    orderBy: $orderByAttribute
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    ...VacationEdges
  }
}
    ${VacationEdgesFragmentDoc}`;

/**
 * __useVacationsQuery__
 *
 * To run a query within a React component, call `useVacationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderByAttribute: // value for 'orderByAttribute'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useVacationsQuery(baseOptions?: Apollo.QueryHookOptions<VacationsQuery, VacationsQueryVariables>) {
        return Apollo.useQuery<VacationsQuery, VacationsQueryVariables>(VacationsDocument, baseOptions);
      }
export function useVacationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VacationsQuery, VacationsQueryVariables>) {
          return Apollo.useLazyQuery<VacationsQuery, VacationsQueryVariables>(VacationsDocument, baseOptions);
        }
export type VacationsQueryHookResult = ReturnType<typeof useVacationsQuery>;
export type VacationsLazyQueryHookResult = ReturnType<typeof useVacationsLazyQuery>;
export type VacationsQueryResult = Apollo.QueryResult<VacationsQuery, VacationsQueryVariables>;
export const CreateVacationDocument = gql`
    mutation CreateVacation($input: CreateVacationMutationInput!) {
  createVacation(input: $input) {
    vacation {
      id
      name
    }
  }
}
    `;
export type CreateVacationMutationFn = Apollo.MutationFunction<CreateVacationMutation, CreateVacationMutationVariables>;

/**
 * __useCreateVacationMutation__
 *
 * To run a mutation, you first call `useCreateVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVacationMutation, { data, loading, error }] = useCreateVacationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVacationMutation(baseOptions?: Apollo.MutationHookOptions<CreateVacationMutation, CreateVacationMutationVariables>) {
        return Apollo.useMutation<CreateVacationMutation, CreateVacationMutationVariables>(CreateVacationDocument, baseOptions);
      }
export type CreateVacationMutationHookResult = ReturnType<typeof useCreateVacationMutation>;
export type CreateVacationMutationResult = Apollo.MutationResult<CreateVacationMutation>;
export type CreateVacationMutationOptions = Apollo.BaseMutationOptions<CreateVacationMutation, CreateVacationMutationVariables>;
export const UpdateVacationDocument = gql`
    mutation UpdateVacation($input: UpdateVacationMutationInput!) {
  updateVacation(input: $input) {
    vacation {
      id
      name
    }
  }
}
    `;
export type UpdateVacationMutationFn = Apollo.MutationFunction<UpdateVacationMutation, UpdateVacationMutationVariables>;

/**
 * __useUpdateVacationMutation__
 *
 * To run a mutation, you first call `useUpdateVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVacationMutation, { data, loading, error }] = useUpdateVacationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVacationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVacationMutation, UpdateVacationMutationVariables>) {
        return Apollo.useMutation<UpdateVacationMutation, UpdateVacationMutationVariables>(UpdateVacationDocument, baseOptions);
      }
export type UpdateVacationMutationHookResult = ReturnType<typeof useUpdateVacationMutation>;
export type UpdateVacationMutationResult = Apollo.MutationResult<UpdateVacationMutation>;
export type UpdateVacationMutationOptions = Apollo.BaseMutationOptions<UpdateVacationMutation, UpdateVacationMutationVariables>;
export const DestroyVacationDocument = gql`
    mutation DestroyVacation($input: DestroyVacationMutationInput!) {
  destroyVacation(input: $input) {
    vacation {
      id
      name
    }
  }
}
    `;
export type DestroyVacationMutationFn = Apollo.MutationFunction<DestroyVacationMutation, DestroyVacationMutationVariables>;

/**
 * __useDestroyVacationMutation__
 *
 * To run a mutation, you first call `useDestroyVacationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyVacationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyVacationMutation, { data, loading, error }] = useDestroyVacationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyVacationMutation(baseOptions?: Apollo.MutationHookOptions<DestroyVacationMutation, DestroyVacationMutationVariables>) {
        return Apollo.useMutation<DestroyVacationMutation, DestroyVacationMutationVariables>(DestroyVacationDocument, baseOptions);
      }
export type DestroyVacationMutationHookResult = ReturnType<typeof useDestroyVacationMutation>;
export type DestroyVacationMutationResult = Apollo.MutationResult<DestroyVacationMutation>;
export type DestroyVacationMutationOptions = Apollo.BaseMutationOptions<DestroyVacationMutation, DestroyVacationMutationVariables>;
export const MonthlyVendorPaymentDocument = gql`
    query MonthlyVendorPayment($id: ID!) {
  monthlyVendorPayment(id: $id) {
    ...MonthlyVendorPayment
    vendor {
      ...Vendor
      account {
        ...VendorAccount
        bankInformation {
          iban
          bic
        }
      }
    }
  }
}
    ${MonthlyVendorPaymentFragmentDoc}
${VendorFragmentDoc}
${VendorAccountFragmentDoc}`;

/**
 * __useMonthlyVendorPaymentQuery__
 *
 * To run a query within a React component, call `useMonthlyVendorPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyVendorPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyVendorPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMonthlyVendorPaymentQuery(baseOptions: Apollo.QueryHookOptions<MonthlyVendorPaymentQuery, MonthlyVendorPaymentQueryVariables>) {
        return Apollo.useQuery<MonthlyVendorPaymentQuery, MonthlyVendorPaymentQueryVariables>(MonthlyVendorPaymentDocument, baseOptions);
      }
export function useMonthlyVendorPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyVendorPaymentQuery, MonthlyVendorPaymentQueryVariables>) {
          return Apollo.useLazyQuery<MonthlyVendorPaymentQuery, MonthlyVendorPaymentQueryVariables>(MonthlyVendorPaymentDocument, baseOptions);
        }
export type MonthlyVendorPaymentQueryHookResult = ReturnType<typeof useMonthlyVendorPaymentQuery>;
export type MonthlyVendorPaymentLazyQueryHookResult = ReturnType<typeof useMonthlyVendorPaymentLazyQuery>;
export type MonthlyVendorPaymentQueryResult = Apollo.QueryResult<MonthlyVendorPaymentQuery, MonthlyVendorPaymentQueryVariables>;
export const MonthlyVendorPaymentsDocument = gql`
    query MonthlyVendorPayments($where: MonthlyVendorPaymentFilter, $first: Int = 10) {
  monthlyVendorPayments(
    where: $where
    orderBy: {attribute: "createdAt", direction: "asc"}
    first: $first
  ) {
    ...MonthlyVendorPaymentEdges
  }
}
    ${MonthlyVendorPaymentEdgesFragmentDoc}`;

/**
 * __useMonthlyVendorPaymentsQuery__
 *
 * To run a query within a React component, call `useMonthlyVendorPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyVendorPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyVendorPaymentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMonthlyVendorPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<MonthlyVendorPaymentsQuery, MonthlyVendorPaymentsQueryVariables>) {
        return Apollo.useQuery<MonthlyVendorPaymentsQuery, MonthlyVendorPaymentsQueryVariables>(MonthlyVendorPaymentsDocument, baseOptions);
      }
export function useMonthlyVendorPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyVendorPaymentsQuery, MonthlyVendorPaymentsQueryVariables>) {
          return Apollo.useLazyQuery<MonthlyVendorPaymentsQuery, MonthlyVendorPaymentsQueryVariables>(MonthlyVendorPaymentsDocument, baseOptions);
        }
export type MonthlyVendorPaymentsQueryHookResult = ReturnType<typeof useMonthlyVendorPaymentsQuery>;
export type MonthlyVendorPaymentsLazyQueryHookResult = ReturnType<typeof useMonthlyVendorPaymentsLazyQuery>;
export type MonthlyVendorPaymentsQueryResult = Apollo.QueryResult<MonthlyVendorPaymentsQuery, MonthlyVendorPaymentsQueryVariables>;
export const UpdateMonthlyVendorPaymentDocument = gql`
    mutation UpdateMonthlyVendorPayment($input: UpdateMonthlyVendorPaymentMutationInput!) {
  updateMonthlyVendorPayment(input: $input) {
    monthlyVendorPayment {
      id
    }
  }
}
    `;
export type UpdateMonthlyVendorPaymentMutationFn = Apollo.MutationFunction<UpdateMonthlyVendorPaymentMutation, UpdateMonthlyVendorPaymentMutationVariables>;

/**
 * __useUpdateMonthlyVendorPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateMonthlyVendorPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonthlyVendorPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonthlyVendorPaymentMutation, { data, loading, error }] = useUpdateMonthlyVendorPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMonthlyVendorPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMonthlyVendorPaymentMutation, UpdateMonthlyVendorPaymentMutationVariables>) {
        return Apollo.useMutation<UpdateMonthlyVendorPaymentMutation, UpdateMonthlyVendorPaymentMutationVariables>(UpdateMonthlyVendorPaymentDocument, baseOptions);
      }
export type UpdateMonthlyVendorPaymentMutationHookResult = ReturnType<typeof useUpdateMonthlyVendorPaymentMutation>;
export type UpdateMonthlyVendorPaymentMutationResult = Apollo.MutationResult<UpdateMonthlyVendorPaymentMutation>;
export type UpdateMonthlyVendorPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateMonthlyVendorPaymentMutation, UpdateMonthlyVendorPaymentMutationVariables>;
export const VendorStatementsDocument = gql`
    query VendorStatements($where: VendorStatementFilter) {
  vendorStatements(
    where: $where
    orderBy: {attribute: "updatedAt", direction: "desc"}
  ) {
    ...VendorStatementEdges
  }
}
    ${VendorStatementEdgesFragmentDoc}`;

/**
 * __useVendorStatementsQuery__
 *
 * To run a query within a React component, call `useVendorStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorStatementsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVendorStatementsQuery(baseOptions?: Apollo.QueryHookOptions<VendorStatementsQuery, VendorStatementsQueryVariables>) {
        return Apollo.useQuery<VendorStatementsQuery, VendorStatementsQueryVariables>(VendorStatementsDocument, baseOptions);
      }
export function useVendorStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorStatementsQuery, VendorStatementsQueryVariables>) {
          return Apollo.useLazyQuery<VendorStatementsQuery, VendorStatementsQueryVariables>(VendorStatementsDocument, baseOptions);
        }
export type VendorStatementsQueryHookResult = ReturnType<typeof useVendorStatementsQuery>;
export type VendorStatementsLazyQueryHookResult = ReturnType<typeof useVendorStatementsLazyQuery>;
export type VendorStatementsQueryResult = Apollo.QueryResult<VendorStatementsQuery, VendorStatementsQueryVariables>;
export const GetWriterDocument = gql`
    query GetWriter($id: ID!) {
  writer(id: $id) {
    ...Writer
  }
}
    ${WriterFragmentDoc}`;

/**
 * __useGetWriterQuery__
 *
 * To run a query within a React component, call `useGetWriterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWriterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWriterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWriterQuery(baseOptions: Apollo.QueryHookOptions<GetWriterQuery, GetWriterQueryVariables>) {
        return Apollo.useQuery<GetWriterQuery, GetWriterQueryVariables>(GetWriterDocument, baseOptions);
      }
export function useGetWriterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWriterQuery, GetWriterQueryVariables>) {
          return Apollo.useLazyQuery<GetWriterQuery, GetWriterQueryVariables>(GetWriterDocument, baseOptions);
        }
export type GetWriterQueryHookResult = ReturnType<typeof useGetWriterQuery>;
export type GetWriterLazyQueryHookResult = ReturnType<typeof useGetWriterLazyQuery>;
export type GetWriterQueryResult = Apollo.QueryResult<GetWriterQuery, GetWriterQueryVariables>;
export const WriterPaymentDocument = gql`
    query WriterPayment($id: ID!) {
  writerPayment(id: $id) {
    ...WriterPayment
    writer {
      ...Writer
      account {
        ...WriterAccount
        bankInformation {
          iban
          bic
        }
      }
    }
  }
}
    ${WriterPaymentFragmentDoc}
${WriterFragmentDoc}
${WriterAccountFragmentDoc}`;

/**
 * __useWriterPaymentQuery__
 *
 * To run a query within a React component, call `useWriterPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useWriterPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWriterPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWriterPaymentQuery(baseOptions: Apollo.QueryHookOptions<WriterPaymentQuery, WriterPaymentQueryVariables>) {
        return Apollo.useQuery<WriterPaymentQuery, WriterPaymentQueryVariables>(WriterPaymentDocument, baseOptions);
      }
export function useWriterPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WriterPaymentQuery, WriterPaymentQueryVariables>) {
          return Apollo.useLazyQuery<WriterPaymentQuery, WriterPaymentQueryVariables>(WriterPaymentDocument, baseOptions);
        }
export type WriterPaymentQueryHookResult = ReturnType<typeof useWriterPaymentQuery>;
export type WriterPaymentLazyQueryHookResult = ReturnType<typeof useWriterPaymentLazyQuery>;
export type WriterPaymentQueryResult = Apollo.QueryResult<WriterPaymentQuery, WriterPaymentQueryVariables>;
export const WriterPaymentsDocument = gql`
    query WriterPayments($where: WriterPaymentFilter, $first: Int = 10) {
  writerPayments(
    where: $where
    orderBy: {attribute: "createdAt", direction: "asc"}
    first: $first
  ) {
    ...WriterPaymentEdges
  }
}
    ${WriterPaymentEdgesFragmentDoc}`;

/**
 * __useWriterPaymentsQuery__
 *
 * To run a query within a React component, call `useWriterPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWriterPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWriterPaymentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useWriterPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<WriterPaymentsQuery, WriterPaymentsQueryVariables>) {
        return Apollo.useQuery<WriterPaymentsQuery, WriterPaymentsQueryVariables>(WriterPaymentsDocument, baseOptions);
      }
export function useWriterPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WriterPaymentsQuery, WriterPaymentsQueryVariables>) {
          return Apollo.useLazyQuery<WriterPaymentsQuery, WriterPaymentsQueryVariables>(WriterPaymentsDocument, baseOptions);
        }
export type WriterPaymentsQueryHookResult = ReturnType<typeof useWriterPaymentsQuery>;
export type WriterPaymentsLazyQueryHookResult = ReturnType<typeof useWriterPaymentsLazyQuery>;
export type WriterPaymentsQueryResult = Apollo.QueryResult<WriterPaymentsQuery, WriterPaymentsQueryVariables>;
export const UpdateWriterPaymentDocument = gql`
    mutation UpdateWriterPayment($input: UpdateWriterPaymentMutationInput!) {
  updateWriterPayment(input: $input) {
    writerPayment {
      id
    }
  }
}
    `;
export type UpdateWriterPaymentMutationFn = Apollo.MutationFunction<UpdateWriterPaymentMutation, UpdateWriterPaymentMutationVariables>;

/**
 * __useUpdateWriterPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateWriterPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWriterPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWriterPaymentMutation, { data, loading, error }] = useUpdateWriterPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWriterPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWriterPaymentMutation, UpdateWriterPaymentMutationVariables>) {
        return Apollo.useMutation<UpdateWriterPaymentMutation, UpdateWriterPaymentMutationVariables>(UpdateWriterPaymentDocument, baseOptions);
      }
export type UpdateWriterPaymentMutationHookResult = ReturnType<typeof useUpdateWriterPaymentMutation>;
export type UpdateWriterPaymentMutationResult = Apollo.MutationResult<UpdateWriterPaymentMutation>;
export type UpdateWriterPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateWriterPaymentMutation, UpdateWriterPaymentMutationVariables>;
export const WriterDocument = gql`
    query Writer($id: ID!) {
  writer(id: $id) {
    ...WriterTable
    nbItemsCreated {
      currentMonth
      lastMonth
      total
      totalAmountCurrentMonth
      totalAmountLastMonth
    }
    nbItemsAfterAccepted {
      currentMonth
      lastMonth
      total
      totalAmountCurrentMonth
      totalAmountLastMonth
    }
    kpis {
      totalFromBeginning
      itemAcceptedPercentage
      itemPercentageStates {
        draft
        accepted
        refused
        needUpdate
        waitingExpertise
        underExpertise
      }
    }
  }
}
    ${WriterTableFragmentDoc}`;

/**
 * __useWriterQuery__
 *
 * To run a query within a React component, call `useWriterQuery` and pass it any options that fit your needs.
 * When your component renders, `useWriterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWriterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWriterQuery(baseOptions: Apollo.QueryHookOptions<WriterQuery, WriterQueryVariables>) {
        return Apollo.useQuery<WriterQuery, WriterQueryVariables>(WriterDocument, baseOptions);
      }
export function useWriterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WriterQuery, WriterQueryVariables>) {
          return Apollo.useLazyQuery<WriterQuery, WriterQueryVariables>(WriterDocument, baseOptions);
        }
export type WriterQueryHookResult = ReturnType<typeof useWriterQuery>;
export type WriterLazyQueryHookResult = ReturnType<typeof useWriterLazyQuery>;
export type WriterQueryResult = Apollo.QueryResult<WriterQuery, WriterQueryVariables>;
export const WritersDocument = gql`
    query Writers($where: WriterFilter) {
  writers(where: $where, orderBy: {attribute: "updatedAt", direction: "desc"}) {
    edges {
      node {
        ...WriterTable
      }
    }
  }
}
    ${WriterTableFragmentDoc}`;

/**
 * __useWritersQuery__
 *
 * To run a query within a React component, call `useWritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWritersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWritersQuery(baseOptions?: Apollo.QueryHookOptions<WritersQuery, WritersQueryVariables>) {
        return Apollo.useQuery<WritersQuery, WritersQueryVariables>(WritersDocument, baseOptions);
      }
export function useWritersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WritersQuery, WritersQueryVariables>) {
          return Apollo.useLazyQuery<WritersQuery, WritersQueryVariables>(WritersDocument, baseOptions);
        }
export type WritersQueryHookResult = ReturnType<typeof useWritersQuery>;
export type WritersLazyQueryHookResult = ReturnType<typeof useWritersLazyQuery>;
export type WritersQueryResult = Apollo.QueryResult<WritersQuery, WritersQueryVariables>;
export const CreateImportDocument = gql`
    mutation CreateImport($attributes: CreateImportInput!) {
  createImport(input: {attributes: $attributes}) {
    clientMutationId
    import {
      id
    }
  }
}
    `;
export type CreateImportMutationFn = Apollo.MutationFunction<CreateImportMutation, CreateImportMutationVariables>;

/**
 * __useCreateImportMutation__
 *
 * To run a mutation, you first call `useCreateImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImportMutation, { data, loading, error }] = useCreateImportMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateImportMutation, CreateImportMutationVariables>) {
        return Apollo.useMutation<CreateImportMutation, CreateImportMutationVariables>(CreateImportDocument, baseOptions);
      }
export type CreateImportMutationHookResult = ReturnType<typeof useCreateImportMutation>;
export type CreateImportMutationResult = Apollo.MutationResult<CreateImportMutation>;
export type CreateImportMutationOptions = Apollo.BaseMutationOptions<CreateImportMutation, CreateImportMutationVariables>;
export const CreateDrouotImportDocument = gql`
    mutation CreateDrouotImport($attributes: CreateDrouotImportInput!) {
  createDrouotImport(input: {attributes: $attributes}) {
    clientMutationId
    import {
      id
    }
  }
}
    `;
export type CreateDrouotImportMutationFn = Apollo.MutationFunction<CreateDrouotImportMutation, CreateDrouotImportMutationVariables>;

/**
 * __useCreateDrouotImportMutation__
 *
 * To run a mutation, you first call `useCreateDrouotImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDrouotImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDrouotImportMutation, { data, loading, error }] = useCreateDrouotImportMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateDrouotImportMutation(baseOptions?: Apollo.MutationHookOptions<CreateDrouotImportMutation, CreateDrouotImportMutationVariables>) {
        return Apollo.useMutation<CreateDrouotImportMutation, CreateDrouotImportMutationVariables>(CreateDrouotImportDocument, baseOptions);
      }
export type CreateDrouotImportMutationHookResult = ReturnType<typeof useCreateDrouotImportMutation>;
export type CreateDrouotImportMutationResult = Apollo.MutationResult<CreateDrouotImportMutation>;
export type CreateDrouotImportMutationOptions = Apollo.BaseMutationOptions<CreateDrouotImportMutation, CreateDrouotImportMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    success
    errors {
      details
      message
    }
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LogInDocument = gql`
    mutation logIn($email: String!, $password: String!) {
  customSignIn(email: $email, password: $password, rememberMe: false) {
    success
    sessionToken
    user {
      id
      email
      firstName
      lastName
      lastAccountTypeUsed
      accounts {
        edges {
          node {
            id
            accountable {
              ... on Expert {
                id
              }
              ... on Vendor {
                id
              }
              __typename
            }
          }
        }
      }
    }
  }
}
    `;
export type LogInMutationFn = Apollo.MutationFunction<LogInMutation, LogInMutationVariables>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogInMutation(baseOptions?: Apollo.MutationHookOptions<LogInMutation, LogInMutationVariables>) {
        return Apollo.useMutation<LogInMutation, LogInMutationVariables>(LogInDocument, baseOptions);
      }
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = Apollo.MutationResult<LogInMutation>;
export type LogInMutationOptions = Apollo.BaseMutationOptions<LogInMutation, LogInMutationVariables>;
export const UpdateUserOnBoardingDocument = gql`
    mutation updateUserOnBoarding($userId: ID!, $firstName: String!, $lastName: String!) {
  updateUser(
    input: {id: $userId, attributes: {firstName: $firstName, lastName: $lastName}}
  ) {
    id
    firstName
    lastName
  }
}
    `;
export type UpdateUserOnBoardingMutationFn = Apollo.MutationFunction<UpdateUserOnBoardingMutation, UpdateUserOnBoardingMutationVariables>;

/**
 * __useUpdateUserOnBoardingMutation__
 *
 * To run a mutation, you first call `useUpdateUserOnBoardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOnBoardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOnBoardingMutation, { data, loading, error }] = useUpdateUserOnBoardingMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateUserOnBoardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserOnBoardingMutation, UpdateUserOnBoardingMutationVariables>) {
        return Apollo.useMutation<UpdateUserOnBoardingMutation, UpdateUserOnBoardingMutationVariables>(UpdateUserOnBoardingDocument, baseOptions);
      }
export type UpdateUserOnBoardingMutationHookResult = ReturnType<typeof useUpdateUserOnBoardingMutation>;
export type UpdateUserOnBoardingMutationResult = Apollo.MutationResult<UpdateUserOnBoardingMutation>;
export type UpdateUserOnBoardingMutationOptions = Apollo.BaseMutationOptions<UpdateUserOnBoardingMutation, UpdateUserOnBoardingMutationVariables>;
export const CreateAccountDocument = gql`
    mutation createAccount($attributes: CreateAccountInput!) {
  createAccount(input: {attributes: $attributes}) {
    account {
      id
      firstName
      lastName
      email
      accountable {
        ... on Expert {
          id
        }
        ... on Vendor {
          id
        }
        ... on Buyer {
          id
        }
        ... on Writer {
          id
        }
        __typename
      }
    }
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, baseOptions);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($email: String!, $password: String!, $passwordConfirmation: String!, $role: String!, $firstName: String!, $lastName: String!, $phone: String!) {
  customSignUp(
    email: $email
    password: $password
    passwordConfirmation: $passwordConfirmation
    role: $role
    firstName: $firstName
    lastName: $lastName
    phone: $phone
  ) {
    sessionToken
    success
    user {
      id
      email
      firstName
      lastName
      accounts {
        edges {
          node {
            id
            accountable {
              ... on Expert {
                id
              }
              ... on Vendor {
                id
              }
              __typename
            }
          }
        }
      }
    }
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      role: // value for 'role'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($password: String!, $passwordConfirmation: String!, $resetPasswordToken: String!) {
  resetPassword(
    password: $password
    passwordConfirmation: $passwordConfirmation
    resetPasswordToken: $resetPasswordToken
  ) {
    success
    errors {
      details
      message
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      resetPasswordToken: // value for 'resetPasswordToken'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($id: ID!) {
  customer(id: $id) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($attributes: CreateCustomerInput!) {
  createCustomer(input: {attributes: $attributes}) {
    clientMutationId
    customer {
      ...Customer
    }
  }
}
    ${CustomerFragmentDoc}`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, baseOptions);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const ItemDocument = gql`
    query Item($id: ID!) {
  item(id: $id) {
    id
    ...ItemSteps
    ...ItemImages
    state
    ...ItemCategory
  }
}
    ${ItemStepsFragmentDoc}
${ItemImagesFragmentDoc}
${ItemCategoryFragmentDoc}`;

/**
 * __useItemQuery__
 *
 * To run a query within a React component, call `useItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useItemQuery(baseOptions: Apollo.QueryHookOptions<ItemQuery, ItemQueryVariables>) {
        return Apollo.useQuery<ItemQuery, ItemQueryVariables>(ItemDocument, baseOptions);
      }
export function useItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemQuery, ItemQueryVariables>) {
          return Apollo.useLazyQuery<ItemQuery, ItemQueryVariables>(ItemDocument, baseOptions);
        }
export type ItemQueryHookResult = ReturnType<typeof useItemQuery>;
export type ItemLazyQueryHookResult = ReturnType<typeof useItemLazyQuery>;
export type ItemQueryResult = Apollo.QueryResult<ItemQuery, ItemQueryVariables>;
export const UploadImagePositionsDocument = gql`
    mutation UploadImagePositions($id: ID!, $positions: UpdateImagePositionInput!) {
  updateItem(input: {id: $id, attributes: {updateImagePositions: $positions}}) {
    clientMutationId
    item {
      title
      images {
        imageUrl
        position
      }
    }
  }
}
    `;
export type UploadImagePositionsMutationFn = Apollo.MutationFunction<UploadImagePositionsMutation, UploadImagePositionsMutationVariables>;

/**
 * __useUploadImagePositionsMutation__
 *
 * To run a mutation, you first call `useUploadImagePositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagePositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagePositionsMutation, { data, loading, error }] = useUploadImagePositionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      positions: // value for 'positions'
 *   },
 * });
 */
export function useUploadImagePositionsMutation(baseOptions?: Apollo.MutationHookOptions<UploadImagePositionsMutation, UploadImagePositionsMutationVariables>) {
        return Apollo.useMutation<UploadImagePositionsMutation, UploadImagePositionsMutationVariables>(UploadImagePositionsDocument, baseOptions);
      }
export type UploadImagePositionsMutationHookResult = ReturnType<typeof useUploadImagePositionsMutation>;
export type UploadImagePositionsMutationResult = Apollo.MutationResult<UploadImagePositionsMutation>;
export type UploadImagePositionsMutationOptions = Apollo.BaseMutationOptions<UploadImagePositionsMutation, UploadImagePositionsMutationVariables>;
export const RemoveImagePositionsDocument = gql`
    mutation removeImagePositions($id: ID!, $positions: [Int!]) {
  updateItem(input: {id: $id, attributes: {imagePositionsToDelete: $positions}}) {
    clientMutationId
    item {
      title
      images {
        imageUrl
        position
      }
    }
  }
}
    `;
export type RemoveImagePositionsMutationFn = Apollo.MutationFunction<RemoveImagePositionsMutation, RemoveImagePositionsMutationVariables>;

/**
 * __useRemoveImagePositionsMutation__
 *
 * To run a mutation, you first call `useRemoveImagePositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveImagePositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeImagePositionsMutation, { data, loading, error }] = useRemoveImagePositionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      positions: // value for 'positions'
 *   },
 * });
 */
export function useRemoveImagePositionsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveImagePositionsMutation, RemoveImagePositionsMutationVariables>) {
        return Apollo.useMutation<RemoveImagePositionsMutation, RemoveImagePositionsMutationVariables>(RemoveImagePositionsDocument, baseOptions);
      }
export type RemoveImagePositionsMutationHookResult = ReturnType<typeof useRemoveImagePositionsMutation>;
export type RemoveImagePositionsMutationResult = Apollo.MutationResult<RemoveImagePositionsMutation>;
export type RemoveImagePositionsMutationOptions = Apollo.BaseMutationOptions<RemoveImagePositionsMutation, RemoveImagePositionsMutationVariables>;